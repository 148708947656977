import { TituloH2 } from '../../../components/BasicComponents/Titulos/Titulos';
import { withAuth } from '../../../context/AuthProvider';
import './ReportPage.scss';

const ReportProjectInfo = (props) => {
    const project = props?.promocion;
    const egrData = props?.egrData;

    return (
        <>
            <TituloH2 titulo={'1. INFORMACIÓN GENERAL'} />
            <ul className="reports__projectInfo">
                <li>Proyecto: {project?.nombre}</li>
                <li>
                    Ubicación: {project?.direccion?.direccion}, {project?.direccion?.municipio?.nombre} CP
                    {project?.direccion?.postalCode} ({project?.direccion?.municipio?.provincia?.nombre})
                </li>
                <li>Tipo de proyecto: {project?.promotionType?.nombre}</li>
                <li>
                    Superficie total construida: {project?.surface} m<sup>2</sup>
                </li>
                <li>NIMA: {project?.nima}</li>
            </ul>

            {egrData?.length > 0 && (
                <div className="reports__egr">
                    <p>
                        <b>Estimación de producción de residuos</b> (según la estimación recogida en los estudios
                        previos EGR/PGR).
                    </p>
                    <table>
                        <thead>
                            <tr>
                                <th>Tipo de residuo</th>
                                <th>Código LER</th>
                                <th>Peso (T)</th>
                                <th>Volumen (m³)</th>
                                <th>Coste (€)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {egrData?.map((item) => (
                                <tr key={item.id}>
                                    <td>{item.lerType}</td>
                                    <td>{item.codLer}</td>
                                    <td>{item.weight}</td>
                                    <td>{item.volume}</td>
                                    <td>{item.budget}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
};

export default withAuth(ReportProjectInfo);
