import enterpriseServices from '../../api/enterprise/enterprise.services';

const _getData = async (filter, { source }) => {
    return await enterpriseServices.recoveryMetrics(filter, { source });
};

export const getDataGraphic = async (promotionIds, withLand = true, withDangerous = true, { source }) => {
    let withoutLandAndWithoutPromotion = !withLand ? '?land.equals=false' : '';
    let withoutLand = !withLand ? '&land.equals=false' : '';
    let resultData;
    const filterWithDangerous = !withDangerous ? '&dangerous.equals=false' : '';
    if (promotionIds) {
        resultData = await _getData(`?promocion.in=${promotionIds}` + withoutLand + filterWithDangerous, { source });
    } else {
        resultData = await _getData('' + withoutLandAndWithoutPromotion, { source });
    }
    return resultData?.data?.content;
};

export const getTotalWeight = (results = []) => {
    let total = 0;
    for (const result of results) {
        total += result?.totalWeight;
    }
    return total;
};

export const getTotalPercentRecovered = (results = []) => {
    let totalWeightRecovered = 0;
    const totalWeight = getTotalWeight(results);
    let percentRecovered = 0;
    for (const result of results) {
        totalWeightRecovered += result?.totalWeightRecovered;
    }
    percentRecovered = (totalWeightRecovered / totalWeight) * 100 || 0;
    // truncate percentage
    percentRecovered = Math.floor(percentRecovered * 100) / 100;
    return percentRecovered;
};

const filters = [
    {
        filter: '?dangerous.equals=false&land.equals=false',
        title: 'waste.nonDangerous',
        showValorization: true
    },
    {
        filter: '?dangerous.equals=true',
        title: 'waste.dangerous',
        showValorization: false
    },
    {
        filter: '?dangerous.equals=false&land.equals=true',
        title: 'waste.land',
        showValorization: true
    }
];

export const getRecovery = async (promotionId, { source }) => {
    let data = [];
    for await (const results of filters) {
        let resultData;
        if (promotionId) {
            let filter = results.filter
                ? results.filter + `&promocion.in=${promotionId}`
                : `?promocion.in=${promotionId}`;
            resultData = await _getData(filter, { source });
        } else {
            resultData = await _getData(results.filter, { source });
        }
        data.push({
            title: results.title,
            totalWeight: getTotalWeight(resultData.data.content),
            percentRecovered: results?.showValorization ? getTotalPercentRecovered(resultData.data.content) : null
        });
    }
    return data;
};
