import { IconTrash } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import IconButton from '../../../components/BasicComponents/Buttons/Small/IconButton';
import { withAuth } from '../../../context/AuthProvider';
import { getValorizationType } from '../../impact/helpers/valorization.helpers';

const PpgclFinal = ({ ppgcl, promocion, deleteOption }) => {
    const getTransferOperator = (ppgcl) => {
        const transferOperatorValues = {
            1: promocion.nombre,
            3: ppgcl?.ppgcl?.procesoGestora?.gestora.empresa,
            4: ppgcl?.transferOperator?.procesoGestora?.gestora.empresa
        };

        return transferOperatorValues[ppgcl?.transferOperatorTypeId];
    };
    const [t] = useTranslation();
    return (
        <li key={ppgcl.ppgcl.id} className="finalPpgcl">
            <div>
                {!getTransferOperator(ppgcl) && promocion.oddEnabled && (
                    <p className="lerLine__warning">
                        Va a crear un flujo sin el rol de operador de traslado. Recuerde añadirlo para poder realizar
                        solicitudes de DI.
                    </p>
                )}
                <p>
                    <span>{ppgcl.ppgcl.codLER.codigoLER}</span> {ppgcl.ppgcl.codLER.descripcion}
                </p>
                <p>
                    <span>
                        {ppgcl.ppgcl?.actualValorizationPercentage ? (
                            ppgcl.ppgcl?.actualValorizationPercentage === 1 ? (
                                /** TODO: Unknown valorization percent should change to "null" */
                                <span className="lerLine__error">Falta el % valorización - </span>
                            ) : (
                                <span className="lerLine__percent">{ppgcl.ppgcl.actualValorizationPercentage}% - </span>
                            )
                        ) : (
                            ''
                        )}
                        {t(
                            `valorizationTypes.${getValorizationType({
                                valorizationCode: ppgcl.ppgcl.procesoGestora.valorizacion.codigoValorizacion,
                                isFinalValorizator: ppgcl.ppgcl.finalValorizator
                            })}`,
                            { ns: 'impact' }
                        )}
                    </span>
                </p>
                <p>
                    Gestora: <span>{ppgcl.ppgcl.procesoGestora.gestora.empresa}</span> - Transportista:{' '}
                    <span>{ppgcl.carrier.procesoGestora.gestora.empresa}</span>
                </p>
                <p>
                    Operador del traslado: <span>{getTransferOperator(ppgcl) ?? '-'}</span>
                </p>
                <p>
                    {ppgcl.cost?.unit !== '' && (
                        <>
                            Coste unitario: <span>{ppgcl.cost?.unit ?? '-'}€ - </span>
                        </>
                    )}
                    {ppgcl.cost?.ton !== '' && (
                        <>
                            Coste por tonelada: <span>{ppgcl.cost?.ton ?? '-'}€ - </span>
                        </>
                    )}
                    Volumen del contenedor: <span>{ppgcl.volumenUnitario} m³</span>
                </p>
            </div>
            {!ppgcl.exists && <IconButton icon={<IconTrash />} action={() => deleteOption(ppgcl)} />}
        </li>
    );
};
export default withAuth(PpgclFinal);
