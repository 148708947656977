import { useTranslation } from 'react-i18next';

import { wasteFlowOptionAdapter } from 'adapters/wasteFlowOptionAdapter';
import BasicButton from 'components/BasicComponents/Buttons/Base/BasicButton';
import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import { SelectOption } from 'components/BasicComponents/Filter/SelectFilter/SelectFilter';
import { useAsyncOptions } from 'components/BasicComponents/Filter/SelectFilter/hooks/useAsyncOptions';
import InputFecha from 'components/BasicComponents/Inputs/InputFecha';
import InputNumber from 'components/BasicComponents/Inputs/InputNumber';
import TextArea from 'components/BasicComponents/Inputs/TextArea';
import SelectAutocomplete from 'components/BasicComponents/Select/SelectAutocomplete';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { WasteFlow, useWasteFlow } from 'modules/waste-flow';
import './WasteRegisterForm.scss';
import { useCheckStakeholdersNotification } from './hooks/useCheckStakeholdersNotification';
import { useWasteRegisterForm } from './hooks/useWasteRegisterForm';

export type WasteRegisterFormProps = {
    uuid?: string;
    promotionId: number;
    onSave?: () => void;
    isEditMode?: boolean;
};

const WasteRegisterForm = (props: WasteRegisterFormProps) => {
    const { t } = useTranslation('wasteRegister');
    const [, analytics] = useAnalyticsStore();
    const form = useWasteRegisterForm(props);
    const [, , { api: wasteFlowApi }] = useWasteFlow();

    const { promotionId } = props;
    const { wasteRegister } = form;

    const { notificationTo } = useCheckStakeholdersNotification({ wasteRegister });

    const [loadPpgclOptions] = useAsyncOptions({
        request: ({ search, page }) => wasteFlowApi.getProjectWasteFlows({ promotionId, search, page }),
        adapter: (wasteFlow, search) => wasteFlowOptionAdapter(wasteFlow, search)
    });

    const modifiers = [form.error ? 'WasteRegisterForm--mutation-error' : ''];

    return (
        <>
            <form
                data-testid="WasteRegisterForm"
                className={`WasteRegisterForm ${modifiers.join(' ')}`}
                onSubmit={async (e) => {
                    e.preventDefault();
                    form.submit();
                }}
            >
                <div>
                    <ErrorCard error={Array.isArray(form.error) ? form.error[0] : form.error}></ErrorCard>
                </div>

                <div className="WasteRegisterForm__flow">
                    <SelectAutocomplete
                        name="flow"
                        label={t('form.flow.label')}
                        placeholder={t('form.flow.placeholder')}
                        loadOptions={loadPpgclOptions}
                        onChange={(o) => form.update({ flow: (o as SelectOption)?.value as WasteFlow })}
                        value={wasteRegister?.flow ? wasteFlowOptionAdapter(wasteRegister.flow) : undefined}
                        onMenuOpen={() => analytics.event(AnalyticsEvents.WASTE_REGISTER_WASTE_FLOW_CLICKED)}
                    />

                    {/* Display only when new register */}
                    {notificationTo.length > 0 && !props.isEditMode && (
                        <p className="WasteRegisterForm__notificationTo">
                            {t('notificationTo')} {notificationTo.join(' y ')}.
                        </p>
                    )}
                </div>

                <InputFecha
                    label={t('form.movementDate.label')}
                    name="date"
                    value={form.wasteRegister?.movementDate}
                    onChange={({ target }) => {
                        form.update({ movementDate: target.value });
                    }}
                    notLimit={true}
                    // Just to ignore optional fields
                    {...({} as any)}
                />

                <InputNumber
                    label={t('form.volume.label')}
                    name="volume"
                    value={form.wasteRegister?.volume}
                    onChange={({ target }) => {
                        form.update({ volume: parseInt(target.value) });
                    }}
                    // Just to ignore optional fields
                    {...({} as any)}
                />

                <div className="WasteRegisterForm__observations">
                    <TextArea
                        id="remarks"
                        label={t('form.observations.label')}
                        placeholder={t('form.observations.placeholder')}
                        name="remarks"
                        value={form.wasteRegister?.observations}
                        onChange={({ target }) => {
                            form.update({ observations: target.value });
                        }}
                        // Just to ignore optional fields
                        {...({} as any)}
                    />
                </div>

                <footer className="WasteRegisterForm__footer">
                    <BasicButton
                        color="purple"
                        type="submit"
                        text={t('form.submit')}
                        iconPosition="left"
                        isLoading={form.loading === 'updating'}
                        // Just to ignore optional fields
                        {...({} as any)}
                    />
                </footer>
            </form>
        </>
    );
};

export default WasteRegisterForm;
