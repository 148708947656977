import { PropsWithChildren } from 'react';
import './CircularProgress.scss';

export interface CircularProgressProps {
    /** percent 0-100 */
    progress?: number;
}

const CircularProgress = ({ progress = 0, children }: PropsWithChildren<CircularProgressProps>) => {
    const percent = (progress * 360) / 100;
    const progressStyle = `conic-gradient(var(--CircularProgress-progress) ${percent}deg, var(--CircularProgress-circle) 0deg)`;

    return (
        <>
            <div
                className="CircularProgress"
                style={{
                    background: progressStyle
                }}
            >
                <svg viewBox="0 0 100 100">
                    <circle cx="50" cy="50" r="44" />
                </svg>
                <p className="CircularProgress__percent">{progress}%</p>
            </div>
            {children && <div className="CircularProgress__text">{children}</div>}
        </>
    );
};

export default CircularProgress;
