import { entitiesAdapter } from 'modules/shared';
import { Thunk } from 'modules/shared/domain/store/createStore';
import { WASTE_REGISTER_INITIAL_CRITERIA, WasteRegisterCriteria } from '../../domain/WasteRegisterCriteria';
import { WasteRegisterStore } from '../../domain/WasteRegisterStore';

type Store = WasteRegisterStore;

export const loadMoreWasteRegisters =
    (criteria: Pick<WasteRegisterCriteria, 'page'>): Thunk<Store> =>
    async (store, { api }) => {
        try {
            const newCriteria = {
                ...store.state.criteria,
                ...criteria,
                size: WASTE_REGISTER_INITIAL_CRITERIA.size
            };

            store.set((draft) => {
                draft.criteria = newCriteria;
                draft.loading = 'pending';

                draft.error = null;
            });

            const { wasteRegisters, total } = await api.findByCriteria(newCriteria);

            const newItems = entitiesAdapter(wasteRegisters, 'uuid');

            store.set((draft) => {
                draft.criteria = newCriteria;

                draft.data.allIds.push(...newItems.allIds);
                draft.data.byId = { ...draft.data.byId, ...newItems.byId };
                draft.total = total;

                draft.loading = 'succeeded';
                draft.error = null;
            });
        } catch (e) {
            const error = e as Error;
            console.error(error);

            store.set((state) => {
                state.error = error;
                state.loading = 'failed';
            });
        }
    };
