import { useEffect } from 'react';

import { TituloH2 } from 'components/BasicComponents/Titulos/Titulos';
import { withAuth } from 'context/AuthProvider';

import '../../../impact/components/Impact.scss';
import ImpactCo2Avoided from '../../../impact/components/ImpactCo2Avoided';
import ImpactCo2Generated from '../../../impact/components/ImpactCo2Generated';
import ImpactLandUse from '../../../impact/components/ImpactLandUse';
import ImpactTableInfoCalculation from '../../../impact/components/ImpactTableInfoCalculation';

import '../ReportPage.scss';
import { useReportImpactData } from './store/reportImpactStore';

const ReportImpact = ({ promocion, isCoCircular }) => {
    const promotionId = promocion?.id;
    const [{ impact }, { fetchImpact }] = useReportImpactData();
    const { equivalencies, impactCo2, impactLandUse } = impact;

    useEffect(() => {
        if (!promotionId) return;

        fetchImpact({
            common: { 'promocion.in': promotionId }
        });
    }, [promotionId]);

    return (
        <>
            <article className="reports__page reports__impact">
                <TituloH2 titulo={'5. MÉTRICAS DE IMPACTO MEDIOAMBIENTAL'} />
                <ImpactTableInfoCalculation />
                <ImpactCo2Generated {...impactCo2} {...{ equivalencies, isCoCircular }} />
            </article>
            <article className="reports__page reports__impact">
                <ImpactCo2Avoided showAccumulatedGraph={false} {...impactCo2} {...{ equivalencies, isCoCircular }} />
                <ImpactLandUse showAccumulatedGraph={false} {...impactLandUse} {...{ equivalencies }} />
            </article>
        </>
    );
};

export default withAuth(ReportImpact);
