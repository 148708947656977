import { useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useTranslation } from 'react-i18next';
import { withAuth } from '../../../context/AuthProvider';
import { traceabilityE2EStatus } from '../../../utils/constants/traceability.constants';
import { customStyles } from '../../../components/BasicComponents/Select-Autocomplete/helpers';
import IconTooltip from '../../../components/BasicComponents/Tooltips/IconTooltip';

const TraceabilityE2EStatusAutocomplete = ({ isClearable = true, ...props }) => {
    const [t] = useTranslation();
    const [defaultValue, setDefaultValue] = useState({ value: '', description: '' });
    const { traceabilityStatus } = props.values;
    const { setValues, values, required, className } = props;

    useEffect(() => {
        getTraceabilityStatus();
    }, [traceabilityStatus]);

    const getTraceabilityStatus = () => {
        const status = traceabilityE2EStatus?.find((status) => status.value === traceabilityStatus);
        setDefaultValue(status);
    };

    const loadOptions = (search) => {
        let optionsStatus = traceabilityE2EStatus;

        if (search) {
            optionsStatus = optionsStatus.filter((option) =>
                option.description.toLowerCase().includes(search.toLowerCase())
            );
        }

        return {
            options: optionsStatus
        };
    };

    const selectOption = (item) => {
        if (item !== null) {
            setValues({ ...values, traceabilityStatus: item?.value });
        } else {
            setValues({ ...values, traceabilityStatus: '' });
        }
    };

    const getTraceabilityE2EStatusTooltip = () => {
        return `Sólo visible y editable por usuarios CoCircular para adaptar traslados con trazabilidad E2E.<br />El estado “solicitud” no contabiliza para las métricas. Si tienes dudas, contacta con los castores.`;
    };

    return (
        <div className={className ? className : 'input__autocomplete input__container'}>
            <div className="input__traceabilityStatus">
                <label className="input__label">
                    {t('traceabilityStatus', { ns: 'traceabilityE2E' })}
                    {required ? '*' : ''}
                </label>
                <IconTooltip msg={getTraceabilityE2EStatusTooltip()} />
            </div>
            <AsyncPaginate
                loadOptions={loadOptions}
                onChange={selectOption}
                getOptionLabel={(option) => option.description}
                getOptionValue={(option) => option}
                value={defaultValue}
                styles={customStyles()}
                placeholder=""
                additional={{
                    page: 0
                }}
                isClearable={isClearable}
            />
        </div>
    );
};
export default withAuth(TraceabilityE2EStatusAutocomplete);
