import { ResponsiveBar } from '@nivo/bar';
import { useTranslation } from 'react-i18next';
import { getBarColor } from 'utils/helpers/colors.helpers';
import { withAuth } from '../../../context/AuthProvider';
import { padding } from '../../../utils/constants/general.constants';
import { currencyFormatDE } from '../../../utils/helpers/general.helpers';
import { MarkersLegend } from './Legends/MarkersLegend';

const MyResponsiveBar = ({ dataForGraphs = [], promocion }) => {
    const [t] = useTranslation();

    const paddingBar = () => {
        const lengthData = dataForGraphs?.length;
        if (lengthData > 0) {
            const findPadding = padding?.find((padding) => padding.length === lengthData);
            return findPadding?.padding || 0.4;
        }
    };

    const UEmarker = 70;
    const selloVerdeMarker = promocion?.porcentajeSelloVerde;
    const selloValorizaMarker = promocion?.valorizacion;

    const markers = [
        {
            name: 'UE',
            axis: 'y',
            value: selloValorizaMarker === UEmarker ? UEmarker + 1.1 : UEmarker,
            lineStyle: { stroke: '#ffa800', strokeWidth: 2, strokeDasharray: '3,3' },
            legendOrientation: 'horizontal',
            translateY: '300',
            textStyle: {
                fill: '#273749',
                fontSize: 12,
                fontFamily: 'Be Vietnam Pro'
            }
        },
        selloVerdeMarker
            ? {
                  name: promocion.selloVerde,
                  value: selloValorizaMarker === selloVerdeMarker ? selloVerdeMarker - 1.1 : selloVerdeMarker,
                  axis: 'y',
                  lineStyle: { stroke: 'green', strokeWidth: 2, strokeDasharray: '8,5,3,3,3,5' },
                  legendOrientation: 'horizontal',
                  textStyle: {
                      fill: '#273749',
                      fontSize: 12,
                      fontFamily: 'Be Vietnam Pro'
                  }
              }
            : null,
        selloValorizaMarker
            ? {
                  name: 'Valoriza',
                  value: selloValorizaMarker,
                  axis: 'y',
                  lineStyle: { stroke: '#4e02bd', strokeWidth: 2 },
                  legendOrientation: 'horizontal',
                  textStyle: {
                      fill: '#273749',
                      fontSize: 12,
                      fontFamily: 'Be Vietnam Pro'
                  }
              }
            : null
    ].filter((marker) => !!marker);

    if (dataForGraphs.length === 0) return <p className="donut__nodata">No hay datos para mostrar</p>;

    return (
        <>
            <MarkersLegend markers={markers} />
            <ResponsiveBar
                data={dataForGraphs}
                groupMode="grouped"
                keys={['percentRecovered']}
                indexBy="typeLer"
                margin={{ top: 30, bottom: 80, left: 50 }}
                padding={paddingBar()}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={(bar) => getBarColor(bar.data.typeLer)}
                borderRadius={10}
                axisTop={null}
                theme={{
                    axis: {
                        fontSize: '14px',
                        tickColor: 'white',
                        ticks: {
                            line: {
                                stroke: 'white'
                            },
                            text: {
                                fill: '#273749',
                                fontSize: 12,
                                fontFamily: 'Be Vietnam Pro'
                            }
                        }
                    }
                }}
                axisRight={null}
                axisBottom={{
                    tickSize: 0,
                    tickPadding: 25,
                    tickRotation: 0,
                    renderTick: ({ opacity, textAnchor, value, x, y }) => {
                        return (
                            <g transform={`translate(${x},${y})`} style={{ opacity }}>
                                <text transform="rotate(-20) translate(-20,15)" textAnchor={textAnchor} fontSize="12px">
                                    {value}
                                </text>
                            </g>
                        );
                    }
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    legend: t('valuation.percentage.upperCase', { ns: 'traceability' }),
                    legendPosition: 'middle',
                    legendOffset: -40
                }}
                animate={false}
                enableLabel={false}
                labelSkipWidth={5}
                labelSkipHeight={12}
                labelTextColor={'#273749'}
                minValue={0}
                maxValue={100}
                markers={markers}
                tooltip={(input) => {
                    return (
                        <div key={input.id}>
                            <p className="donut__tooltip__description">
                                <span>{input.data.typeLer}</span>
                            </p>
                            <p className="donut__tooltip__description">
                                <span>{t('tn.total', { ns: 'traceability' })}:</span>{' '}
                                {currencyFormatDE(input.data.totalWeight)} Tn
                            </p>
                            <p className="donut__tooltip__description">
                                <span>{t('valued.tn', { ns: 'traceability' })}:</span>{' '}
                                {currencyFormatDE(input.data.totalWeightRecovered)} Tn
                            </p>
                            <p className="donut__tooltip__description">
                                <span>{t('valuation.percentage.valued', { ns: 'traceability' })}:</span>{' '}
                                {currencyFormatDE(input.data.percentRecovered)}%
                            </p>
                        </div>
                    );
                }}
            />
        </>
    );
};

const GraficoBarra = (props) => {
    return (
        <div className="barras__container ">
            <MyResponsiveBar dataForGraphs={props.dataForGraphs} promocion={props.promocion} />
        </div>
    );
};

export default withAuth(GraficoBarra);
