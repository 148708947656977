import { contextStoreAdapter } from 'modules/shared';
import { wasteManagementStore } from 'modules/waste-management/application/wasteManagementStore';

const WasteManagementContext = contextStoreAdapter(wasteManagementStore)({ name: 'WasteManagementContext' });
export const WasteManagementProvider = WasteManagementContext.Provider;
export const useWasteManagementStore = WasteManagementContext.useConsumer;

export const wasteManagementApi = {
    useQuery: WasteManagementContext.createQueryHook(({ api }) => api.getWasteManagement),
    useDelete: WasteManagementContext.createMutationHook(({ api }) => api.deleteWasteManagementEntry),
    useCreate: WasteManagementContext.createMutationHook(({ api }) => api.createWasteManagementEntry),
    useSave: WasteManagementContext.createMutationHook(({ api }) => api.saveWasteManagement),
    useMetrics: WasteManagementContext.createQueryHook(({ api }) => api.getMetrics),
    useFlowHistory: WasteManagementContext.createQueryHook(({ api }) => api.getFlowHistory)
};
