import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { IconInfoCircle } from '@tabler/icons';
import userServices from '../../../../api/user/user.services';
import { withAuth } from '../../../../context/AuthProvider';
import ModalEliminar from '../../../BasicComponents/Modales/ModalEliminar';
import SimplePopover from '../../../BasicComponents/Popovers/SimplePopover';
import { TituloH2 } from '../../../BasicComponents/Titulos/Titulos';
import SimpleCard from '../../../BasicComponents/Cards/SimpleCard';
import NewUserForm from '../../NewPromotion/Step3/NewUserForm';
import User from '../../PromotionSettings/User';
import { OddUsersContext } from '../CardNewUserOdd';

const PromotionOddUsers = ({ promocion }) => {
    const [t] = useTranslation();
    const { setUsers, users, setFinishLoading, noUsersPromotion, setNoUsersPromotion } = useContext(OddUsersContext);

    const [toggleDeleteUser, setToggleDeleteUser] = useState(false);
    const [userToEditOrCreate, setUserToEditOrCreate] = useState();
    const [openEditUserPopover, setOpenEditUserPopover] = useState(false);
    const [userToDelete, setUserToDelete] = useState();
    // eslint-disable-next-line
    const [message, setMessage] = useState();

    useEffect(() => {
        searchUsers();
    }, [promocion?.id]);

    const searchUsers = async () => {
        setFinishLoading(false);
        const users = await userServices.getUser(`?promocionId.equals=${promocion.id}&authority.in=ROLE_DUMPMASTER`);
        setUsers(users);
        setFinishLoading(true);
    };

    const openEditPopover = async (user) => {
        const getUser = await userServices.getUser(`?id.equals=${user.id}`);
        setUserToEditOrCreate(getUser[0]);
        setOpenEditUserPopover(!openEditUserPopover);
    };

    const openModalDeleteUser = async (user) => {
        setToggleDeleteUser(!toggleDeleteUser);
        setUserToDelete(user);
    };

    const filterArrWithoutUserDeleted = (user) => {
        setUsers(users.filter((userArr) => userArr.id !== user.id));
    };

    const deleteUser = async () => {
        const userDelete = { ...userToDelete, activated: false };
        const deleteUser = await userServices.editUser(userDelete);
        createMessageDeleteUser(deleteUser);
        filterArrWithoutUserDeleted(deleteUser.data);
    };

    const createMessageDeleteUser = (user) => {
        if (user !== undefined && user.status === 200) {
            setMessage({ text: t('messageDeleteUser.success', { ns: 'traceabilityE2E' }), success: true });
        } else {
            setMessage({ text: t('messageDeleteUser.error', { ns: 'traceabilityE2E' }), success: false });
        }
    };

    return (
        <SimpleCard className="usersOdd__promotionUsers">
            <TituloH2 titulo={t('usersLinked', { ns: 'traceabilityE2E' })} />

            <p className="usersOdd__explication">
                <IconInfoCircle color="#7F42F5" size={35} />
                {t('usersNoLinkedExplication', { ns: 'traceabilityE2E' })}
            </p>

            {users.length > 0 && (
                <ul className="usersOdd__ul usersOdd__scroll">
                    {users.map((user) => (
                        <User
                            promotion={promocion}
                            userPromotion={user}
                            key={user.id}
                            disassociateUser={true}
                            editUserOptions={true}
                            {...{
                                openModalDeleteUser,
                                openEditPopover,
                                setUsers,
                                users,
                                noUsersPromotion,
                                setNoUsersPromotion
                            }}
                        />
                    ))}
                </ul>
            )}

            {openEditUserPopover && (
                <SimplePopover
                    openModal={openEditUserPopover}
                    setOpenModal={setOpenEditUserPopover}
                    title={userToEditOrCreate?.id ? t('editUser', { ns: 'users' }) : t('newUser', { ns: 'users' })}
                    className="simplePopover newPromotion__popover"
                    addFunctionOnClose={() => setUserToEditOrCreate()}
                >
                    <NewUserForm
                        setToggleFormNewUser={setOpenEditUserPopover}
                        onlyDumpmaster={true}
                        {...{ userToEditOrCreate, setUsers, users }}
                        promotion={promocion}
                    />
                </SimplePopover>
            )}

            {toggleDeleteUser && (
                <ModalEliminar
                    title={t('deleteUser', { ns: 'traceabilityE2E' })}
                    subtitle={`${t('deleteUserSubtitle', { ns: 'traceabilityE2E' })} ${userToDelete.firstName} ${
                        userToDelete.lastName
                    }? ${t('deleteUserSubtitlePt2', { ns: 'traceabilityE2E' })}`}
                    openModal={toggleDeleteUser}
                    setOpenModal={setToggleDeleteUser}
                    actionText={t('deleteUser', { ns: 'traceabilityE2E' })}
                    deleteFunction={deleteUser}
                />
            )}
        </SimpleCard>
    );
};

export default withAuth(PromotionOddUsers);
