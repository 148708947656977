import './MarkersLegend.scss';

interface MarkersLegendProps {
    markers?: Array<{
        name: string;
        lineStyle: {
            stroke: string;
            strokeWidth: number;
            strokeDasharray: number;
        };
    }>;
}

export const MarkersLegend = ({ markers }: MarkersLegendProps) => {
    if (!markers || markers.length === 0) return null;

    return (
        <ul className="MarkersLegend">
            {markers?.map((marker) => (
                <li className="MarkersLegend__marker">
                    <svg className="MarkersLegend__line" height={marker.lineStyle.strokeWidth} width="20">
                        <line
                            y1="1"
                            x2="20"
                            y2="1"
                            stroke={marker.lineStyle.stroke}
                            strokeWidth={marker.lineStyle.strokeWidth}
                            strokeDasharray={marker.lineStyle.strokeDasharray}
                        />
                    </svg>
                    <p className="MarkersLegend__label">{marker.name}</p>
                </li>
            ))}
        </ul>
    );
};
