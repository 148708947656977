import { useState } from 'react';

const useInfiniteScroll = () => {
    const [page, setPage] = useState(0);
    const [samePage, setSamePage] = useState(false);

    const increasePage = () => {
        setPage(page + 1);
    };
    const resetPage = () => {
        if (page === 0) {
            setSamePage(!samePage);
        } else {
            setPage(0);
        }
    };

    const isScrolling = () => {
        setPage(page + 1);
    };

    return { isScrolling, page, increasePage, resetPage, samePage };
};

export default useInfiniteScroll;
