import { BasicTooltip, useTooltip } from '@nivo/tooltip';

const CustomStackedBar = ({ barProps, tooltipRef }) => {
    const { showTooltipFromEvent, hideTooltip } = useTooltip();

    const { x, y, width, height, data } = barProps;
    const potentialDetail = data.data.PotentialDetail;

    const lerName = data.indexValue;

    const BORDER_ROUND = 3;
    const EXIST_MIX = potentialDetail.mix > 0;

    const heightValue = parseInt(data.value);
    const heightScalePercentage = heightValue > 0 ? height / heightValue : 0;

    const mixHeightInPX = potentialDetail.mix * heightScalePercentage;
    const lerHeightInPX = potentialDetail.ler * heightScalePercentage;

    const lerHeightYCoordinate = y + height - lerHeightInPX;
    const mixHeightYCoordinate = y + height - mixHeightInPX;

    const onMouseHoverHandler = ({ event, type }: { event: any; type: 'ler' | 'mix' }) => {
        const mix = { label: 'Potencial mezclas', color: '#70987d', value: potentialDetail.mix };
        const ler = { label: 'Potencial', color: barProps.color, value: potentialDetail.ler };

        const tooltipData = { ler, mix };

        showTooltipFromEvent(
            <BasicTooltip
                id={`${tooltipData[type].label} - ${lerName}`}
                color={tooltipData[type].color}
                value={tooltipData[type].value}
                enableChip={true}
            />,
            event
        );
    };

    return (
        <g>
            {/* To achive rounded bars, we round them with rx and ry, and then, with the inset used trought clipPath
             we cut the bottom or the top (depending on the case). After that cut, there is a empty part between both
             bars, so we play with height and y values to compensate the cut. */}
            <rect
                // the fallback color just in case to avoid black color on graphic
                fill={'#70987d'}
                height={mixHeightInPX + BORDER_ROUND}
                width={width}
                x={x}
                y={mixHeightYCoordinate - lerHeightInPX}
                rx={BORDER_ROUND}
                ry={BORDER_ROUND}
                style={{ clipPath: 'inset(0px 0px 3px 0px)' }}
                onMouseLeave={() => hideTooltip()}
                onMouseEnter={(event) => onMouseHoverHandler({ event, type: 'mix' })}
                onMouseMove={(event) => onMouseHoverHandler({ event, type: 'mix' })}
            />
            <rect
                fill={barProps.color}
                height={lerHeightInPX + (EXIST_MIX ? BORDER_ROUND : 0)}
                width={width}
                x={x}
                y={lerHeightYCoordinate - (EXIST_MIX ? BORDER_ROUND : 0)}
                rx={BORDER_ROUND}
                ry={BORDER_ROUND}
                style={{ clipPath: EXIST_MIX ? 'inset(3px 0px 0px 0px)' : 'none' }}
                onMouseLeave={() => hideTooltip()}
                onMouseEnter={(event) => onMouseHoverHandler({ event, type: 'ler' })}
                onMouseMove={(event) => onMouseHoverHandler({ event, type: 'ler' })}
            />
        </g>
    );
};

export default CustomStackedBar;
