import { IconAlertCircle } from '@tabler/icons';
import axios, { AxiosError } from 'axios';
import { DomainException } from 'modules/shared';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyState from '../EmptyState';
import AlertCard from './AlertCard';

interface ErrorCardProps {
    error?: Error | DomainException | null;
    className?: string;
    tx?: { ns: string; [key: string]: any };
}

const ErrorCard = ({ error, className, children, tx }: PropsWithChildren<ErrorCardProps>) => {
    const [t] = useTranslation();
    if (!error) return null;

    // Prevents print cancel errors
    if (axios.isCancel(error)) return null;

    const isDomainException = error instanceof DomainException;
    const title = (error instanceof DomainException && t(error?.cause?.code, tx)) || (error as Error)?.message;

    if (error?.message?.includes('403')) {
        return <EmptyState className={className} mode="forbidden" />;
    }

    const apiError = (error as AxiosError).response;

    const errorDetail = (apiError && apiError.data) || error?.stack;

    return (
        <AlertCard
            title={title}
            className={`ErrorCard card__alert-error ${className || ''}`}
            icon={<IconAlertCircle />}
        >
            {children && <p>{children}</p>}
            {isDomainException && t(error?.cause?.description, tx)}
            {!children && !isDomainException && (
                <details>
                    <pre style={{ fontSize: '10px' }}>{JSON.stringify(errorDetail, null, 2)}</pre>
                </details>
            )}
        </AlertCard>
    );
};

export default ErrorCard;
