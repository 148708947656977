import { useEffect, useState } from 'react';

import promotionServices from '../api/promotion/promotion.services';
import { useFilter } from './useFilter';

export const useAllPromotions = (values, page, samePage) => {
    const [allPromotions, setAllPromotions] = useState([]);
    const [loadingPromotions, setLoadingPromotions] = useState(true);

    const objFilter = {
        id: `&id.equals=${values.id}`,
        num: `&num.equals=${values.num}`,
        enterprise: `&enterpriseId.equals=${values.enterprise?.id}`,
        promotion: `&id.equals=${values.promotion?.id}`,
        province: `&provincia.equals=${values.province.nombre}`,
        promotionStatus: `${values.promotionStatus.value}`
    };
    const { filter, filterReady } = useFilter({ values, objFilter, page, samePage });

    const getAllPromotions = async () => {
        setLoadingPromotions(true);
        promotionServices
            .filterPromotion(filter)
            .then((result) => {
                if (page === 0) {
                    setAllPromotions(result);
                } else {
                    setAllPromotions(allPromotions.concat(result));
                }
                setLoadingPromotions(false);
            })
            .catch(console.log);
    };
    useEffect(() => {
        if (filterReady) {
            getAllPromotions();
        }
    }, [filterReady, filter]);

    return { allPromotions, loadingPromotions, getAllPromotions, setAllPromotions };
};
