import { useTranslation } from 'react-i18next';

import KPITile from 'features/KPIs/components/KPITile';
import { Widget } from 'features/KPIs/types/Widget';
import { calcKPIStatusFromGoal, useMandatoryFlowsSummary } from 'modules/promotion-kpis';
import KPIThermometer from '../../components/KPIThermometer';
import MandatoryFlowsDetails from './components/MandatoryFlowsDetails';

const useMandatoryFlowsWidget: Widget = (props) => {
    const [t] = useTranslation('kpis');
    const summary = useMandatoryFlowsSummary(props);

    const total = summary.data?.lerTypes.length || null;
    const value = total ? summary.data?.lerTypes.filter((lerType) => lerType.met).length : null;
    const status = calcKPIStatusFromGoal(summary.data);

    return {
        status,
        Tile: () => (
            <KPITile
                label={t('mandatory-flows.label')}
                unit={''}
                value={summary.loading === 'succeeded' ? value : undefined}
                total={summary.loading === 'succeeded' ? total : undefined}
                hasError={!!summary.error}
                status={status}
            >
                <KPIThermometer
                    type="discrete"
                    value={value}
                    maxValue={total || 0}
                    status={summary.data?.lerTypes.map((flow) => calcKPIStatusFromGoal(flow))}
                />
            </KPITile>
        ),
        Detail: () => {
            return <MandatoryFlowsDetails promotionId={props.promotionId} />;
        }
    };
};

export default useMandatoryFlowsWidget;
