import CircularProgress from 'components/BasicComponents/Progress/CircularProgress/CircularProgress';
import { useEffect, useState } from 'react';

interface ReportProgressProps {
    maxCount: number;
}

function ReportProgress({ maxCount }: ReportProgressProps) {
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (count >= maxCount) return;

        setTimeout(() => {
            setCount(count + 1);
        }, 1000);
    }, [maxCount, count]);

    return (
        <div className="reports__progress">
            <CircularProgress progress={(count * 100) / maxCount}>Generando informe...</CircularProgress>
        </div>
    );
}

export default ReportProgress;
