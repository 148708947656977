import { promotionKPIsStore } from 'modules/promotion-kpis/application/promotionKPIsStore';
import { contextStoreAdapter } from 'modules/shared';

const PromotionKPIsContext = contextStoreAdapter(promotionKPIsStore)({ name: 'PromotionKPIsContext' });

export const PromotionKPIsProvider = PromotionKPIsContext.Provider;
export const usePromotionKPIs = PromotionKPIsContext.useConsumer;

export const useValorizationSummary = PromotionKPIsContext.createQueryHook(({ api }) => api.getValorizationSummary);
export const useValorizationByScope = PromotionKPIsContext.createQueryHook(({ api }) => api.getValorizationByScope);
export const useMixSummary = PromotionKPIsContext.createQueryHook(({ api }) => api.getMixSummary);
export const useMixByScope = PromotionKPIsContext.createQueryHook(({ api }) => api.getMixByScope);
export const useMandatoryFlowsSummary = PromotionKPIsContext.createQueryHook(({ api }) => api.getMandatoryFlowsSummary);
export const useMandatoryFlowsDetails = PromotionKPIsContext.createQueryHook(({ api }) => api.getMandatoryFlowsDetails);
export const useCertifiedTraceabilities = PromotionKPIsContext.createQueryHook(
    ({ api }) => api.getCertifiedTraceabilities
);
export const useGoals = PromotionKPIsContext.createQueryHook(({ api }) => api.getGoals);
export const useGoalSave = PromotionKPIsContext.createMutationHook(({ api }) => api.saveGoal);
export const useGoalRemove = PromotionKPIsContext.createMutationHook(({ api }) => api.removeGoal);
