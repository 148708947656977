import {
    IconArrowLeft,
    IconBuildingCommunity,
    IconCalendar,
    IconChevronLeft,
    IconCoin,
    IconDeviceAnalytics,
    IconExternalLink,
    IconFileText,
    IconGlobe,
    IconHelp,
    IconHome,
    IconLogout,
    IconMenu2,
    IconPlus,
    IconSettings,
    IconTruck,
    IconX
} from '@tabler/icons';
import { IconRobot } from 'icons';
import { useAuth } from 'modules/auth';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import logoImg from '../../../assets/logo/logo360.png';
import { withAuth } from '../../../context/AuthProvider';
import HasPermission from '../../AccessCheckers/HasPermission';
import FastAccessButton from '../../BasicComponents/Buttons/FastAccessButton';
import EnvLabel from '../EnvLabel/EnvLabel';
import Tooltip from '../Tooltips/Tooltip';
import LinkNavBar from './LinkNavBar';
import './Navbar.scss';

const NAVBAR_COLLAPSED_KEY = 'navbar--collapsed';
const TOOLTIP_DELAY = 500;

const Navbar = ({ isWasteStakeholder, promocion: promotion }) => {
    const [, { logout }] = useAuth();
    const [t] = useTranslation();
    const [showMenu, setShowMenu] = useState(false);
    const [collapsed, setCollapsed] = useState(localStorage.getItem(NAVBAR_COLLAPSED_KEY) === 'true');

    // We cant use this hook until all sections (documents, costs) are inside /promotion too
    // const [{ promotion }] = usePromotion();

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const navbarItemsGlobal = [
        {
            ifRole: 'canView',
            section: 'enterprise',
            link: '/home',
            Icon: IconHome,
            title: t('home', { ns: 'common' })
        },
        {
            ifRole: 'canView',
            section: 'impact',
            link: '/impact',
            Icon: IconGlobe,
            title: t('impact', { ns: 'common' })
        },
        {
            ifRole: 'canView',
            section: 'dashboard',
            link: '/dashboard',
            Icon: IconDeviceAnalytics,
            title: t('globalDashboard', { ns: 'dashboard' })
        },
        {
            ifRole: 'canCreate',
            section: 'globalSettings',
            link: '/settings',
            Icon: IconSettings,
            title: t('usersConfiguration', { ns: 'common' })
        },
        {
            type: 'header',
            title: t('cocircularAdmin'),
            ifRole: 'canView',
            section: 'admin'
        },
        {
            ifRole: 'canCreate',
            section: 'promotion',
            link: '/settings/new-promotion',
            Icon: IconPlus,
            title: t('title', { ns: 'newPromotion' })
        },
        {
            ifRole: 'canView',
            section: 'admin',
            link: '/admin/walle',
            Icon: IconRobot,
            title: t('title', { ns: 'walle' })
        }
    ];
    const navbarItemsPromotion = (promotion) => [
        {
            ifRole: 'canView',
            section: 'enterprise',
            link: '/home',
            Icon: IconArrowLeft,
            title: t('home', { ns: 'common' })
        },
        {
            type: 'header',
            title: `${promotion.nombre}`
        },
        {
            ifRole: 'canView',
            section: 'promotion',
            link: `/promotion/${promotion?.id}/expedient`,
            Icon: IconBuildingCommunity,
            title: t('promotion.upperCase', { ns: 'common' })
        },
        {
            ifRole: 'canView',
            section: 'documentation',
            link: '/documents',
            Icon: IconFileText,
            title: t('documentation.upperCase', { ns: 'common' })
        },
        {
            ifRole: 'canView',
            section: 'traceability',
            link: `/promotion/${promotion?.id}/traceabilities`,
            Icon: IconTruck,
            title: t('title', { ns: 'traceability' })
        },
        {
            ifRole: 'canView',
            section: 'costs',
            link: '/costs',
            Icon: IconCoin,
            title: t('title', { ns: 'costs' }),
            showIf: promotion?.permissions?.costs
        },
        {
            ifRole: 'canView',
            section: ['activities', 'siteChecking'],
            link: `/promotion/${promotion?.id}/activities`,
            Icon: IconCalendar,
            title: t('title', { ns: 'activities' })
        },
        {
            ifRole: 'canView',
            section: 'impact',
            link: `/promotion/${promotion?.id}/impact`,
            Icon: IconGlobe,
            title: t('impact', { ns: 'common' })
        },
        {
            ifRole: 'canView',
            section: 'configuration',
            link: `/promotion/${promotion?.id}/settings`,
            Icon: IconSettings,
            title: t('settings', { ns: 'newPromotion' })
        }
    ];

    const navbarItemsWasteManager = [
        {
            ifRole: 'canView',
            section: 'wasteManagerTraceability',
            link: '/waste-manager-traceability',
            Icon: IconTruck,
            title: t('title', { ns: 'traceability' })
        }
    ];

    const generalItems = [
        {
            Icon: IconHelp,
            title: 'Centro de ayuda',
            link: '/help'
        },
        {
            link: 'https://www.cocircular.es/',
            Icon: IconExternalLink,
            title: 'CoCircular',
            externalLink: true,
            hideActiveClassName: true,
            target: '_blank'
        }
    ];

    const getNavBarItems = () => {
        if (isWasteStakeholder) {
            return navbarItemsWasteManager;
        }

        if (promotion?.id) {
            return navbarItemsPromotion(promotion);
        }

        return navbarItemsGlobal;
    };

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
        localStorage.setItem(NAVBAR_COLLAPSED_KEY, collapsed ? 'false' : 'true');
    };

    const modifiers = {
        active: showMenu ? 'active' : '',
        collapsed: collapsed ? 'navbar--collapsed' : ''
    };

    return (
        <nav className={`navbar ${modifiers.active} ${modifiers.collapsed}`}>
            <div className="navbar__top">
                <NavLink className="navbar__logo" to="/">
                    <img src={logoImg} alt="Logo 360 Advisor" className="nav-imgLogo" />
                    <EnvLabel text="development" />
                </NavLink>
                <div className="nav-hamburguer" onClick={() => toggleMenu()}>
                    {showMenu ? (
                        <IconX color="#7F42F5" size={32} stroke={1.5} />
                    ) : (
                        <IconMenu2 color="#7F42F5" size={32} stroke={1.5} />
                    )}
                </div>
            </div>
            {showMenu && (
                // TODO: show commented line if dumpmaster
                <div className="navbar__selectedPromotion">
                    <HasPermission ifRole="canView" section="wasteConfiguration">
                        <Link to="/waste-manager/dashboard">
                            <FastAccessButton type="recycle" />
                        </Link>
                    </HasPermission>

                    <HasPermission ifRole="canCreate" section="promotion">
                        <Link to={`/promotion/${promotion?.id}/edit`}>
                            <FastAccessButton type="edit" />
                        </Link>
                    </HasPermission>
                </div>
            )}
            <ul className="navbar__items">
                <div className={'navbar__item ' + (showMenu ? 'active' : '')}>
                    {getNavBarItems().map((item) => {
                        if (item.showIf === false) return <></>;

                        if (item.type === 'header') {
                            return (
                                <HasPermission ifRole={item.ifRole} section={item.section}>
                                    <header className="navbar__section-header">
                                        <span>{item.title}</span>
                                    </header>
                                </HasPermission>
                            );
                        }

                        return (
                            <Tooltip
                                key={item.title}
                                msg={collapsed && item.title}
                                offset={{}}
                                delayShow={TOOLTIP_DELAY}
                            >
                                <LinkNavBar {...item} key={item.link} />
                            </Tooltip>
                        );
                    })}
                </div>

                <div className={'navbar__item' + (showMenu ? 'active' : '')}>
                    {generalItems.map((item) => (
                        <Tooltip key={item.title} msg={collapsed && item.title} offset={{}} delayShow={TOOLTIP_DELAY}>
                            <LinkNavBar {...item} key={item.link} />
                        </Tooltip>
                    ))}
                    {showMenu && (
                        <LinkNavBar
                            link="/"
                            Icon={IconLogout}
                            title={t('logOut', { ns: 'common' })}
                            hideActiveClassName={true}
                            action={logout}
                        />
                    )}
                </div>
            </ul>
            <div className="navbar__bottom">
                <Tooltip msg={collapsed && t('expandMenu', { ns: 'common' })} offset={{}} delayShow={TOOLTIP_DELAY}>
                    <button className="navbar__toggle-collapse" onClick={toggleCollapsed}>
                        <IconChevronLeft size={20} />
                        <span>{t('collapseMenu', { ns: 'common' })}</span>
                    </button>
                </Tooltip>
            </div>
        </nav>
    );
};
export default withAuth(Navbar);
