import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconChevronRight } from '@tabler/icons';
import { withAuth } from '../../../context/AuthProvider';
import SmallButton from '../../../components/BasicComponents/Buttons/Small/SmallButton';
import GraficoBarrasPopover from '../../../components/BasicComponents/Popovers/GraficoBarrasPopover';
import GraficoBarra from '../../../components/BasicComponents/Graficos/GraficoBarra';
import { TituloH3 } from '../../BasicComponents/Titulos/Titulos';

const BarrasPromocion = ({ graphicsData }) => {
    const [t] = useTranslation();
    const [openPopover, setOpenPopover] = useState(false);

    const toggleButton = () => {
        setOpenPopover(!openPopover);
    };

    return (
        <div>
            <TituloH3
                titulo={t('valuation.percentage.tons', { ns: 'traceability' })}
                tooltip={`${t('valuation.percentage.recovery', { ns: 'traceability' })}.`}
            />
            <SmallButton
                titulo={t('viewDetail', { ns: 'common' })}
                icon={<IconChevronRight size={18} stroke={1.4} />}
                iconPosition="right"
                action={toggleButton}
            />
            <GraficoBarra dataForGraphs={graphicsData} />
            {openPopover && (
                <GraficoBarrasPopover openModal={openPopover} setOpenModal={toggleButton} data={graphicsData} />
            )}
        </div>
    );
};
export default withAuth(BarrasPromocion);
