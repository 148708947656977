import { useEffect, useState } from 'react';

import { UserWasteManagerSelectorProps } from '../UserWasteManagerSelector';

export const useResetWhenEnterpriseChanges = (props: UserWasteManagerSelectorProps) => {
    const [enterpriseId, setEnterpriseId] = useState<number | undefined>(props.enterpriseId);

    useEffect(() => {
        if (!enterpriseId) {
            setEnterpriseId(props.enterpriseId);
            return;
        }

        if (props.enterpriseId === enterpriseId) return;

        props.onChange?.([]);
    }, [props.enterpriseId, enterpriseId]);
};
