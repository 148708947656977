import { createContext, useState } from 'react';
import { withAuth } from '../../../context/AuthProvider';
import './UsersOdd.scss';

export const OddUsersContext = createContext({});

const { Provider } = OddUsersContext;

const CardNewUserOdd = ({ children }) => {
    //dumpmaster users only
    const [users, setUsers] = useState([]);
    const [noUsersPromotion, setNoUsersPromotion] = useState([]);
    const [finishLoading, setFinishLoading] = useState(false);

    return (
        <Provider value={{ users, setUsers, finishLoading, setFinishLoading, noUsersPromotion, setNoUsersPromotion }}>
            {children}
        </Provider>
    );
};
export default withAuth(CardNewUserOdd);
