import fetcher from '../../../lib/fetcher';

export const getPpgclWithLinkedWasteManager = async (filter = '') => {
    const URL = `api/maintenance/waste-manager-with-user-by-promotion${filter}`;
    try {
        const response = await fetcher.get(URL);
        return response;
    } catch (error) {
        console.log(error);
    }
};
