import { Link } from 'react-router-dom';

import { legacyEnterpriseAdapter } from 'modules/enterprise/related-enterprise/domain/legacyEnterpriseAdapter';
import { withAuth } from '../../../context/AuthProvider';
import FastAccessButton from '../Buttons/FastAccessButton';
import MenuDetailTopBar from '../../ComplexComponents/TopBar/MenuDetailTopBar';
import HasPermission from '../../AccessCheckers/HasPermission';
import './TopBar.scss';

const TopBar = (props) => {
    const { isCoCircular } = props;

    return (
        <>
            <div className="topbar">
                <div className="topbar__promoData">
                    {props?.promocion?.id && (
                        <>
                            <p className="topbar__enterprise">
                                {legacyEnterpriseAdapter(props.promocion?.relatedEnterprises)?.nombreComercial}
                            </p>
                            <span className="topbar__separator" />
                            <p className="topbar__enterprise">
                                {isCoCircular && `${props.promocion?.num} -`} {props.promocion?.nombre}
                            </p>
                        </>
                    )}
                    {props.promocion?.id && (
                        <>
                            <HasPermission ifRole="canView" section="wasteConfiguration">
                                <Link to="/waste-manager/dashboard">
                                    <FastAccessButton type="recycle" className="fastAccessButtons" />
                                </Link>
                            </HasPermission>
                        </>
                    )}
                </div>
                <MenuDetailTopBar />
            </div>
        </>
    );
};
export default withAuth(TopBar);
