import { IconRecycle } from '@tabler/icons';
import { withAuth } from '../../../context/AuthProvider';
import RoundedIcon from '../../BasicComponents/Icon/RoundedIcon';
import IconTooltip from '../Tooltips/IconTooltip';
import GraficoBarra from '../Graficos/GraficoBarra';
import { currencyFormatDE } from '../../../utils/helpers/general.helpers';
import SimplePopover from './SimplePopover';

const GraficoBarrasPopover = ({ openModal, setOpenModal, noClose, data }) => {
    return (
        <SimplePopover
            openModal={openModal}
            setOpenModal={setOpenModal}
            noClose={noClose}
            title="Detalle toneladas valorizadas por tipo de LER"
        >
            <div className="popover__container popoverDonut">
                <div className="popoverDonut__graphic">
                    <GraficoBarra dataForGraphs={data} />
                </div>
                <ul>
                    {data.map((ler) => (
                        <li key={ler.typeLer}>
                            <div className="popoverDonut__container">
                                <RoundedIcon icon={<IconRecycle size={18} stroke={1.7} />} />
                                {ler.typeLer} ({currencyFormatDE(ler.totalWeightRecovered.toFixed(2))} t -{' '}
                                {currencyFormatDE(ler?.percentRecovered)}%)
                            </div>
                            {ler.lers.map((codigos) => (
                                <div key={codigos.codLer} className="popoverDonut__desglose">
                                    <p className="popoverDonut__ler">
                                        {codigos.codLer}
                                        <IconTooltip msg={codigos.description} />:{' '}
                                        {currencyFormatDE(codigos.totalWeightRecovered.toFixed(2))} t -{' '}
                                        {currencyFormatDE(codigos?.percentRecovered)}%
                                    </p>
                                </div>
                            ))}
                        </li>
                    ))}
                </ul>
            </div>
        </SimplePopover>
    );
};
export default withAuth(GraficoBarrasPopover);
