import SimplePopover from 'components/BasicComponents/Popovers/SimplePopover';
import ProgressBar from 'components/BasicComponents/Progress/ProgressBar/ProgressBar';
import TagBadge from 'features/tags/components/TagBadge';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { useAuth } from 'modules/auth';
import { usePromotion } from 'modules/promotion';
import { currencyFormatDE, getDiffDays, roundNumber } from 'utils/helpers/general.helpers';
import './PromotionInfoModal.scss';
import WalleMailAddress from './WalleMailAddress';

interface PromotionInfoModalProps {
    openModal: boolean;
    setOpenModal: (value: boolean) => void;
}

const PromotionInfoModal = ({ openModal, setOpenModal }: PromotionInfoModalProps) => {
    const [{ isCoCircular }] = useAuth();
    const { format } = useDateFormatter();
    const [{ promotion, promotionPreviews }] = usePromotion();
    const tags = promotionPreviews?.[0]?.tags || [];

    if (!promotion) return <></>;

    const mainEnterprise = promotion.relatedEnterprises?.find((enterprise) => enterprise.mainEnterprise === true);

    const dates = [
        promotion.fechaInicio,
        promotion.startDateCocircular,
        promotion.fechaFin,
        promotion.endDateCocircular
    ];

    const oldestDate = dates.reduce((c, n) => (Date.parse(n) < Date.parse(c) ? n : c));
    const newestDate = dates.reduce((c, n) => (Date.parse(n) > Date.parse(c) ? n : c));

    const diffDaysOldestAndNewest = getDiffDays(oldestDate, newestDate);
    const diffDaysInitEnd = getDiffDays(promotion.fechaInicio, promotion.fechaFin);
    // Regla de 3 para sacar el ancho de la progress bar
    const progressBarPercentageWidth = (diffDaysInitEnd * 100) / diffDaysOldestAndNewest;

    const getDatePositionPercentage = (diffDays) => {
        const datePositionPercentage = roundNumber((diffDays * 100) / diffDaysOldestAndNewest, 0);
        return datePositionPercentage;
    };

    // Position of each date (in percentage)
    const positionInitProject = getDatePositionPercentage(getDiffDays(oldestDate, promotion.fechaInicio));
    const positionInitServices = getDatePositionPercentage(getDiffDays(oldestDate, promotion.startDateCocircular));
    const positionEndProject = getDatePositionPercentage(getDiffDays(oldestDate, promotion.fechaFin));
    const positionEndServices = getDatePositionPercentage(getDiffDays(oldestDate, promotion.endDateCocircular));

    return (
        <SimplePopover
            openModal={openModal}
            setOpenModal={setOpenModal}
            title={promotion.nombre}
            className="PromotionInfoModal"
        >
            {tags.length > 0 && (
                <div className="PromotionInfoModal__tags">
                    {tags?.map((tag) => (
                        <TagBadge name={tag.name} color={tag.color as any} />
                    ))}
                </div>
            )}
            <div className="PromotionInfoModal__extraData">
                {isCoCircular && (
                    <>
                        <p>ID {promotion.id}</p>
                        <p>NUM {promotion.num}</p>
                    </>
                )}
                <p>{promotion.promotionType.nombre}</p>
                <p>{currencyFormatDE(promotion.surface)} m²</p>
                {promotion.promotionType.additionalProperties?.numHouses && (
                    <p>{promotion.promotionType.additionalProperties?.numHouses} viviendas</p>
                )}
                {promotion.promotionType.additionalProperties?.power && (
                    <p>{promotion.promotionType.additionalProperties?.power} MW</p>
                )}
                {mainEnterprise?.enterpriseName && <p>{mainEnterprise?.enterpriseName}</p>}
                <div>
                    {promotion?.direccion.direccion}, {promotion?.direccion.municipio.nombre},{' '}
                    {promotion?.direccion.postalCode} ({promotion?.direccion.municipio.provincia.nombre})
                </div>
            </div>

            <WalleMailAddress />

            <div className="PromotionInfoModal__projectProgress">
                <div className="PromotionInfoModal__backgroundBar"></div>
                <ProgressBar
                    displayPercentageText={true}
                    percentage={promotion.progress}
                    style={{
                        width: `calc(${progressBarPercentageWidth}% - 2px)`, // -2px to compensate purple border
                        position: 'absolute',
                        left: `calc(${positionInitProject}% + 1px)`, // +1px to compensate purple border
                        top: '1px' // +1px to compensate purple border
                    }}
                />
                {/* DATES */}
                <p
                    className="PromotionInfoModal__date PromotionInfoModal__date--left PromotionInfoModal__date--bottom"
                    style={{ left: positionInitProject + '%' }}
                >
                    <span>Inicio proyecto</span>
                    <span>{format(promotion.fechaInicio, 'DD/MM/YYYY')}</span>
                </p>
                <p
                    className="PromotionInfoModal__date PromotionInfoModal__date--left PromotionInfoModal__date--top"
                    style={{ left: positionInitServices + '%' }}
                >
                    <span>Inicio servicios CoCircular</span>
                    <span>{format(promotion.startDateCocircular, 'DD/MM/YYYY')}</span>
                </p>
                <p
                    className="PromotionInfoModal__date PromotionInfoModal__date--right PromotionInfoModal__date--bottom"
                    style={{ left: positionEndProject + '%' }}
                >
                    <span>Fin proyecto</span>
                    <span>{format(promotion.fechaFin, 'DD/MM/YYYY')}</span>
                </p>
                <p
                    className="PromotionInfoModal__date  PromotionInfoModal__date--right PromotionInfoModal__date--top"
                    style={{ left: positionEndServices + '%' }}
                >
                    <span>Fin servicios CoCircular</span>
                    <span>{format(promotion.endDateCocircular, 'DD/MM/YYYY')}</span>
                </p>
            </div>
        </SimplePopover>
    );
};

export default PromotionInfoModal;
