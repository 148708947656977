import { AnalyticsEvents, useAnalyticsEvent } from 'modules/analytics';
import Home from 'pages/Home/Home';
import './PromotionAnalysis.scss';

const PromotionAnalysis = () => {
    useAnalyticsEvent(AnalyticsEvents.HOME_ANALYSIS_REACHED);

    return (
        <>
            <Home />
        </>
    );
};

export default PromotionAnalysis;
