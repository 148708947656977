import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';
import promoProGesCodLersServices from '../../../api/promoProGesCodLers/promoProGesCodLers.services';
import { withAuth } from '../../../context/AuthProvider';
import { createFilterWithObject, differenceArrays, removeDuplicates } from '../../../utils/helpers/general.helpers';
import { getPpgclWithLinkedWasteManager } from '../../../features/traceability_e2e/api/getPpgclWithLinkedWasteManager';
import { customStyles, getCarrierIds, hasMore } from './helpers';
import { DEFAULT_OPTIONS_AUTOCOMPLETE } from './autocomplete.helpers';

const PromoProGesCodLerAutocomplete = ({
    setValues,
    values,
    name = 'gestora',
    value,
    required,
    typeOfWasteManager = 'WASTE_MANAGER',
    className,
    promocion,
    validacion,
    INITIAL_STATE = '',
    newActivity,
    //por defecto, el nombre de codLer, wasteManager y carrier en el obj initial de los values
    codLer = 'codLer',
    wasteManager = 'gestora',
    carrier = 'transportista',
    customLabel = null,
    requestContainerODD = false,
    multiselect,
    preLoadOptions = false,
    requiredFieldsToPreLoadOptions = [],
    resetFields = [],
    disabled = false
}) => {
    const [t] = useTranslation();
    const [notComplete, setNotComplete] = useState(false);
    const getWasteManagerNames = (options) => {
        return {
            CARRIER: isCarrier(options),
            TRANSFER_OPERATOR: isTransferOperator(options),
            WASTE_MANAGER: isWasteManager(options)
        };
    };

    const getCarrierFilter = () => {
        if (Array.isArray(values[carrier])) {
            return `&carrierId.contains=${carrierIds}`;
        }
        return `&carrierId.equals=${carrierIds}`;
    };

    const carrierIds = getCarrierIds(values[carrier]);
    const filterValues = {
        [codLer]: `&codLer.equals=${values[codLer]?.codigoLER}`,
        [carrier]: carrierIds ? getCarrierFilter() : '',
        [wasteManager]: `&gestoraId.equals=${values[wasteManager]?.id}`
    };

    const getSearchName = (search) => {
        return {
            CARRIER: `&carrierName.contains=${search}`,
            TRANSFER_OPERATOR: `&operatorName.contains=${search}`,
            WASTE_MANAGER: `&gestoraName.contains=${search}`
        };
    };

    const getFilter = (page, search) => {
        let filter = `?page=${page}`;
        if (promocion) {
            filter += `&promocionId.equals=${promocion.id}`;
        }
        if (search) {
            filter += getSearchName(search)[typeOfWasteManager];
        }
        // If this select acts like wasteManager, we need to remove the wasteManager filter. Same with carrier, etc
        delete filterValues[name];
        const addFilter = createFilterWithObject(values, filterValues);
        return (filter += addFilter);
    };

    const isCarrier = (options) => {
        const cleanNullCarriersOptions = options.filter((option) => option.carrierProGesCodLer !== null);
        const carriersWasteManagers = cleanNullCarriersOptions.map(
            (option) => option.carrierProGesCodLer?.procesoGestora?.gestora
        );
        return carriersWasteManagers;
    };

    const isWasteManager = (options) => {
        const wasteManagers = options.map((option) => option.wasteManagerProGesCodLer?.procesoGestora?.gestora);
        return wasteManagers;
    };

    const isTransferOperator = (options) => {
        const cleanNullTransfersOperators = options.filter((option) => option.transferOperatorProGesCodLer !== null);
        const carriersWasteManagers = cleanNullTransfersOperators.map(
            (option) => option.transferOperatorProGesCodLer?.procesoGestora?.gestora
        );
        return carriersWasteManagers;
    };

    const loadOptions = async (search, loadedOptions, { page }) => {
        let filter = getFilter(page, search);
        const ppgcl = await promoProGesCodLersServices.filterPromoProGesCodLers(filter);
        const optionsWithDuplicates = getWasteManagerNames(ppgcl)[[typeOfWasteManager]];

        const currentOptions = removeDuplicates(optionsWithDuplicates, 'id');
        let options = differenceArrays(currentOptions, loadedOptions, 'id');

        if (newActivity && values?.transportista) {
            options = [];
        }
        if (multiselect) {
            options = differenceArrays(options, values[name]);
        }

        const allOptions = [...options, ...loadedOptions];
        if (allOptions?.length === 1) {
            setValues({ ...values, [name]: multiselect ? [options[0]] : options[0] });
        }

        return {
            options,
            hasMore: hasMore(ppgcl),
            additional: {
                page: page + 1
            }
        };
    };

    const loadOptionsWithLinkedWasteManager = async (search, loadedOptions, { page }) => {
        let filter = `?page=${page}&promotionId=${promocion?.id}`;

        if (values?.[codLer] !== null && values?.[codLer] !== '') {
            filter += `&codLer=${values?.[codLer]?.codigoLER}`;
        }

        if (values?.carrier !== null && values?.carrier !== '') {
            filter += `&carrierId=${values?.carrier?.id}`;
        }

        let options = [];
        const ppgcl = (await getPpgclWithLinkedWasteManager(filter)) || null;

        if (ppgcl !== null) {
            const optionsWithDuplicates = getWasteManagerNames(ppgcl?.data)[[typeOfWasteManager]];
            options = removeDuplicates(optionsWithDuplicates, 'id');
        }

        if (options?.length === 1) {
            setValues({ ...values, [name]: options[0] });
        }

        return {
            options,
            hasMore: ppgcl !== null ? hasMore(options) : false,
            additional: {
                page: page + 1
            }
        };
    };

    const loadOptionsCarrierWithLinkedWasteManager = async (search, loadedOptions, { page }) => {
        let filter = `?page=${page}&promotionId=${promocion?.id}`;
        if (values?.[codLer]) {
            filter += `&codLer=${values?.[codLer]?.codigoLER}`;
        }
        if (values[wasteManager]) {
            filter += `&wasteManagerId=${values[wasteManager].id}`;
        }
        let options = [];
        const ppgcl = (await getPpgclWithLinkedWasteManager(filter)) || null;

        if (ppgcl !== null) {
            const optionsWithDuplicates = getWasteManagerNames(ppgcl?.data)[[typeOfWasteManager]];
            options = removeDuplicates(optionsWithDuplicates, 'id');
        }

        if (options?.length === 1) {
            setValues({ ...values, [name]: options[0] });
        }

        return {
            options,
            hasMore: ppgcl !== null ? hasMore(options) : false,
            additional: {
                page: page + 1
            }
        };
    };

    const onChangeMultiselect = async (item) => {
        setValues({ ...values, [name]: item });
    };

    const onChange = async (item) => {
        if (item === null) {
            const valuesAux = { ...values, [name]: INITIAL_STATE };
            resetFields.forEach((field) => (valuesAux[field] = INITIAL_STATE));

            setValues(valuesAux);
        } else {
            const valuesAux = { ...values, [name]: item };
            resetFields.forEach((field) => (valuesAux[field] = INITIAL_STATE));

            setValues(valuesAux);
        }
    };

    const emptyInput = () => {
        if (required && validacion && !value) {
            setNotComplete(true);
        } else {
            setNotComplete(false);
        }
    };

    useEffect(() => {
        emptyInput();
    }, [promocion?.id, validacion, value]);

    const key = () => {
        let valuesAux = { ...values };

        if (preLoadOptions) {
            valuesAux = {};
            requiredFieldsToPreLoadOptions.forEach((field) => {
                valuesAux[field] = values[field];
            });
        }
        return JSON.stringify(valuesAux);
    };

    const optionsMessage = () => {
        if (newActivity && values[carrier]) {
            return 'No puedes seleccionar una gestora con un transportista seleccionado';
        } else if (requestContainerODD && !values[codLer]) {
            return 'Selecciona primero un código LER';
        } else if (requestContainerODD && typeOfWasteManager === 'CARRIER' && !values[wasteManager]) {
            return 'Selecciona primero una gestora';
        }
    };

    const labelName = {
        CARRIER: t('transporter', { ns: 'common' }),
        TRANSFER_OPERATOR: t('transferOperator', { ns: 'common' }),
        WASTE_MANAGER: t('manager.sg', { ns: 'common' })
    };

    const getLoadOptions = () => {
        if (requestContainerODD && typeOfWasteManager === 'WASTE_MANAGER') {
            return loadOptionsWithLinkedWasteManager;
        } else if (requestContainerODD && typeOfWasteManager === 'CARRIER' && !values[wasteManager]) {
            return [];
        } else if (requestContainerODD && typeOfWasteManager === 'CARRIER') {
            return loadOptionsCarrierWithLinkedWasteManager;
        } else {
            return loadOptions;
        }
    };

    const checkIfPreLoadOptions = () => {
        if (values[name] !== undefined && values[name] !== null && values[name] !== '') {
            return false;
        }

        let hasRequiredFields = true;

        for (let i = 0; i < requiredFieldsToPreLoadOptions.length && hasRequiredFields; i++) {
            const field = requiredFieldsToPreLoadOptions[i];

            if (values[field] === undefined || values[field] === null || values[field] === '') {
                hasRequiredFields = false;
                break;
            }
        }
        return hasRequiredFields;
    };

    const modifiers = [disabled ? 'input__autocomplete--disabled' : ''];

    return (
        <div className={`${className ? className : 'input__autocomplete'} ${modifiers.join(' ')}`}>
            <label className="input__label">
                {customLabel ? customLabel : labelName[typeOfWasteManager]} {required ? '*' : ''}
            </label>

            <AsyncPaginate
                key={key()}
                defaultOptions={preLoadOptions ? checkIfPreLoadOptions() : false}
                loadOptions={getLoadOptions()}
                onChange={multiselect ? onChangeMultiselect : onChange}
                getOptionLabel={(option) => option.empresa}
                getOptionValue={(option) => option}
                value={value}
                styles={customStyles(notComplete)}
                placeholder=""
                additional={{
                    page: 0
                }}
                isClearable={true}
                noOptionsMessage={optionsMessage}
                {...DEFAULT_OPTIONS_AUTOCOMPLETE}
                isMulti={multiselect ? true : false}
                isDisabled={disabled}
            />
        </div>
    );
};
export default withAuth(PromoProGesCodLerAutocomplete);
