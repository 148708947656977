import { DomainException } from 'modules/shared';

export class NoContentException extends DomainException {
    constructor() {
        super({
            code: 'no-content',
            description: 'No content found',
            content: null
        });
    }
}

export class InvalidContentException extends DomainException {
    constructor() {
        super({
            code: 'invalid-content',
            description: 'Invalid content found',
            content: null
        });
    }
}
