import { PromotionKPIsProvider, httpPromotionKPIsApi } from 'modules/promotion-kpis';
import KPIs from './KPIs';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
    <PromotionKPIsProvider api={httpPromotionKPIsApi}>
        <KPIs />
    </PromotionKPIsProvider>
);

// For displaying an isolated KPI.
export { default as KPICard } from './components/KPICard';
