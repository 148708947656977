import fetcher from '../../../lib/fetcher';

export const getCo2ByLer = async (promotionId, filter = '') => {
    const URL = promotionId
        ? `/api/impact/avoided/co2-by-ler?promocion.in=${promotionId}&${filter}`
        : `/api/impact/avoided/co2-by-ler?${filter}`;

    try {
        const { data } = await fetcher.get(URL);

        return data.content;
    } catch (error) {
        console.log(error);
    }
};
