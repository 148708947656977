import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const usePreventNavigation = (prevent: boolean) => {
    const history = useHistory();
    const [t] = useTranslation();

    const listener = (event) => {
        // Cancel the event as stated by the standard.
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = '';
    };

    useEffect(() => {
        if (prevent) {
            const unblock = history.block(t('areYouSureYouWantToLeaveThePage'));
            return () => unblock();
        }
    }, [prevent]);

    useEffect(() => {
        if (prevent) window.addEventListener('beforeunload', listener);
        if (!prevent) window.removeEventListener('beforeunload', listener);

        return () => {
            console.log('cleanup');
            window.removeEventListener('beforeunload', listener);
            (window as any).beforeunload = () => {};
        };
    }, [prevent]);
};
