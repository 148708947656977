import { TituloH2 } from '../../../components/BasicComponents/Titulos/Titulos';
import { withAuth } from '../../../context/AuthProvider';
import './ReportPage.scss';

const ReportIndex = () => {
    return (
        <article className="reports__page">
            <div className="reports__index">
                <TituloH2 titulo={'CONTENIDO DEL INFORME'} />
                <ol>
                    <li>Información general</li>
                    <li>
                        Resumen y objetivos
                        <ol>
                            <li>Valorización</li>
                            <li>Residuos producidos</li>
                            <li>Factor de generación de residuos (FGR)</li>
                            <li>Impacto medioambiental CO₂</li>
                        </ol>
                    </li>
                    <li>
                        Métricas de gestión de residuos
                        <ol>
                            <li>Distribución de residuos por tipo de LER (valorizables)</li>
                            <li>Detalle toneladas generadas (por LER)</li>
                            <li>Peso generado por residuo</li>
                        </ol>
                    </li>
                    <li>
                        Tratamiento y valorización
                        <ol>
                            <li>Porcentaje de valorización y de mezcla</li>
                            <li>Cantidad de residuos según su tratamiento</li>
                            <li>Cantidad de residuos por planta gestora</li>
                        </ol>
                    </li>
                    <li>
                        Métricas de impacto medioambiental
                        <ol>
                            <li>Emisiones CO₂</li>
                            <li>Ocupación de suelo</li>
                        </ol>
                    </li>
                </ol>
            </div>
        </article>
    );
};

export default withAuth(ReportIndex);
