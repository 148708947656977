import KPIAlarm from 'features/KPIs/components/KPIAlarm';
import { KPIStatus, MandatoryFlowsDetails } from 'modules/promotion-kpis';
import { Trans, useTranslation } from 'react-i18next';
import WasteManagementAlarm from './components/WasteManagementAlarm';
import { useMandatoryFlowsAlarms } from './hooks/useMandatoryFlowsAlarms';

export type MandatoryFlowAlarmsProps = {
    mandatoryFlows?: MandatoryFlowsDetails | null;
    promotionId: string;
};

const MandatoryFlowAlarms = (props: MandatoryFlowAlarmsProps) => {
    const [t] = useTranslation('kpis');
    const alarms = useMandatoryFlowsAlarms(props);

    if (!props.mandatoryFlows) return null;

    const links = {
        goals: `/promotion/${props.promotionId}/settings/kpi-goals/mandatory-flows`,
        traceabilities: `/promotion/${props.promotionId}/traceabilities`
    };

    return (
        <>
            <div className="MandatoryFlowAlarms">
                {/* Critical type count alarms */}
                {alarms.count.Critical.length > 0 && (
                    <KPIAlarm
                        status={KPIStatus.DANGER}
                        link={{ text: t(`kpis:mandatory-flows.alarm.danger.count.link`), to: links.traceabilities }}
                        text={
                            <Trans
                                i18nKey={`kpis:mandatory-flows.alarm.danger.count.text`}
                                values={{ flows: alarms.count.Critical.map((flows) => flows.name).join(', ') }}
                                components={{ b: <strong /> }}
                            />
                        }
                    />
                )}

                {/* Critical type egr and pgr alarms */}
                <WasteManagementAlarm
                    status={KPIStatus.DANGER}
                    alarms={alarms.egrAndPgr.Critical}
                    link={links.traceabilities}
                />

                {/* Warning type count alarms */}
                {alarms.count.Warning.length > 0 && (
                    <KPIAlarm
                        status={KPIStatus.WARNING}
                        link={{ text: t(`kpis:mandatory-flows.alarm.warning.count.link`), to: links.traceabilities }}
                        text={
                            <Trans
                                i18nKey={`kpis:mandatory-flows.alarm.warning.count.text`}
                                values={{ flows: alarms.count.Warning.map((flows) => flows.name).join(', ') }}
                                components={{ b: <strong /> }}
                            />
                        }
                    />
                )}

                {/* Critical type egr and pgr alarms */}
                <WasteManagementAlarm
                    status={KPIStatus.WARNING}
                    alarms={alarms.egrAndPgr.Warning}
                    link={links.traceabilities}
                />

                {/* Not applicable */}
                {alarms.notApplicable && (
                    <KPIAlarm
                        status={KPIStatus.NOT_APPLICABLE}
                        text={t(`mandatory-flows.alarm.not_applicable.text`)}
                        link={{ text: t(`mandatory-flows.alarm.not_applicable.link`), to: links.goals }}
                    />
                )}

                {/* No goals set */}
                {props.mandatoryFlows.lerTypes.length === 0 && (
                    <KPIAlarm
                        status={KPIStatus.INDETERMINATE}
                        text={t(`mandatory-flows.alarm.indeterminate.text`)}
                        link={{ text: t(`mandatory-flows.alarm.indeterminate.link`), to: links.goals }}
                    />
                )}
            </div>
        </>
    );
};

export default MandatoryFlowAlarms;
