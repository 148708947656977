import { IconBox, IconBuildingFactory, IconFileDownload, IconTruck } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import Di from 'components/ComplexComponents/Di';
import GenericDataField from 'features/traceabilities/components/TraceabilitiesTable/table/GenericDataField';
import { wasteRegisterToTraceabilityAdapter } from 'features/waste-register/components/WasteRegisterDI/adapter/wasteRegisterToTraceabilityAdapter';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { Traceability } from 'modules/traceabilities';
import { WasteRegister } from 'modules/waste-register/domain/WasteRegister';
import { useWasteRegisterStore } from 'modules/waste-register/infrastructure/react/wasteRegisterContext';
import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { convertToYYYYMMDDFormat } from 'utils/helpers/general.helpers';
import './WasteRegisterListItem.scss';

type WasteRegisterListItemProps = {
    wasteRegister?: WasteRegister;
    onClickEdit?: (wasteRegister: WasteRegister) => void;
};

const WasteRegisterListItem = (props: WasteRegisterListItemProps) => {
    const { wasteRegister } = props;
    const [t] = useTranslation('wasteRegister');
    const { format } = useDateFormatter();
    const [{ updating }, , { api }] = useWasteRegisterStore();
    const [diData, setDiData] = useState<Traceability | undefined>(undefined);
    const generatedDiRef = useRef<any>(null);

    if (!wasteRegister) return <></>;

    // eslint-disable-next-line
    const print = useReactToPrint({
        content: () => generatedDiRef.current as any,
        documentTitle: `${convertToYYYYMMDDFormat(diData?.date)}_DI_${diData?.ler.code}_${diData?.di.num}`
    });

    const loadAndPrintDi = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        const wasteRegisterDiData = await api.findById({ uuid: wasteRegister.uuid });
        if (!wasteRegisterDiData) return;

        setDiData(wasteRegisterToTraceabilityAdapter(wasteRegisterDiData));

        print();
    };

    const isUpdating = wasteRegister && !!updating[wasteRegister.uuid];

    const modifiers = [
        !wasteRegister ? 'WasteRegisterListItem--skeleton' : '',
        isUpdating ? 'WasteRegisterListItem--updating' : ''
    ];

    return (
        <div
            className={`WasteRegisterListItem ${modifiers.join(' ')}`}
            onClick={() => wasteRegister && props.onClickEdit?.(wasteRegister)}
            role={wasteRegister ? 'listitem' : 'presentation'}
        >
            <div className="WasteRegisterListItem__data --meta">
                <strong>{format(wasteRegister?.movementDate, 'DD/MM/YY')}</strong>
                <p>{wasteRegister?.updatedBy}</p>
            </div>

            <div className="WasteRegisterListItem__data --ler">
                <strong>{wasteRegister?.flow.ler.code}</strong>
                <p>{wasteRegister?.flow.ler.type}</p>
            </div>

            <div className="WasteRegisterListItem__data --manager">
                <GenericDataField
                    text={wasteRegister?.flow.manager.name || ''}
                    icon={<IconBuildingFactory />}
                    title={wasteRegister?.flow.manager.name}
                />
            </div>

            <div className="WasteRegisterListItem__data --carrier">
                <GenericDataField
                    text={wasteRegister?.flow.carrier?.name || ''}
                    icon={<IconTruck />}
                    title={wasteRegister?.flow.carrier?.name || ''}
                />
            </div>

            <div className="WasteRegisterListItem__data --volumen">
                <GenericDataField unit="m³" icon={<IconBox />} text={wasteRegister?.volume?.toString() || ''} />
            </div>

            {/* Only for "DISPOSAL" */}
            {/* {wasteRegister && (
                <div className="WasteRegisterListItem__data --status --READED">
                    <IconChecks size={16} /> <span>Leído</span>
                </div>
            )} */}

            {/* <div className="WasteRegisterListItem__data --status"></div> */}
            {wasteRegister && (
                <div className="WasteRegisterListItem__data --actions">
                    <button className="WasteRegisterListItem__preDi" onClick={(e) => loadAndPrintDi(e)}>
                        <IconFileDownload size={18} />
                        {t('list.actions.preDi.label')}
                    </button>
                    <div style={{ display: 'none' }}>{diData && <Di referent={generatedDiRef} diData={diData} />}</div>
                    {/**
                     * For future use actions like delete, etc
                     */}

                    {/* <DropdownList
                        items={[
                            <Link to={`${url}/${wasteRegister.uuid}/di`}>
                                <IconFileDownload size={18} />
                                {t('list.actions.preDi.label')}
                            </Link>,
                            <button>
                                <IconFileDownload size={18} />
                                {t('list.actions.preDi.label')}
                            </button>
                        ]}
                    >
                        <button>
                            <IconDotsVertical size={16} />
                        </button>
                    </DropdownList> */}
                </div>
            )}

            {wasteRegister?.observations && (
                <div className="WasteRegisterListItem__data --observations">{wasteRegister.observations}</div>
            )}
        </div>
    );
};

export default WasteRegisterListItem;
