import ReportPage from '../components/ReportPage';

export const REPORTS_ROUTES = [
    {
        component: ReportPage,
        /** Atm the "Promotionid" parameter does nothing, but we establish the routes based on the future refactor */
        path: '/promotion/:promotionId/reports',
        requiredPermission: 'canView',
        /** Same permissions as dashboard */
        section: 'dashboard'
    }
];
