import Headline from 'components/BasicComponents/Headline/Headline';
import KPIs from 'features/KPIs';
import Alerts from 'features/alerts';
import './PromotionDetail.scss';
import ValorizaBadge from './sections/ValorizaBadge/ValorizaBadge';
import WasteByValorization from './sections/WasteByValorization';
import WasteSummary from './sections/WasteSummary';
import PromotionInfo from './sections/PromotionInfo/PromotionInfo';

const PromotionDetail = () => {
    return (
        <div className="PromotionDetail">
            <section className="PromotionDetail__promotionInfo">
                <PromotionInfo />
            </section>

            <section className="PromotionDetail__kpis">
                <Headline className="PromotionDetail__headline">Objetivos del proyecto</Headline>
                <KPIs />
            </section>

            <section className="PromotionDetail__waste">
                <Headline className="PromotionDetail__headline">Desglose de residuos</Headline>
                <WasteSummary />
                <WasteByValorization />
            </section>

            <aside className="PromotionDetail__aside">
                <ValorizaBadge />
                <Alerts />
            </aside>
        </div>
    );
};

export default PromotionDetail;
