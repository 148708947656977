import { ADMIN_ROUTES } from 'features/admin';
import { ASSOCIATE_TRACEABILITIES_ROUTE } from 'features/associate_documents_traceabilities/routes/AssociateTracabilitiesRoute';
import { GLOBAL_DASHBOARD_ROUTES } from 'features/dashboard/routes/DashboardRouter';
import { DOCUMENTATION_ROUTES } from 'features/documentation/routes/DocumentationRoutes';
import { IMPACT_ROUTES } from 'features/impact/routes/ImpactRouters';
import { NEW_EDIT_TRACEABILITY_ROUTE } from 'features/new_edit_traceability/routes/NewEditTraceabilityRoute';
import { PROMOTION_ROUTES } from 'features/promotion/routes/PromotionRouter';
import { REPORTS_ROUTES } from 'features/reports/routes/ReportRouter';
import { REUSE_ROUTES } from 'features/reuse/routes/ReuseRouter';
import { TRACEABILITY_E2E_ROUTES } from 'features/traceability_e2e/routes/TraceabilityE2ERoutes';

import { HELP_CENTER_ROUTES } from 'features/help-center/routes/HelpCenterRoutes';
import { HOME_ROUTES } from 'features/home/routes/HomeRoutes';
import { COSTS_ROUTES } from './CostsRouter';
import { OTHERS_ROUTES } from './OthersRouter';
import { PROFILE_ROUTES } from './ProfileRouter';
import { SETTINGS_ROUTES } from './SettingsRouter';
import { TRACEABILITIES_ROUTES } from './TraceabilitiesRouter';
import { WASTE_MANAGER_ROUTES } from './WasteManagerRouter';

export let PRIVATE_ROUTES = [];

PRIVATE_ROUTES = ([] as any).concat(
    IMPACT_ROUTES,
    ASSOCIATE_TRACEABILITIES_ROUTE,
    DOCUMENTATION_ROUTES,
    COSTS_ROUTES,
    TRACEABILITIES_ROUTES,
    NEW_EDIT_TRACEABILITY_ROUTE,
    OTHERS_ROUTES,
    PROFILE_ROUTES,
    SETTINGS_ROUTES,
    GLOBAL_DASHBOARD_ROUTES,
    REPORTS_ROUTES,
    WASTE_MANAGER_ROUTES,
    REUSE_ROUTES,
    TRACEABILITY_E2E_ROUTES,
    PROMOTION_ROUTES,
    ADMIN_ROUTES,
    HOME_ROUTES,
    HELP_CENTER_ROUTES
);
