import { useTranslation } from 'react-i18next';
import Toast from '../components/BasicComponents/Messages/Toast';
import { withAuth } from '../context/AuthProvider';
import { notify } from '../utils/helpers/notifications';

let toggleModal = () => {};
const ToastHandler = () => {
    const [t] = useTranslation();

    const createText = (text) => {
        if (typeof text === 'string') {
            return text;
        } else {
            return t(text.text, { ns: text.ns });
        }
    };

    toggleModal = (text, isSuccess) => {
        notify({ isSuccess, msg: createText(text) });
    };

    return <Toast />;
};
export { toggleModal };
export default withAuth(ToastHandler);
