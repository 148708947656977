import * as Sentry from '@sentry/react';
import { Logger } from 'modules/shared/domain/Logger';

export const logger: Logger = {
    async send(error: Error, extra = {}) {
        console.error(error);
        Sentry.captureException(error, {
            level: extra.level || 'error',
            user: extra.user,
            extra: {
                error_stack: error.stack,
                error_cause: error.cause,
                error_message: error.message,
                ...extra
            }
        });
    }
};
