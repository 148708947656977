import { Trans, useTranslation } from 'react-i18next';

import CreateButton from 'components/BasicComponents/Buttons/CreateButton';
import './Support.scss';
import { IconCalendar } from '@tabler/icons';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';

const Support = () => {
    const [t] = useTranslation('helpCenter');
    const [, analytics] = useAnalyticsStore();

    const email = 'product@cocircular.es';
    const calendar = 'https://calendar.app.google/zUQF3eQkV5m2qyD39';

    return (
        <div className="Support">
            <h2 className="Support__title">
                <Trans i18nKey="support.title" ns="helpCenter" />
            </h2>
            <p className="Support__subtitle">
                <Trans
                    i18nKey="support.subtitle"
                    ns="helpCenter"
                    values={{ email }}
                    components={[
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                            onClick={() => analytics.event(AnalyticsEvents.SUPPORT_EMAIL_CLICKED)}
                            className="link"
                            href={`mailto:${email}`}
                        />,
                        <p />
                    ]}
                />
            </p>
            <CreateButton
                action={() => analytics.event(AnalyticsEvents.SUPPORT_CALENDAR_CLICKED)}
                extraClasses="Support__btn"
                icon={<IconCalendar size={24} stroke={1.5} />}
                text={t('support.button')}
                type="external-link"
                to={calendar as any}
            />
        </div>
    );
};

export default Support;
