import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReactComponent as Gestionadas } from '../../assets/iconos/valorizadas/gestionadasgradient.svg';
import { ReactComponent as Valorizadas } from '../../assets/iconos/valorizadas/valorizadas.svg';
import HasPermission from '../../components/AccessCheckers/HasPermission';
import Buscador from '../../components/BasicComponents/Buscador/Buscador';
import CreateButton from '../../components/BasicComponents/Buttons/CreateButton';
import SearchButton from '../../components/BasicComponents/Buttons/SearchButton';
import SimpleCard from '../../components/BasicComponents/Cards/SimpleCard';
import InputNumber from '../../components/BasicComponents/Inputs/InputNumber';
import ProjectSelector from '../../components/BasicComponents/Searcher/ProjectSelector';
import EnterpiseAutocomplete from '../../components/BasicComponents/Select-Autocomplete/EnterpiseAutocomplete';
import PromotionAutocomplete from '../../components/BasicComponents/Select-Autocomplete/PromotionAutocomplete';
import PromotionStatus from '../../components/BasicComponents/Select-Autocomplete/PromotionStatus';
import ProvinceAutocomplete from '../../components/BasicComponents/Select-Autocomplete/ProvinceAutocomplete';
import { TituloH3 } from '../../components/BasicComponents/Titulos/Titulos';
import BarrasPromocion from '../../components/ComplexComponents/Promotion/BarrasPromocion';
import PromotionsDataByUser from '../../components/ComplexComponents/Promotion/PromotionsDataByUser';
import { withAuth } from '../../context/AuthProvider';
import TypeWaste from '../../features/impact/components/TypeWaste';
import { useAllPromotions } from '../../hooks/useAllPromotions';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { useUserPromotions } from '../../hooks/useUserPromotions';
import { currencyFormatDE } from '../../utils/helpers/general.helpers';
import { checkIfCoCircularOrAudit } from '../../utils/helpers/user.helpers';
import './Home.scss';

const Home = (props) => {
    const [t] = useTranslation();
    const [, analytics] = useAnalyticsStore();
    const [values, setValues] = useState({
        id: '',
        num: '',
        enterprise: '',
        promotion: '',
        idsPromotions: '',
        province: '',
        promotionStatus: ''
    });
    const [dataGraphic, setDataGraphic] = useState([]);
    const [metrics, setMetrics] = useState([]);
    const [projectsOnFilter, setProjectsOnFilter] = useState([]);
    const [projectsOutOfFilter, setProjectsOutOfFilter] = useState([]);
    const [loading, setLoading] = useState(false);
    const { page, samePage, isScrolling, resetPage } = useInfiniteScroll();
    const { allPromotions, setAllPromotions } = useAllPromotions(values, page, samePage);
    const [loadingUserPromotion, userPromotions] = useUserPromotions(props.user?.id, props.isCoCircular);
    const handleInputChange = ({ target }) => {
        setValues({
            ...values,
            [target.name]: target.value
        });
    };

    const handleFormSubmit = async (e) => {
        analytics.event(AnalyticsEvents.HOME_ANALYSIS_SEARCH);
        e.preventDefault();
        resetPage();
    };

    const handleLoadMore = () => {
        isScrolling();
    };

    const { isCoCircular } = props;
    return (
        <>
            <div className="home__headerButton">
                <HasPermission ifRole="canCreate" section="newPromotionRequest">
                    <CreateButton text="Solicitud de nuevo proyecto" to="/settings/new-promotion-request" />
                </HasPermission>
            </div>
            <Buscador titulo={t('legacy.search', { ns: 'home' })}>
                <form onSubmit={handleFormSubmit}>
                    {checkIfCoCircularOrAudit(props?.user) && (
                        <>
                            <InputNumber
                                label={t('legacy.id', { ns: 'home' })}
                                value={values.id}
                                {...{ setValues }}
                                name="id"
                                onChange={handleInputChange}
                            />
                            <InputNumber
                                label={t('legacy.number', { ns: 'home' })}
                                value={values.num}
                                {...{ setValues }}
                                name="num"
                                onChange={handleInputChange}
                            />
                        </>
                    )}
                    {isCoCircular && (
                        <>
                            <EnterpiseAutocomplete
                                {...{ values, setValues }}
                                isClearable={true}
                                propName="enterprise"
                                promotion={values.promotion}
                            />
                            <PromotionAutocomplete
                                values={values}
                                setValues={setValues}
                                isClearable={true}
                                propName="promotion"
                                enterprise={values.enterprise}
                                num={values.num}
                            />
                        </>
                    )}
                    <PromotionStatus {...{ values, setValues }} isClearable={true} />
                    <ProvinceAutocomplete {...{ values, setValues }} isClearable={true} />
                    <SearchButton text={t('search.search', { ns: 'common' })} />
                </form>
            </Buscador>
            {allPromotions?.length > 0 ? (
                <>
                    <div className="home__promotions" id="home__promotions">
                        <InfiniteScroll
                            dataLength={allPromotions.length}
                            next={() => handleLoadMore()}
                            hasMore={allPromotions.length % 20 === 0 ? true : false}
                            scrollableTarget="main-layout"
                        >
                            <div className="home__promotions__list">
                                {allPromotions.map((promotion) => (
                                    <PromotionsDataByUser key={promotion.id} {...{ promotion }} />
                                ))}
                            </div>
                        </InfiniteScroll>
                    </div>
                    <HasPermission ifRole="canView" section="traceability">
                        {!isCoCircular && !loadingUserPromotion && (
                            <SimpleCard className="home__metricsCard">
                                <ProjectSelector
                                    {...{
                                        metrics,
                                        setMetrics,
                                        setDataGraphic,
                                        loading,
                                        setLoading,
                                        projectsOnFilter,
                                        setProjectsOnFilter,
                                        projectsOutOfFilter,
                                        setProjectsOutOfFilter
                                    }}
                                    projects={userPromotions}
                                    projectsFiltered={allPromotions}
                                    setProjectsFiltered={setAllPromotions}
                                />
                                {projectsOnFilter.length > 0 ? (
                                    <div className="home__metricsContainer">
                                        <div className="home__metrics">
                                            {metrics.map((option) => (
                                                <div key={option?.title} className="home__metric home__metricContainer">
                                                    <TituloH3
                                                        titulo={
                                                            <span className="home__typeWaste">
                                                                <TypeWaste type={option?.title?.substring(6)} />
                                                            </span>
                                                        }
                                                    />
                                                    {option?.percentRecovered !== null && (
                                                        <div className="home__metric">
                                                            <Valorizadas />
                                                            <p>
                                                                {currencyFormatDE(option?.percentRecovered)} %{' '}
                                                                <span>{t('legacy.waste.valued', { ns: 'home' })}</span>
                                                            </p>
                                                        </div>
                                                    )}
                                                    <div className="home__metric">
                                                        <Gestionadas />
                                                        <p>
                                                            {currencyFormatDE(option?.totalWeight)} tn{' '}
                                                            <span>{t('legacy.waste.managed', { ns: 'home' })}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <BarrasPromocion graphicsData={dataGraphic} className="home__graphic" />
                                    </div>
                                ) : (
                                    <p className="home__metricsContainer">
                                        Selecciona mínimo una promoción para poder visualizar las métricas
                                    </p>
                                )}
                            </SimpleCard>
                        )}
                    </HasPermission>
                </>
            ) : (
                <p>{t('legacy.notFound', { ns: 'home' })}</p>
            )}
        </>
    );
};
export default withAuth(Home);
