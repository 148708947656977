import { IconCheck } from '@tabler/icons';
import { useEffect } from 'react';
import axios from 'axios';

import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { withAuth } from '../../../context/AuthProvider';
import { truncateString } from '../../../utils/helpers/text.helpers';
import { getDataGraphic, getRecovery } from '../../../utils/helpers/valorizationTones.helpers';
import Spinner from '../Spinner/Spinner';
import './ProjectSelector.scss';

const ProjectSelector = ({
    projectsFiltered,
    setMetrics,
    setDataGraphic,
    loading,
    setLoading,
    projectsOnFilter,
    setProjectsOnFilter,
    projectsOutOfFilter,
    setProjectsOutOfFilter,
    ...rest
}) => {
    const [, analytics] = useAnalyticsStore();
    const { isCoCircular } = rest;

    const quitProject = (project) => {
        setProjectsOnFilter(projectsOnFilter.filter((proj) => proj.id !== project.id));
        setProjectsOutOfFilter([...projectsOutOfFilter, project]);
        analytics.event(AnalyticsEvents.HOME_ANALYSIS_SUMMARY_FILTER_REMOVE_PROMOTION);
    };

    const addProject = (project) => {
        setProjectsOutOfFilter(projectsOutOfFilter.filter((promo) => promo.id !== project.id));
        setProjectsOnFilter([...projectsOnFilter, project]);
        analytics.event(AnalyticsEvents.HOME_ANALYSIS_SUMMARY_FILTER_ADD_PROMOTION);
    };

    const getMetricsData = async (source) => {
        setLoading(true);
        const promotionsIds = projectsOnFilter.map((project) => project.id);
        setDataGraphic(await getDataGraphic(promotionsIds.length === 0 ? '' : promotionsIds, true, true, { source }));
        setMetrics(await getRecovery(promotionsIds.length === 0 ? '' : promotionsIds, { source }));
        setLoading(false);
    };

    useEffect(() => {
        setProjectsOnFilter(projectsFiltered);
    }, [projectsFiltered]);

    useEffect(() => {
        const cancelToken = axios.CancelToken;
        const source = cancelToken.source();
        if (!isCoCircular) {
            getMetricsData(source);
        }
        return () => source.cancel('axios request cancelled');
    }, [projectsOnFilter]);

    return (
        <div className="projectSelector__container">
            <div className="projectSelector__tagsContainer">
                {projectsOnFilter.map((project) => (
                    <button key={project.id} className="projectSelector__item" onClick={() => quitProject(project)}>
                        <IconCheck />
                        <p>{truncateString(project?.nombre, 40)}</p>
                    </button>
                ))}
            </div>
            {projectsOutOfFilter.length > 0 && (
                <>
                    <div className="projectSelector__tagsContainer">
                        {projectsOutOfFilter.map((project) => (
                            <button
                                key={project.id}
                                className="projectSelector__item projectSelector__item--disabled"
                                onClick={() => addProject(project)}
                            >
                                <p>{truncateString(project?.nombre, 40)}</p>
                            </button>
                        ))}
                    </div>
                </>
            )}
            {loading && <Spinner />}
        </div>
    );
};

export default withAuth(ProjectSelector);
