import { Reducers, createStore } from 'modules/shared/domain/store/createStore';

import { E2EPermissionsStore } from '../domain/E2EPermissionsStore';
import { loadE2EPermissions } from './load/loadE2EPermissions';

const reducers: Reducers<E2EPermissionsStore> = {
    loadE2EPermissionsPending(state, payload) {
        return { ...state, loading: 'pending' };
    },
    loadE2EPermissionsFulfilled(state, payload) {
        return { ...state, loading: 'succeeded', permissions: payload.permissions };
    },
    loadE2EPermissionsRejected(state, payload) {
        return { ...state, loading: 'failed', error: payload.error };
    }
};

export const e2ePermissionsStore = createStore({
    reducers,
    initialState: {
        permissions: null,
        loading: 'idle',
        error: null
    },
    thunks: {
        loadE2EPermissions
    }
});
