import { useState, useEffect, createRef } from 'react';
import NumberFormat from 'react-number-format';
import { currencyFormatDE } from '../../../utils/helpers/general.helpers';

const InputNumber = ({
    label = '',
    id = '',
    name,
    value,
    onChange,
    min = '',
    max = '',
    inputContainer = '',
    className,
    validacion = false,
    formatNumber = true,
    placeholder = false,
    placeholderText = '',
    readOnly = false,
    required = false,
    maximumFractionDigits = 2,
    disabled = false,
    htmlRequired = false,
    hidden = false,
    showThousandSeparator = true,
    extraElement,
    align = 'left',
    style = {},
    errorMsg = '',
    onBlur = undefined
}) => {
    const ref = createRef();
    const [notComplete, setNotComplete] = useState(false);

    const inputVacio = () => {
        if (validacion === true && required) {
            if (
                value === undefined ||
                value === null ||
                (typeof value === 'string' && value.trim().length === 0) ||
                (min !== '' && value < Number(min))
            ) {
                setNotComplete(true);
            } else {
                setNotComplete(false);
            }
        } else {
            setNotComplete(false);
        }
    };

    const classInput = () => {
        let inputClass = 'input__input';
        if (className) {
            inputClass += ` ${className}`;
        }
        if (disabled) {
            inputClass += ` input__input--disabled`;
        }

        inputClass += ` input__input--align-${align}`;
        return inputClass;
    };

    useEffect(() => {
        inputVacio();
    }, [notComplete, validacion, value]);

    useEffect(() => {
        // because NumberFormat is a wrapper, we need to access the input element to set the custom validity
        const input = ref.current.querySelector('input');
        if (!input) return;

        if (errorMsg) {
            input.setCustomValidity(errorMsg);
            input.reportValidity();
        } else input.setCustomValidity('');
    }, [errorMsg]);

    return (
        <div className={inputContainer ? `${inputContainer} input__container` : `input__container`} style={style}>
            <label className="input__label" htmlFor={id}>
                {label} {required ? '*' : ''}
            </label>

            <div className={`input__wrapper ${notComplete ? 'input__input--notComplete' : ''}`} ref={ref}>
                {formatNumber ? (
                    <NumberFormat
                        id={id}
                        aria-label={label}
                        name={name}
                        value={value && currencyFormatDE(value, maximumFractionDigits)}
                        className={classInput()}
                        step="any"
                        min={min}
                        max={max}
                        {...(showThousandSeparator ? { thousandSeparator: '.' } : {})}
                        decimalSeparator={','}
                        onBlur={onBlur}
                        decimalScale={6}
                        hidden={hidden}
                        placeholder={(placeholder && value) || placeholderText}
                        onValueChange={(values) => {
                            const { value } = values;
                            const obj = {
                                target: {
                                    name: name,
                                    value: value
                                }
                            };

                            onChange(obj);
                        }}
                        disabled={disabled}
                        required={htmlRequired}
                    />
                ) : (
                    <input
                        aria-label={label}
                        id={id}
                        type="number"
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        className={classInput()}
                        step="any"
                        min={min}
                        max={max}
                        readOnly={readOnly}
                        required={htmlRequired}
                    />
                )}
                {extraElement && extraElement}
            </div>
        </div>
    );
};
export default InputNumber;
