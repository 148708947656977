import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import { Traceability } from 'modules/traceabilities';
import { getValorizationType } from 'features/impact/helpers/valorization.helpers';
import { COLUMNS } from './constants/columns';

interface ValorizationInfoProps {
    traceability: Traceability;
}

const ValorizationInfo = ({ traceability }: ValorizationInfoProps) => {
    const [t] = useTranslation();
    const columnData = COLUMNS.find((column) => column.name === 'valorizationInfo') || null;
    const [valorizationType, setValorizationType] = useState('');

    useEffect(() => {
        calculateValorizationType();
    }, []);

    if (!columnData) return null;

    const calculateValorizationType = () => {
        // TODO: valorization type needs... typescript!
        const valorizationTypeAux = getValorizationType({
            valorizationCode: traceability.process.valorizationCode,
            isFinalValorizator: false
        });
        setValorizationType(valorizationTypeAux);
    };

    const getValorizationLabel = () => {
        if (valorizationType === 'reuse') return t(`valorization.reuse`, { ns: 'traceabilities' });

        /* TODO: Unknown valorization percent should change to "null" */
        if (traceability.valorizationPercent === null) return `${traceability.process.valorizationCode} - % descon.`;

        return `${traceability.process.valorizationCode} - ${traceability.valorizationPercent}%`;
    };

    const getValorizationClassname = () => {
        if (valorizationType === '') return '';

        let className = 'good';
        if (valorizationType === 'energy' || valorizationType === 'stored') className = 'average';
        if (valorizationType === 'dumping') className = 'bad';

        return `TraceabilitiesTable__valorizationInfo-${className}`;
    };

    return (
        <td className="TraceabilitiesTable__valorizationInfoWrapper">
            <Tooltip
                msg={valorizationType !== '' ? t(`valorization.${valorizationType}`, { ns: 'traceabilities' }) : ''}
            >
                <span
                    data-testid="Traceabilty__valorizationInfo"
                    className={`TraceabilitiesTable__valorizationInfo ${getValorizationClassname()}`}
                >
                    {getValorizationLabel()}
                </span>
            </Tooltip>
        </td>
    );
};

export default ValorizationInfo;
