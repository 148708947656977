import fetcher from 'lib/fetcher';

/** @refactor Coupled, move to module api */
export async function findWasteManagers(criteria: WasteManagerCriteria): Promise<WasteManager[]> {
    const { data } = await fetcher.get(`/api/waste-managers/enterprise/${criteria.enterpriseId}`, {
        params: { size: 999, page: 0 }
    });

    const wasteManagers = (data.content as WasteManager[])
        .map((wm) => ({ id: wm.id, name: wm.name, nima: wm.nima }))
        // Move search to backend
        .filter((wm) => {
            if (!criteria.search) return true;
            return wm.name.toLowerCase().includes(criteria.search.toLowerCase());
        });

    return wasteManagers;
}

interface WasteManagerCriteria {
    enterpriseId: number;
    search?: string;
    page?: number;
}

export interface WasteManager {
    id: number;
    name: string;
    nima?: string;
}
