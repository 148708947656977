import { Thunk } from 'modules/shared/domain/store/createStore';
import { TraceabilitiesCriteria } from 'modules/traceabilities/domain/TraceabilitiesCriteria';
import { Traceability } from 'modules/traceabilities/domain/Traceability/Traceability';

import { TraceabilitiesStore } from '../../domain/TraceabilitiesStore';

export const loadOnePendingTraceability =
    (newCriteria: TraceabilitiesCriteria): Thunk<TraceabilitiesStore, { traceability: Traceability | null }> =>
    async (store, { api, logger }) => {
        try {
            const { traceabilities } = await api.getTraceabilities(newCriteria);
            return { traceability: traceabilities.length > 0 ? traceabilities[0] : null };
        } catch (e) {
            const error = e as Error;
            logger.send(error);
            return { traceability: null };
        }
    };
