import { Traceability } from 'modules/traceabilities';
import { WasteRegisterDi } from 'modules/waste-register/domain/WasteRegisterDi';

export function wasteRegisterToTraceabilityAdapter(wasteRegisterDi: WasteRegisterDi): Traceability {
    return {
        id: parseInt(wasteRegisterDi.uuid, 16),
        di: {
            num: wasteRegisterDi.diNum,
            url: null,
            hasDi: true
        },
        process: {
            valorizationCode: wasteRegisterDi.flow.treatment.code,
            description: wasteRegisterDi.flow.treatment.description
        },
        status: 'PENDING',
        ler: {
            id: wasteRegisterDi.flow.ler.code,
            code: wasteRegisterDi.flow.ler.code,
            desc: wasteRegisterDi.flow.ler.desc,
            kind: wasteRegisterDi.flow.ler.type,
            typeId: 0
        },
        valorizationPercent: wasteRegisterDi.flow.valuationPercentage,
        weight: 0,
        volume: wasteRegisterDi.volume,
        cost: wasteRegisterDi.cost,
        observations: wasteRegisterDi.observations || '',
        date: wasteRegisterDi.movementDate,
        creationDate: wasteRegisterDi.createdAt,
        createdBy: wasteRegisterDi.createdBy,
        updateDate: wasteRegisterDi.updatedAt,
        updatedBy: wasteRegisterDi.updatedBy,
        wasteManager: {
            id: wasteRegisterDi.flow.manager.id.toString(),
            name: wasteRegisterDi.flow.manager.name,
            nif: wasteRegisterDi.flow.manager.nif,
            nima: wasteRegisterDi.flow.manager?.nima?.toString() || '',
            addresses: [wasteRegisterDi.flow.manager.address],
            phone: 0,
            email: ''
        },
        carrier: {
            id: wasteRegisterDi.flow.carrier?.id.toString() || '',
            name: wasteRegisterDi.flow.carrier?.name || '',
            nif: wasteRegisterDi.flow.carrier?.nif || '',
            nima: wasteRegisterDi.flow.carrier?.nima?.toString() || '',
            addresses: wasteRegisterDi.flow.carrier?.address ? [wasteRegisterDi.flow.carrier?.address] : [],
            phone: 0,
            email: ''
        },
        transferOperator: {
            id: wasteRegisterDi.flow.operator.id.toString(),
            name: wasteRegisterDi.flow.operator.name,
            nif: wasteRegisterDi.flow.operator.nif,
            nima: wasteRegisterDi.flow.operator.nima?.toString() || '',
            addresses: wasteRegisterDi.flow.operator.address ? [wasteRegisterDi.flow.operator.address] : [],
            phone: 0,
            email: ''
        },
        project: {
            id: wasteRegisterDi.owner.id,
            enterpriseName: wasteRegisterDi.owner.name,
            enterpriseCif: wasteRegisterDi.owner.nif,
            name: wasteRegisterDi.owner.name,
            nima: wasteRegisterDi.owner.nima?.toString() || '',
            address: wasteRegisterDi.owner.address
        },
        e2e: false,
        canDeny: false,
        canCreate: false,
        canEdit: false,
        canDelete: false,
        canCancel: false
    };
}
