import fetcher from '../../../../lib/fetcher';

export const getEgrLerTypes = async (promotionId) => {
    const URL = `/api/tracing/egr?promotionId=${promotionId}`;
    try {
        const response = await fetcher.get(URL);
        return response;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
