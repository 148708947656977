import MessageWasteManagerPage from '../components/MessageWasteManagerPage';
import RequestTraceabilityE2EPage from '../components/RequestTraceabilityE2EPage';

export const TRACEABILITY_E2E_ROUTES = [
    {
        component: MessageWasteManagerPage,
        path: '/waste-manager-traceability',
        requiredPermission: 'canView',
        section: 'wasteManagerTraceability'
    },
    {
        component: RequestTraceabilityE2EPage,
        path: '/traceabilities/request-di',
        requiredPermission: 'canView',
        section: 'traceability'
    }
];
