import { IconFileAlert, IconFileDownload } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import { Traceability, useTraceabilities } from 'modules/traceabilities';

import { COLUMNS } from './constants/columns';

interface DiColumnProps {
    traceability: Traceability;
}

const DiColumn = ({ traceability }: DiColumnProps) => {
    const [, , { api }] = useTraceabilities();

    const [t] = useTranslation();
    const columnData = COLUMNS.find((column) => column.name === 'di') || null;

    if (!columnData) return null;

    const openDi = async (e) => {
        e.stopPropagation();
        e.preventDefault();

        const { url } = await api.getDiByTraceabilityId({ id: traceability.id.toString() });
        if (!url) return;
        window.open(url);
    };

    const hasFileDi = traceability.di.hasDi;
    const btnText = hasFileDi ? 'withDi' : 'withoutDi';

    return (
        <td className={`TraceabilitiesTable__di`}>
            <button className="TraceabilitiesTable__button" onClick={(e) => openDi(e)} disabled={!hasFileDi}>
                {hasFileDi ? <IconFileDownload size={18} /> : <IconFileAlert size={18} />}
                {t(btnText, { ns: 'traceabilities' })}
            </button>
        </td>
    );
};

export default DiColumn;
