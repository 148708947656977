// eslint-disable-next-line import/named
import { CartesianMarkerProps } from '@nivo/core';
import { KPIStatus } from 'modules/promotion-kpis';

const labelStyles = {
    fill: '#273749',
    fontSize: 10,
    fontFamily: 'Be Vietnam Pro'
};

const colors = {
    success: '#6ECEC1',
    danger: '#e84420',
    warning: '#ffbf43',
    indeterminate: '#671FF3',
    not_applicable: '#671FF3',
    MIN: '#4E6178',
    MAX: '#4E6177',
    timeline: 'transparent'
};

export const KPIHistoricalChartStyles = {
    colors,
    labelStyles,
    createMarker: (props: CartesianMarkerProps): CartesianMarkerProps => ({
        legendPosition: 'bottom',
        lineStyle: { stroke: '#D5DFEB', strokeWidth: 2 },
        legendOrientation: 'horizontal',
        textStyle: labelStyles,
        ...props
    }),
    GoalPoint: (props: { color?: string; pointColor?: string; type: 'MIN' | 'MAX'; theme?: 'light' | 'dark' }) => {
        const { theme = 'light' } = props;

        const rotate = ('MIN' === props.type && 270) || ('MAX' === props.type && 90) || 0;

        const circleFill = (theme === 'light' && '#fff') || (theme === 'dark' && 'transparent') || '';
        const circleStroke = (theme === 'light' && props.color) || (theme === 'dark' && '#fff') || '';
        const polygonFill = (theme === 'light' && props.pointColor) || (theme === 'dark' && '#fff') || '';

        return (
            <g>
                <circle fill={circleFill} r={8} strokeWidth={1} stroke={circleStroke} />
                <polygon
                    points={`0,-8 8,0 0,8`}
                    style={{
                        transform: `rotate(${rotate}deg) scale(1, 0.6) translate(-3px)`
                    }}
                    fill={polygonFill}
                />
            </g>
        );
    },
    DataPoint: (props: { status?: KPIStatus | null }) => {
        return (
            <g>
                <circle r={2} strokeWidth={1} fill={colors[props.status || 'indeterminate']} />
            </g>
        );
    }
};
