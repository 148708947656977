import SimplePopover from '../../../components/BasicComponents/Popovers/SimplePopover';
import Spinner from '../../../components/BasicComponents/Spinner/Spinner';

const ReportGeneratePDFPopover = ({ openModal, setOpenModal }) => {
    return (
        <SimplePopover
            openModal={openModal}
            setOpenModal={setOpenModal}
            title={''}
            noClose={true}
            className="reports__popover"
        >
            <p>Estamos generando el PDF. Espera unos segundos, por favor.</p>
            <Spinner positionRelative={true} />
        </SimplePopover>
    );
};

export default ReportGeneratePDFPopover;
