// eslint-disable-next-line import/named
import { TablerIconProps } from '@tabler/icons';
import { IconAlertOctagon, IconAlertTriangle, IconCircleCheck, IconHelp, IconInfoCircle } from '@tabler/icons';

import { KPIStatus } from 'modules/promotion-kpis';
import './KPIStatusIcon.scss';

interface KPIStatusIconProps {
    status?: KPIStatus | null;
    className?: string;
}

const KPIStatusIcon = ({ status, className }: KPIStatusIconProps) => {
    const params: TablerIconProps & { [key: string]: any } = {
        className: `KPIStatusIcon KPIStatusIcon--${status} ${className || ''}`,
        size: 22,
        stroke: 1.5,
        'data-testid': 'KPIStatusIcon'
    };

    if (status === KPIStatus.SUCCESS) return <IconCircleCheck {...params} />;
    if (status === KPIStatus.DANGER) return <IconAlertOctagon {...params} />;
    if (status === KPIStatus.WARNING) return <IconAlertTriangle {...params} />;
    if (status === KPIStatus.NOT_APPLICABLE) return <IconInfoCircle {...params} />;
    if (status === KPIStatus.INDETERMINATE) return <IconHelp {...params} />;

    return null;
};

export default KPIStatusIcon;
