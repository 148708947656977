import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

import { useE2EPermissions } from 'modules/e2e';
import { usePromotion } from 'modules/promotion/infrastructure/react/promotionContext';

export const useE2EPermissionsGuard = () => {
    const history = useHistory();
    const [{ promotion }] = usePromotion();

    const [{ permissions }, { loadE2EPermissions }] = useE2EPermissions();

    useEffect(() => {
        loadE2EPermissions();
    }, []);

    const shouldRedirectOutside = permissions && (!permissions.canAccess.value || permissions.isDisabled.value);

    useEffect(() => {
        if (shouldRedirectOutside) {
            history.push(promotion?.id ? `/promotion/${promotion.id}/traceabilities` : '/traceabilities');
        }
    }, [shouldRedirectOutside, history]);
};
