// eslint-disable-next-line import/named
import { Link, LinkProps } from 'react-router-dom';
import React from 'react';

import { KPIStatus } from 'modules/promotion-kpis';
import './KPIAlarm.scss';
import KPIStatusIcon from '../KPIStatusIcon';

interface KPIAlarmProps {
    title?: string;
    text: React.JSX.Element;
    status?: KPIStatus | null;
    link?: { to: LinkProps['to']; text: string };
    onLinkClick?: () => void;
}

const KPIAlarm = (props: KPIAlarmProps) => {
    return (
        <>
            <div className={`KPIAlarm`}>
                <KPIStatusIcon status={props.status} />
                <div className="KPIAlarm__container">
                    <div className="KPIAlarm__title" data-testid={`KPIAlarm__title`}>
                        {props.title}
                    </div>
                    <div className="KPIAlarm__text" data-testid={`KPIAlarm__text`}>
                        {props.text}
                        {props.link && (
                            <Link className="KPIAlarm__link" to={props.link.to} onClick={props.onLinkClick}>
                                {props.link.text} »
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default KPIAlarm;
