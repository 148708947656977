export const traceabilityE2EMapperPOST = ({ ppgcl, date, remarks, volume }) => {
    const traceabilityE2E = {
        promoProGesCodLerId: ppgcl?.id,
        date: date,
        remarks: remarks === '' ? null : remarks,
        volume: volume === '' ? null : volume,
        documentoPromos: []
    };

    return traceabilityE2E;
};
