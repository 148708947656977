import { useReloadAfterUpdate } from 'features/automatic-reading/hooks/useReloadAfterUpdate';
import { useLoadAfterFiltersChange } from '../../hooks/useLoadAfterFiltersChange';
import { useReloadExtractionsWhilePending } from '../../hooks/useReloadExtractionsWhilePending';
import AutomaticReadingFilters from '../AutomaticReadingFilters/AutomaticReadingFilters';
import AutomaticReadingList from '../AutomaticReadingList/AutomaticReadingList';
import './AutomaticReadingPage.scss';

const AutomaticReadingPage = () => {
    useReloadExtractionsWhilePending();
    useLoadAfterFiltersChange();
    useReloadAfterUpdate();

    return (
        <div className="AutomaticReadingPage">
            <AutomaticReadingFilters />
            <AutomaticReadingList />
        </div>
    );
};

export default AutomaticReadingPage;
