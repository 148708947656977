import { Trans, useTranslation } from 'react-i18next';
import { Link, Redirect, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import Tabs from 'components/BasicComponents/Tabs';
import PrivateRoute from 'components/routes/PrivateRoute';
import { PromotionKPIs } from 'modules/promotion-kpis/domain/PromotionKPIs';
import './KPIsGoalSettings.scss';
import KPIGoals from './components/KPIGoals';
import KPIGoalsExport from './components/KPIGoalsExport';

const KPIsGoalSettings = () => {
    const [t] = useTranslation('KPIsGoalSettings');
    const match = useRouteMatch();
    const { pathname } = useLocation();

    const routes = Object.values(PromotionKPIs)
        .map((kpi) => ({
            kpi,
            path: `/${kpi}`,
            component: () => <KPIGoals kpi={kpi} />,
            exact: false
        }))
        .filter((route) => route.kpi !== PromotionKPIs.certifiedTraceabilities);

    const root = `/promotion/:promotionId/settings/kpi-goals`;
    const current = `${match.url}`;

    return (
        <>
            <div className="KPIsGoalSettings">
                <header className="KPIsGoalSettings__header">
                    <div className="KPIsGoalSettings__title">
                        <h2>{t('title')}</h2>
                        <p>
                            <Trans i18nKey="KPIsGoalSettings:subtitle" components={{ br: <br /> }} />
                        </p>
                    </div>
                    <KPIGoalsExport />
                </header>

                <Tabs
                    activeTab={routes.findIndex((route) => pathname.includes(route.path))}
                    tabs={routes.map((route) => (
                        <Link to={current + route.path}>{t(`kpi.${route.kpi}.title`)}</Link>
                    ))}
                />

                <Switch>
                    {routes
                        .filter((route) => route.component)
                        .map(({ path, ...route }) => (
                            <PrivateRoute path={root + path} {...route} />
                        ))}
                    <Redirect to={current + routes[0].path} />
                </Switch>
            </div>
        </>
    );
};

export default KPIsGoalSettings;
