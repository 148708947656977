import { KPIStatus } from '../KPIStatus';
import { CertifiedTraceabilities } from './CertifiedTraceabilities';

export const calcCertifiedTraceabilitiesStatus = (params: {
    certifiedTraceabilities?: CertifiedTraceabilities | null;
    promotionProgress?: number;
}) => {
    const { certifiedTraceabilities, promotionProgress = 0 } = params;

    if (!certifiedTraceabilities) return { certifiedTraceabilities, status: KPIStatus.INDETERMINATE };

    const unmet = certifiedTraceabilities.data < certifiedTraceabilities.goal;
    const met = !unmet;
    const notCertified = certifiedTraceabilities.goal - certifiedTraceabilities.data;

    const status: KPIStatus | null =
        (certifiedTraceabilities.goal === 0 && KPIStatus.INDETERMINATE) ||
        (unmet && promotionProgress >= 50 && KPIStatus.DANGER) ||
        (unmet && promotionProgress < 50 && KPIStatus.WARNING) ||
        (met && KPIStatus.SUCCESS) ||
        null;

    return {
        certifiedTraceabilities,
        status,
        notCertified
    };
};
