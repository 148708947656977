import InputNumber from 'components/BasicComponents/Inputs/InputNumber';
import TextArea from 'components/BasicComponents/Inputs/TextArea';
import { E2EPermissionsProvider } from 'modules/e2e';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import HasPermission from '../../../components/AccessCheckers/HasPermission';
import Buscador from '../../../components/BasicComponents/Buscador/Buscador';
import GoBack from '../../../components/BasicComponents/Buttons/GoBack';
import SubmitButton from '../../../components/BasicComponents/Buttons/SubmitButton';
import InputFecha from '../../../components/BasicComponents/Inputs/InputFecha';
import MessageNotComplete from '../../../components/BasicComponents/Messages/MessageNotComplete';
import ResultadoCarga from '../../../components/BasicComponents/Messages/ResultadoCarga';
import CodLerAutocomplete from '../../../components/BasicComponents/Select-Autocomplete/CodLerAutocomplete';
import PromoProGesCodLerAutocomplete from '../../../components/BasicComponents/Select-Autocomplete/PromoProGesCodLerAutocomplete';
import { TituloH1 } from '../../../components/BasicComponents/Titulos/Titulos';
import { withAuth } from '../../../context/AuthProvider';
import { getPpgcl } from '../../../features/new_edit_traceability/helpers/newEditTracability.helpers';
import Layout from '../../../pages/Layout/Layout';
import { getYearMonthDay } from '../../../utils/helpers/general.helpers';
import { createTraceabilityE2E } from '../api/createTraceabilityE2E';
import { useE2EPermissionsGuard } from '../hooks/useE2EPermissionsGuard';
import { traceabilityE2EMapperPOST } from '../mappers/traceabilityE2EMapperPOST';
import './RequestTraceabilityE2EPage.scss';

const RequestTraceabilityE2EPage = (props) => {
    useE2EPermissionsGuard();
    const [t] = useTranslation();
    const history = useHistory();

    const INITIAL_STATE_VALUES = { date: getYearMonthDay(), ppgcl: null, volume: null, remarks: '' };

    const INITIAL_STATE_PPGCL = {
        wasteManager: null,
        codLer: null,
        carrier: null
    };

    const [selectedPpgcl, setSelectedPpgcl] = useState(INITIAL_STATE_PPGCL);
    const isCodLerInitialized = useRef(false);

    const [values, setValues] = useState(INITIAL_STATE_VALUES);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [messageNotComplete, setMessageNotComplete] = useState('');
    const [message, setMessage] = useState('');
    const [isFormValidated, setIsFormValidated] = useState(true);

    useEffect(() => {
        // avoid entering on first render (when everything is null)
        if (selectedPpgcl?.wasteManager !== null || selectedPpgcl?.codLer !== null || selectedPpgcl?.carrier !== null) {
            // to avoid setting wasteManager to null the first time (when we set codLer value) we skip the 1st run of this useeffect with useRef
            if (!isCodLerInitialized.current) {
                isCodLerInitialized.current = true;
                return;
            }
            setSelectedPpgcl({ ...selectedPpgcl, wasteManager: null, carrier: null });
            setValues({ ...values, ppgcl: null });
        }
    }, [selectedPpgcl?.codLer]);

    useEffect(() => {
        // avoid entering on first render (when everything is null)
        if (selectedPpgcl?.wasteManager !== null || selectedPpgcl?.codLer !== null || selectedPpgcl?.carrier !== null) {
            setSelectedPpgcl({ ...selectedPpgcl, carrier: null });
            setValues({ ...values, ppgcl: null });
        }
    }, [selectedPpgcl?.wasteManager]);

    useEffect(() => {
        if (
            selectedPpgcl?.wasteManager?.id !== undefined &&
            selectedPpgcl?.wasteManager?.id !== null &&
            selectedPpgcl?.codLer?.codigoLER !== undefined &&
            selectedPpgcl?.codLer?.codigoLER !== null &&
            selectedPpgcl?.carrier?.id !== undefined &&
            selectedPpgcl?.carrier?.id !== null
        ) {
            getSelectedPromoProGesCodLer();
        } else {
            setValues({ ...values, ppgcl: null });
        }
    }, [selectedPpgcl?.carrier]);

    const searchPpgcl = async () => {
        return await getPpgcl({
            promotionId: props?.promocion?.id,
            codLer: selectedPpgcl?.codLer?.codigoLER,
            wasteManagerId: selectedPpgcl?.wasteManager?.id,
            carrierId: selectedPpgcl?.carrier?.id
        });
    };

    const getSelectedPromoProGesCodLer = async () => {
        const ppgcl = await searchPpgcl();
        setValues({ ...values, ppgcl, volume: ppgcl?.volumenUnitario || 0 });
    };

    const handleFormsubmit = async (e) => {
        e.preventDefault();

        if (!validarForm()) return;

        setIsSubmitting(true);

        const e2eTraceability = traceabilityE2EMapperPOST({
            ppgcl: values?.ppgcl,
            date: values?.date,
            remarks: values?.remarks,
            volume: values?.volume
        });

        await createTraceabilityE2E(e2eTraceability);

        setValues(INITIAL_STATE_VALUES);
        setSelectedPpgcl(INITIAL_STATE_PPGCL);
        setIsSubmitting(false);
    };

    const validarForm = () => {
        setMessageNotComplete('');
        if (values?.date === '' || values?.ppgcl === null) {
            setIsFormValidated(false);
            setMessageNotComplete(t('complete', { ns: 'common' }));
            return false;
        } else {
            setIsFormValidated(true);
            return true;
        }
    };

    const handleInputChange = ({ target }) => {
        setValues({ ...values, [target.name]: target.value });
    };

    const goBack = () => {
        history.goBack();
    };

    const linkToWasteConfiguration = () => {
        if (props?.isCoCircular) {
            return '/waste-manager/dashboard';
        }

        return `/settings/promotion/${props?.promocion?.id}/edit`;
    };

    return (
        <Layout>
            <GoBack goBack={goBack} />
            <TituloH1 titulo={t('requestDI', { ns: 'traceabilityE2E' })} />
            <Buscador titulo="" column={true}>
                <HasPermission ifRole="canView" section={['wasteConfiguration', 'configuration']}>
                    <p>
                        <span>{t('rememberConfiguration.pt1', { ns: 'traceabilityE2E' })} </span>
                        <Link to={linkToWasteConfiguration()}>
                            {t('rememberConfiguration.pt2', { ns: 'traceabilityE2E' })}
                        </Link>
                        <span> {t('rememberConfiguration.pt3', { ns: 'traceabilityE2E' })}.</span>
                    </p>
                </HasPermission>
                <form onSubmit={handleFormsubmit}>
                    <CodLerAutocomplete
                        values={selectedPpgcl}
                        setValues={setSelectedPpgcl}
                        value={selectedPpgcl?.codLer}
                        validacion={!isFormValidated}
                        resetCarrier={true}
                        resetWasteManager={true}
                        requestContainerODD={true}
                        required
                    />
                    <PromoProGesCodLerAutocomplete
                        values={selectedPpgcl}
                        setValues={setSelectedPpgcl}
                        value={selectedPpgcl?.wasteManager}
                        customLabel={t('wasteManagerDestination', { ns: 'traceabilityE2E' })}
                        wasteManager="wasteManager"
                        carrier="carrier"
                        name="wasteManager"
                        validacion={!isFormValidated}
                        requestContainerODD={true}
                        required
                    />
                    <PromoProGesCodLerAutocomplete
                        values={selectedPpgcl}
                        setValues={setSelectedPpgcl}
                        value={selectedPpgcl?.carrier}
                        validacion={!isFormValidated}
                        customLabel={t('carrierEnterprise', { ns: 'traceabilityE2E' })}
                        wasteManager="wasteManager"
                        typeOfWasteManager="CARRIER"
                        name="carrier"
                        requestContainerODD={true}
                        required
                    />

                    <InputNumber
                        label={`${t('volume.upperCase', { ns: 'traceability' })}`}
                        name="volume"
                        value={values?.volume}
                        onChange={handleInputChange}
                    />
                    <InputFecha
                        label={t('datePickUp', { ns: 'traceabilityE2E' })}
                        name="date"
                        value={values?.date}
                        onChange={handleInputChange}
                        validacion={!isFormValidated}
                        notLimit={true}
                        required
                    />
                    <TextArea
                        className="RequestTraceabilityE2EPage__textarea"
                        label={`Observaciones / Información para la gestora`}
                        name="remarks"
                        value={values?.remarks}
                        onChange={handleInputChange}
                        placeholder={`Ejemplo:\nNº contenedor: 712341234.\nRecoger a partir de las 4 de la tarde.`}
                    />

                    {messageNotComplete && <MessageNotComplete message={messageNotComplete} />}
                    {message.text !== undefined && (
                        <ResultadoCarga text={message.text} success={message.success} setMessage={setMessage} />
                    )}
                    <SubmitButton text={t('requestDI', { ns: 'traceabilityE2E' })} buttonDisabled={isSubmitting} />
                </form>
            </Buscador>
        </Layout>
    );
};

export default withAuth((props) => {
    if (!props.promocion) return null;

    return (
        <E2EPermissionsProvider promotion={{ getCurrent: async () => props.promocion }}>
            <RequestTraceabilityE2EPage {...props} />
        </E2EPermissionsProvider>
    );
});
