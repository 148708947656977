// import { useTranslation } from 'react-i18next';
import { TituloH2, TituloH4 } from '../../../components/BasicComponents/Titulos/Titulos';
import { withAuth } from '../../../context/AuthProvider';
import { getGraphicLink } from '../../dashboard/helpers/biuwer.helpers';
import './ReportPage.scss';

const ReportInitialKpis = (props) => {
    // const [t] = useTranslation();

    const getLink = (resourceId, includeLands = null) => {
        let landFilter = '';

        if (includeLands !== null) {
            landFilter = `&land=${includeLands}`;
        }
        return getGraphicLink(resourceId, [props?.promocion?.id], landFilter) || '';
    };

    return (
        <>
            <TituloH2 titulo={'2. RESUMEN Y OBJETIVOS'} />

            <TituloH4 titulo={'Valorización'} />
            <small>Objetivos: 70% mínimo legal (valorizables) - 90% tierras - 6 flujos</small>
            <div className="reports__iframeWrapper">
                <iframe
                    height="290"
                    className="reports__kpisIframe reports__kpisIframe-oneLine"
                    src={getLink('fd7a4620-c337-11ed-ae09-17a1a794b024')}
                />
            </div>
            <br />
            <TituloH4 titulo={'Residuos producidos'} />
            <div className="reports__iframeWrapper">
                <iframe
                    height="290"
                    className="reports__kpisIframe reports__kpisIframe-oneLine"
                    src={getLink('113f2810-c338-11ed-ae09-17a1a794b024')}
                />
            </div>
            <br />
            <TituloH4 titulo={'Factor de generación de residuos (FGR)'} />
            <div className="reports__iframeWrapper">
                <iframe
                    height="450"
                    className="reports__kpisIframe reports__kpisIframe-multipleLines"
                    src={getLink('b51046a0-c184-11ed-ae09-17a1a794b024')}
                />
            </div>
            <br />
            <TituloH4 titulo={'Impacto medioambiental CO₂'} />
            <div className="reports__iframeWrapper">
                <iframe
                    height="450"
                    className="reports__kpisIframe reports__kpisIframe-multipleLines"
                    src={getLink('15a4f330-c338-11ed-ae09-17a1a794b024')}
                />
            </div>
        </>
    );
};

export default withAuth(ReportInitialKpis);
