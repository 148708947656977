import { ReactComponent as ImpactIcon } from 'assets/iconos/metrics_evolution/impact.svg';
import { ReactComponent as ManagedIcon } from 'assets/iconos/metrics_evolution/managed.svg';
import { ReactComponent as ValorizedIcon } from 'assets/iconos/metrics_evolution/valued.svg';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import { usePromotion } from 'modules/promotion';
import { useWasteSummary } from 'modules/waste-summary';
import { useTranslation } from 'react-i18next';
import { currencyFormatDE, roundNumber } from 'utils/helpers/general.helpers';
import './WasteSummary.scss';

const WasteSummary = () => {
    const [t] = useTranslation();

    const [{ promotion }] = usePromotion();
    if (!promotion) return <></>;

    // eslint-disable-next-line
    const { data } = useWasteSummary({ promotionId: promotion?.id });

    const formatNumber = (number) => {
        const num = currencyFormatDE(roundNumber(number, 1));
        return num;
    };

    return (
        <SimpleCard className="WasteSummary">
            <div className="WasteSummary__section">
                <ManagedIcon />
                <div className="WasteSummary__data">
                    <h4 data-testid="managed">
                        <span>Residuos gestionados</span>
                    </h4>
                    <ul>
                        <li data-testid="managedValuableTons">
                            <span>{formatNumber(data?.managed.valuableTons)} t valorizables</span>
                        </li>
                        <li data-testid="managedDangerousTons">
                            <span>{formatNumber(data?.managed.dangerousTons)} t peligrosos</span>
                        </li>
                        <li data-testid="managedLandTons">
                            <span>{formatNumber(data?.managed.landTons)} t tierras</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="WasteSummary__section">
                <ValorizedIcon />
                <div className="WasteSummary__data">
                    <h4 data-testid="valorized">
                        <span>Residuos valorizados</span>
                    </h4>
                    <ul>
                        <li data-testid="valuedValuableTons">
                            <span>{formatNumber(data?.valuated.valuableTons)} t valorizables</span>
                        </li>
                        <li data-testid="valuedLandTons">
                            <span>{formatNumber(data?.valuated.landTons)} t tierras</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="WasteSummary__section">
                <ImpactIcon />
                <div className="WasteSummary__data">
                    <h4 data-testid="impact">
                        <span>Impacto medioambiental</span>
                    </h4>
                    <ul>
                        <li data-testid="generatedCo2">
                            <span>{formatNumber(data?.impact.generatedCo2)} t CO₂ eq. generado</span>
                        </li>
                        <li data-testid="avoidedCo2">
                            <span>{formatNumber(data?.impact.avoidedCo2)} t CO₂ eq. evitado</span>
                        </li>
                    </ul>
                </div>
            </div>
        </SimpleCard>
    );
};

export default WasteSummary;
