import { useTranslation } from 'react-i18next';

import SimplePopover from 'components/BasicComponents/Popovers/SimplePopover';
import useStateFromStorage from 'hooks/useStateFromStorage';
import { useAuth } from 'modules/auth';
import Onboarding from '../Onboarding';
import './OnboardingPopover.scss';

const key = 'OnboardingPopover--enabled';

const OnboardingPopover = () => {
    const [t] = useTranslation('onboarding');
    const [{ user }] = useAuth();
    const [enabled, setEnabled] = useStateFromStorage({ key, initialState: true });

    const close = () => {
        setEnabled(false);
        window.alert(t('popover.closed'));
    };

    if (!user) return null;

    return (
        <SimplePopover openModal={enabled} setOpenModal={close}>
            <div className="OnboardingPopover">
                <header className="OnboardingPopover__header">
                    <h2 className="OnboardingPopover__title">{t('popover.title')}</h2>
                    <p className="OnboardingPopover__subtitle">{t('popover.subtitle')}</p>
                </header>
                <Onboarding />
            </div>
        </SimplePopover>
    );
};

export default OnboardingPopover;
