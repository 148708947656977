import './IconCard.scss';
export const IconCard = ({ descriptionMetric = '', metric = '', unit = '', icon, warning = false }) => {
    return (
        <div className={warning ? 'iconCard iconCard--warning' : 'iconCard'}>
            {icon}
            <div className="iconCard__info">
                <span className="iconCard__description">{descriptionMetric}</span>
                <p className="iconCard__metric">
                    {metric !== null ? metric : <span className="iconCard__notavailable">N/D</span>}
                    {metric !== null && <span className="iconCard__unit">{unit}</span>}
                </p>
            </div>
        </div>
    );
};
