import { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withAuth } from '../../../context/AuthProvider';
import NewPpgclStepper from '../../../features/new_ppgcl/components/NewPpgclStepper';
import CreateButton from '../../BasicComponents/Buttons/CreateButton';
import SimpleCard from '../../BasicComponents/Cards/SimpleCard';
import { TituloH3 } from '../../BasicComponents/Titulos/Titulos';
import NewCodLersRequest from '../PromotionSettings/NewCodLersRequest';
import NewWasteManagerRequest from '../PromotionSettings/NewWasteManagerRequest';
import SeekerPpgcl from './SeekerPpgcl/SeekerPpgcl';

export const PpgclContext = createContext({});

const { Provider } = PpgclContext;

const CardPpgclSettings = (props) => {
    const [t] = useTranslation();
    const [allPpgcl, setAllPpgcl] = useState([]);
    const [openCodLerPopover, setOpenCodLerPopover] = useState(false);
    const [openWasteManagerPopover, setOpenWasteManagerPopover] = useState(false);
    return (
        <>
            <Provider value={{ setAllPpgcl, allPpgcl }}>
                <SimpleCard>
                    <SeekerPpgcl />
                </SimpleCard>
                <SimpleCard className="ppgcl__card">
                    <TituloH3 titulo="Creador de flujos de residuos" />
                    <div className="detailPromotion__requestButtons ppgcl__containerButton">
                        <CreateButton
                            text={t('title', { ns: 'newWasteManager' })}
                            action={() => setOpenWasteManagerPopover(true)}
                            type="button"
                        />
                    </div>
                    <NewPpgclStepper />
                    {openWasteManagerPopover && (
                        <NewWasteManagerRequest
                            openModal={openWasteManagerPopover}
                            setOpenModal={setOpenWasteManagerPopover}
                            promotionName={props.promocion?.nombre}
                        />
                    )}

                    {openCodLerPopover && (
                        <NewCodLersRequest openModal={openCodLerPopover} setOpenModal={setOpenCodLerPopover} />
                    )}
                </SimpleCard>
            </Provider>
        </>
    );
};
export default withAuth(CardPpgclSettings);
