import { IconInfoCircle, IconX } from '@tabler/icons';
import { useAuth } from 'modules/auth';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import promotionServices from '../../../../api/promotion/promotion.services';
import userServices from '../../../../api/user/user.services';
import { withAuth } from '../../../../context/AuthProvider';
import { allFieldsComplete } from '../../../../utils/helpers/general.helpers';
import { checkIfCoCircularResponsability } from '../../../../utils/helpers/user.helpers';
import SubmitButton from '../../../BasicComponents/Buttons/SubmitButton';
import InputEmail from '../../../BasicComponents/Inputs/InputEmail';
import InputNumber from '../../../BasicComponents/Inputs/InputNumber';
import InputText from '../../../BasicComponents/Inputs/InputText';
import MessageNotComplete from '../../../BasicComponents/Messages/MessageNotComplete';
import ResultadoCarga from '../../../BasicComponents/Messages/ResultadoCarga';
import Spinner from '../../../BasicComponents/Spinner/Spinner';
import { TituloH3 } from '../../../BasicComponents/Titulos/Titulos';
import PromotionsUserAutocomplete from '../../PromotionSettings/PromotionsUserAutocomplete';
import EnterpriseAutocomplete from '../RelatedEnterprises/components/autocompletes/EnterpriseAutocomplete';
import LanguageSelect from './LanguageSelect';
import './NewUserForm.scss';
import RoleAutocomplete from './RoleAutocomplete';
import UserWasteManagerSelector from './components/UserWasteManagerSelector';

const INITIAL_STATE_USER = {
    roleType: '',
    firstName: '',
    lastName: '',
    langKey: 'es',
    email: '',
    phoneOne: '',
    phoneTwo: '',
    notifiable: false,
    authorities: [],
    promotionIds: [],
    wasteManagers: [],
    activated: true,
    enterprise: ''
};

const NewUserForm = ({
    setToggleFormNewUser,
    onlyDumpmaster = false,
    userToEditOrCreate,
    associateToPromotion = false,
    myPromotions,
    setUsers,
    users,
    promotion
}) => {
    const [{ user: account }] = useAuth();
    const [user, setUser] = useState(INITIAL_STATE_USER);
    const [loading, setLoading] = useState(false);
    const [validation, setValidation] = useState(undefined);
    const [message, setMessage] = useState('');
    const [messageNotComplete, setMessageNotComplete] = useState('');
    const [editUser, setEditUser] = useState(false);
    const [userPromotions, setUserPromotions] = useState([]);
    const [t] = useTranslation();

    const deletePromotionToTheList = (id) => {
        setUser({
            ...user,
            promotionIds: user.promotionIds.filter((idPromotion) => idPromotion !== id)
        });
        setUserPromotions(userPromotions.filter((promotion) => promotion.id !== id));
    };

    const searchUserPromotions = async () => {
        const filter = `?id.in=${userToEditOrCreate.promotionIds}`;
        const promotions = await promotionServices.filterPromotion(filter);
        setUserPromotions(promotions);
    };

    const updateUserInArrUsers = (userEdited) => {
        const objIndex = users.findIndex((user) => user.id === userEdited.id);
        users[objIndex] = userEdited;
        setUsers(users);
    };

    const addUserInArrUsers = (userEdited) => {
        setUsers([userEdited, ...users]);
    };

    const createMessage = (user) => {
        if (user !== undefined && (user.status === 201 || user.status === 200)) {
            setMessage({ success: true, text: t('successCreateUser', { ns: 'newPromotion' }) });
            setTimeout(() => {
                setToggleFormNewUser(false);
                setUser(INITIAL_STATE_USER);
            }, 1500);
        } else {
            setMessage({ success: false, text: t('errorCreateUser', { ns: 'newPromotion' }) });
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (await validationForm()) {
            if (editUser) {
                userToEditOrCreate = await userServices.editUser(user);
                updateUserInArrUsers(userToEditOrCreate.data.content);
            } else {
                userToEditOrCreate = await userServices.createUser({
                    ...user,
                    promotionIds: [...(user.promotionIds || []), ...(promotion?.id ? [promotion.id] : [])]
                });

                addUserInArrUsers(userToEditOrCreate.data.content);
            }
            createMessage(userToEditOrCreate);
        }
        setLoading(false);
    };

    const handleInputChange = ({ target }) => {
        setUser({
            ...user,
            [target.name]: target.value
        });
    };

    const requiredFields = () => {
        return {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            roleType: user.roleType,
            authorities: user.authorities,
            enterprise: user.enterprise
        };
    };

    const checkIfUserExists = async () => {
        const checkUser = await userServices.getUser(`?mail.equals=${user?.email}`);
        const userAlreadyExists = checkUser.length > 0;
        return userAlreadyExists;
    };

    const validationForm = async () => {
        let requiredInputs = requiredFields();
        if ((await checkIfUserExists()) && !editUser) {
            setMessageNotComplete('Este email ya está registrado en la plataforma');
            setValidation(true);
            return false;
        }

        if (onlyDumpmaster) {
            requiredInputs = { ...requiredInputs, wasteManagers: user?.wasteManagers };
        }

        if (associateToPromotion) {
            requiredInputs = { ...requiredInputs, promotionIds: user.promotionIds };
        }

        if (allFieldsComplete(requiredInputs)) {
            setMessageNotComplete('');
            setValidation(false);
            return true;
        } else {
            setMessageNotComplete(t('notComplete', { ns: 'errors' }));
            setValidation(true);
            return false;
        }
    };

    const onRadioChange = ({ target }) => {
        let arrAuthorities = [];
        if (user.authorities.includes(target.value)) {
            arrAuthorities = user.authorities.filter((authority) => authority !== target.value);
        } else {
            arrAuthorities = [...user.authorities, target.value];
        }
        setUser({ ...user, authorities: arrAuthorities });
    };

    const updateNotifiable = () => {
        setUser({ ...user, notifiable: !user.notifiable });
    };

    const checkIfIsDumpmaster = () => {
        return onlyDumpmaster || user?.authorities?.includes('ROLE_DUMPMASTER');
    };

    const initializeUser = async () => {
        let userAux = INITIAL_STATE_USER;

        if (userToEditOrCreate) {
            // edit user
            setEditUser(true);
            userAux = { ...userToEditOrCreate };

            if (userAux?.notifiable === null) {
                userAux.notifiable = false;
            }

            if (associateToPromotion) {
                searchUserPromotions();
            }
        } else {
            // create new user
            if (checkIfIsDumpmaster()) {
                userAux = { ...userAux, authorities: ['ROLE_DUMPMASTER'], notifiable: true };
            }
        }

        setUser(userAux);
    };

    const showIfNotCoCircular = () => {
        return checkIfCoCircularResponsability(user?.roleType?.id);
    };

    useEffect(() => {
        initializeUser();
    }, [userToEditOrCreate]);

    /** Start effect: Sets user's account enterprise to form values if match conditions */
    const isAdmin = account?.authorities?.includes('ROLE_ADMIN');
    const userEnteprise = account?.enterprise;

    const shouldSetOwnEnterprise = userEnteprise && !isAdmin && user.id;

    useEffect(() => {
        if (shouldSetOwnEnterprise) {
            const payload = { ...user, enterprise: userEnteprise };
            setUser(payload);
        }
    }, [shouldSetOwnEnterprise, JSON.stringify(userEnteprise)]);
    /** Ends effect */

    return (
        <form onSubmit={handleFormSubmit} className="newUserForm">
            <div className="newUserForm__containerInputs">
                <InputEmail
                    label={t('email', { ns: 'users' })}
                    name="email"
                    value={user.email}
                    id="email--newUser"
                    onChange={handleInputChange}
                    validacion={validation}
                    required
                />
                <EnterpriseAutocomplete
                    disabled={shouldSetOwnEnterprise}
                    className="input__container"
                    // Adapted from new to old enterprise model
                    values={{
                        ...user,
                        enterprise: user.enterprise ? { ...user.enterprise, nombreComercial: user.enterprise.name } : ''
                    }}
                    setValues={({ enterprise, ...restUser }) => {
                        setUser({
                            ...restUser,
                            enterprise: { id: enterprise.id, cif: enterprise.cif, name: enterprise.nombreComercial }
                        });
                    }}
                    propName="enterprise"
                    isFormValid={!validation}
                    required
                    tooltip={
                        shouldSetOwnEnterprise ? (
                            <Tooltip msg={t('notAllowedChangeEnterprise', { ns: 'users' })}>
                                <IconInfoCircle color="#7F42F5" size={20} stroke={1.5} />
                            </Tooltip>
                        ) : null
                    }
                />
            </div>

            <div className="newUserForm__containerInputs">
                <InputText
                    label={t('firstName', { ns: 'users' })}
                    name="firstName"
                    value={user.firstName}
                    id="firstName--newUser"
                    onChange={handleInputChange}
                    validacion={validation}
                    required
                />
                <InputText
                    label={t('lastName', { ns: 'users' })}
                    name="lastName"
                    value={user.lastName}
                    id="lastName--newUser"
                    onChange={handleInputChange}
                    validacion={validation}
                    required
                />
            </div>
            <div className="newUserForm__containerInputs">
                <LanguageSelect values={user} setValues={setUser} onChange={handleInputChange} />
                <RoleAutocomplete
                    values={user}
                    setValues={setUser}
                    validacion={validation}
                    {...{ onlyDumpmaster }}
                    required
                />
            </div>
            <div className="newUserForm__containerInputs">
                <InputNumber
                    label={t('phoneOne', { ns: 'users' })}
                    name="phoneOne"
                    id="phoneOne--newUser"
                    value={user.phoneOne}
                    onChange={handleInputChange}
                    formatNumber={false}
                />
                <InputNumber
                    label={t('phoneTwo', { ns: 'users' })}
                    name="phoneTwo"
                    id="phoneTwo--newUser"
                    value={user.phoneTwo}
                    onChange={handleInputChange}
                    formatNumber={false}
                />
                {isAdmin && (
                    <>
                        <div className="promotionSettings__checkbox">
                            <input
                                id="activated"
                                type="checkbox"
                                checked={user.activated}
                                onChange={() => setUser({ ...user, activated: !user.activated })}
                            />
                            <label htmlFor="activated">Usuario activado</label>
                        </div>
                    </>
                )}
            </div>

            {associateToPromotion && (
                <div className="newUser__containerPromotions">
                    <PromotionsUserAutocomplete
                        myPromotions={myPromotions}
                        promotionsToAdd={userPromotions}
                        setPromotionsToAdd={setUserPromotions}
                        values={user}
                        setValues={setUser}
                        required
                        validacion={validation}
                    />
                    {userPromotions.length > 0 && (
                        <div className="newUser__containerPromotionsSelected">
                            {userPromotions.map((promotion) => (
                                <div key={promotion?.id} className="newUser__promotionsSelected">
                                    {promotion?.nombre}
                                    <IconX onClick={() => deletePromotionToTheList(promotion.id)} />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
            {showIfNotCoCircular() && (
                <div className="newUser__radioContainer">
                    <TituloH3 titulo={t('notifications', { ns: 'users' })} />
                    <div>
                        <input
                            type="checkbox"
                            value={user?.notifiable}
                            id="notifiable"
                            name="notifiable"
                            checked={user?.notifiable === null || user?.notifiable === false ? false : true}
                            onChange={(e) => updateNotifiable(e)}
                            className="checkbox"
                        />
                        <label htmlFor="notifiable">
                            {t(checkIfIsDumpmaster() ? 'wasteManagerE2ENotifications' : 'siteCheckingNotifications', {
                                ns: 'users'
                            })}
                        </label>
                    </div>
                </div>
            )}
            {checkIfIsDumpmaster() ? (
                <div>
                    <UserWasteManagerSelector
                        enterpriseId={user?.enterprise?.id}
                        value={user?.wasteManagers}
                        onChange={(wasteManagers) => setUser((prev) => ({ ...prev, wasteManagers }))}
                    />
                </div>
            ) : (
                <div className="newUser__radioContainer">
                    <TituloH3 titulo={t('rolDescription', { ns: 'users' })} />
                    <div>
                        <input
                            type="checkbox"
                            value="ROLE_READER"
                            id="ROLE_READER"
                            checked={user?.authorities.includes('ROLE_READER')}
                            onChange={onRadioChange}
                            className="checkbox"
                        />
                        <label htmlFor="ROLE_READER">
                            <span>{t('reader', { ns: 'users' })}:</span> {t('readerDescription', { ns: 'users' })}
                        </label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            value="ROLE_WRITER"
                            id="ROLE_WRITER"
                            checked={user?.authorities.includes('ROLE_WRITER')}
                            onChange={onRadioChange}
                            className="checkbox"
                        />
                        <label htmlFor="ROLE_WRITER">
                            <span> {t('writer', { ns: 'users' })}:</span> {t('writerDescription', { ns: 'users' })}
                        </label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            value="ROLE_WRITER_AUDIT"
                            id="ROLE_WRITER_AUDIT"
                            checked={user?.authorities.includes('ROLE_WRITER_AUDIT')}
                            onChange={onRadioChange}
                            className="checkbox"
                        />
                        <label htmlFor="ROLE_WRITER_AUDIT">
                            <span> {t('writerAudit', { ns: 'users' })}: </span>{' '}
                            {t('writerAuditDescription', { ns: 'users' })}
                        </label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            value="ROLE_MAINTENANCE"
                            id="ROLE_MAINTENANCE"
                            checked={user?.authorities.includes('ROLE_MAINTENANCE')}
                            onChange={onRadioChange}
                            className="checkbox"
                        />
                        <label htmlFor="ROLE_MAINTENANCE">
                            <span> {t('maintenance', { ns: 'users' })}: </span>{' '}
                            {t('maintenanceDescription', { ns: 'users' })}
                        </label>
                    </div>
                </div>
            )}

            <SubmitButton text={t('save', { ns: 'common' })} buttonDisabled={loading} />
            {messageNotComplete && <MessageNotComplete message={messageNotComplete} />}
            {loading && <Spinner />}
            {message.text && <ResultadoCarga text={message.text} setMessage={setMessage} success={message.success} />}
        </form>
    );
};
export default withAuth(NewUserForm);
