import { IconLeaf } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import KPIStatusIcon from 'features/KPIs/components/KPIStatusIcon';
import { getCo2ByLerGraphData } from 'features/impact/mappers/FromAPIResponseToGraphData';
import { legacyEnterpriseAdapter } from 'modules/enterprise/related-enterprise/domain/legacyEnterpriseAdapter';
import {
    PromotionKPIsProvider,
    calcCertifiedTraceabilitiesStatus,
    calcKPIStatusFromGoal,
    getCurrentApplicableGoals,
    httpPromotionKPIsApi,
    useCertifiedTraceabilities,
    useMandatoryFlowsSummary,
    useMixSummary,
    useValorizationSummary
} from 'modules/promotion-kpis';
import { withAuth } from '../../../context/AuthProvider';
import { cambiarOrdenFecha, currencyFormatDE } from '../../../utils/helpers/general.helpers.js';
import { truncateString } from '../../../utils/helpers/text.helpers.js';
import { checkIfCoCircularOrAudit } from '../../../utils/helpers/user.helpers';
import SimpleCard from '../../BasicComponents/Cards/SimpleCard';
import { IconCard } from './IconCard';
import './PromotionsDataByUser.scss';

const ICON_SIZE = 25;
const STROKE = 1.5;

const PromotionsDataByUser = (props) => {
    const [t] = useTranslation();
    const [co2, setCo2] = useState();
    const history = useHistory();

    const progressPercentage = props.promotion?.progressPercentage;

    const promotionLink = (id) => {
        if (!id) return;
        props.changePromotion(props.promotion);
        history.push(`/promotion/${id}`);
    };

    const getCO2 = async () => {
        const co2KPI = await getCo2ByLerGraphData(props?.promotion?.id, 'mixed.equals=false');
        setCo2(co2KPI.co2KPI);
    };

    useEffect(() => {
        if (props?.promotion?.id) {
            getCO2();
        }
    }, [props?.promotion?.id]);

    const mainEnterprise = legacyEnterpriseAdapter(props.promotion?.relatedEnterprises);

    return (
        <SimpleCard
            title={
                checkIfCoCircularOrAudit(props?.user)
                    ? `${props?.promotion?.num} - ${truncateString(props?.promotion?.nombre, 40)}`
                    : truncateString(props?.promotion?.nombre, 40)
            }
            action={() => promotionLink(props.promotion?.id)}
            className="promotionCard card-hover"
            button
            buttonTitle={t('view', { ns: 'promotion' })}
            buttonAction={promotionLink}
        >
            <h6 className="promotionCard__subtitle">
                {mainEnterprise?.nombreComercial} | <span>{props.promotion?.promotionType?.nombre}</span>
                {props.promotion?.surface && (
                    <span>
                        - {currencyFormatDE(props.promotion?.surface)} m<sup>2</sup>
                    </span>
                )}
                <span>| {props.promotion.direccion?.municipio?.provincia?.nombre}</span>
                <span>
                    | {progressPercentage}% {t('progress', { ns: 'promotion' })}
                </span>
            </h6>
            <div className="promotionCard__info">
                {/* <IconCard
                        descriptionMetric={t('userPromotions.valuation', { ns: 'promotion' })}
                        metric={currencyFormatDE(percentValorization, numberOfDecimals(percentValorization))}
                        unit="%"
                    /> */}
                <IconValorization promotionId={props?.promotion?.id} promotionProgress={progressPercentage} />
                <IconMix promotionId={props?.promotion?.id} promotionProgress={progressPercentage} />
                <IconMandatoryFlows promotionId={props?.promotion?.id} promotionProgress={progressPercentage} />
                <IconCertifiedTraceabilities
                    promotionId={props?.promotion?.id}
                    promotionProgress={progressPercentage}
                />

                <IconCard
                    descriptionMetric={t('co2.avoided.title', { ns: 'impact' })}
                    metric={currencyFormatDE(co2?.real)}
                    unit="t CO₂ eq"
                    icon={<IconLeaf size={ICON_SIZE} stroke={STROKE} />}
                    warning={co2?.real < co2?.standard}
                />
            </div>
            {props?.promotion?.latestUpdate && (
                <p className="promotionCard__latestUpdate">
                    {t('latestUpdate', { ns: 'promotion' })}: {cambiarOrdenFecha(props.promotion?.latestUpdate)}
                </p>
            )}
        </SimpleCard>
    );
};

export default withAuth((props) => (
    <PromotionKPIsProvider api={httpPromotionKPIsApi}>
        <PromotionsDataByUser {...props} />
    </PromotionKPIsProvider>
));

const IconMix = ({ promotionId, promotionProgress }) => {
    const [t] = useTranslation();
    const summary = useMixSummary({ promotionId });

    const recoverable = summary.data?.recoverable;
    const recoverableUnmetGoal = getCurrentApplicableGoals(recoverable?.goals || [])[0];
    const recoverableStatus = calcKPIStatusFromGoal(recoverableUnmetGoal);

    return (
        <IconCard
            descriptionMetric={t('mix.label', { ns: 'kpis' })}
            metric={recoverable?.value ? currencyFormatDE(recoverable.value) : null}
            unit="%"
            icon={<KPIStatusIcon status={recoverableStatus || null} />}
        />
    );
};

const IconValorization = ({ promotionId, promotionProgress }) => {
    const [t] = useTranslation();

    const summary = useValorizationSummary({ promotionId });

    const recoverable = summary.data?.recoverable;
    const recoverableUnmetGoal = getCurrentApplicableGoals(recoverable?.goals || [])[0];
    const recoverableStatus = calcKPIStatusFromGoal(recoverableUnmetGoal);

    return (
        <IconCard
            descriptionMetric={t('valorization.label', { ns: 'kpis' })}
            metric={recoverable?.value ? currencyFormatDE(recoverable.value) : null}
            unit="%"
            icon={<KPIStatusIcon status={recoverableStatus || null} />}
        />
    );
};

// TODO: Implement mandatory flows
const IconMandatoryFlows = ({ promotionId, promotionProgress }) => {
    const [t] = useTranslation();
    const summary = useMandatoryFlowsSummary({ promotionId });

    const total = summary.data?.lerTypes.length || null;
    const flows = total ? summary.data?.lerTypes.filter((lerType) => lerType.met).length : null;
    const status = calcKPIStatusFromGoal(summary.data);

    const value = total > 0 ? `${flows} / ${total}` : null;

    return (
        <IconCard
            descriptionMetric={t('mandatory-flows.label', { ns: 'kpis' })}
            metric={value}
            icon={<KPIStatusIcon status={status || null} />}
        />
    );
};

const IconCertifiedTraceabilities = ({ promotionId, promotionProgress }) => {
    const [t] = useTranslation();

    const { data } = useCertifiedTraceabilities({ promotionId });

    const current = calcCertifiedTraceabilitiesStatus({
        certifiedTraceabilities: data,
        promotionProgress
    });

    const value = current.certifiedTraceabilities
        ? `${current.certifiedTraceabilities.data} / ${current.certifiedTraceabilities.goal}`
        : null;

    return (
        <IconCard
            descriptionMetric={t('certifiedTraceabilities.label', { ns: 'kpis' })}
            metric={value}
            icon={<KPIStatusIcon status={current.status || null} />}
        />
    );
};
