import { useEffect, useState } from 'react';
import { IconAlertCircle, IconPrinter } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import { withAuth } from 'context/AuthProvider';
import AlertCard from 'components/BasicComponents/Cards/AlertCard';
import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import logoBlue from 'assets/logo-blue.png';

import { getEgrLerTypes } from './api/getEgrLerType';

import ReportIndex from './ReportIndex';
import ReportProjectInfo from './ReportProjectInfo';
import ReportCover from './ReportCover';
import ReportImpact from './ReportImpact';
import './ReportPage.scss';
import ReportGraphics from './ReportGraphics';
import ReportGeneratePDFPopover from './ReportGeneratePDFPopover';
import ReportInitialKpis from './ReportInitialKpis';
import ReportProgress from './ReportProgress';

const MINIMUM_TIME_TO_WAIT = 25; // seconds;

const ReportComponent = (props) => {
    const [t] = useTranslation();
    const [openLoadingPopover, setOpenLoadingPopover] = useState(false);
    const [egrData, setEgrData] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const handlePrint = () => {
        window.print();
    };

    const getHeaderDates = () => {
        const today = new Date();
        const currentDate = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
        const time = today.getHours() + ':' + today.getMinutes();

        let text = t('reportTitle', { ns: 'reports' });
        text += ` - ${t('reportGenerated', { ns: 'reports' })} ${currentDate} ${t('time', { ns: 'reports' })} ${time}h`;

        return text;
    };

    const getEgr = async () => {
        const promotionEgr = await getEgrLerTypes(props?.promocion?.id);
        const data = promotionEgr?.data?.content?.egrResponse || [];
        setEgrData(data);
    };

    useEffect(() => {
        // Provisional timeout until fix race conditions
        setTimeout(() => setLoaded(true), MINIMUM_TIME_TO_WAIT * 1000);

        if (props?.promocion?.id !== undefined) {
            getEgr();
        }
    }, [props?.promocion?.id]);

    const modifiers = {
        hiddenPdf: !loaded ? 'reports__pdf--hidden' : ''
    };

    // Workaround: prevents to be applied globally
    // https://app.clickup.com/t/1c23fk5
    const globalStyle = `
        @page {
            margin: 0cm 1.5cm 1.5cm 1.5cm;
            size: A4;
        }
        html {
            -webkit-print-color-adjust:exact !important;
            print-color-adjust:exact !important;
        }
    `;

    return (
        <div className="reports">
            <style>{globalStyle}</style>
            <div className="reports__explanation">
                {/* Provisional message until fix race conditions */}
                <AlertCard title="Descarga de informe" icon={<IconAlertCircle />}>
                    <p>
                        Antes de descargar el informe,{' '}
                        <strong>verifica que todas las gráficas se han cargado y se visualizan correctamente</strong>{' '}
                        para evitar que aparezcan cortadas o en blanco.
                    </p>
                    <p>
                        Si el informe aparece con márgenes incorrectos,{' '}
                        <strong>comprueba que el zoom del navegador se encuentra al 100%</strong> y descárgalo de nuevo.
                    </p>
                    <footer className="reports__explanationFooter">
                        <SmallButton
                            titulo="Descargar informe"
                            icon={<IconPrinter />}
                            action={() => handlePrint()}
                            type="button"
                            color="blue"
                            disabled={openLoadingPopover || !loaded}
                        />
                    </footer>
                </AlertCard>
            </div>

            {!loaded && <ReportProgress maxCount={MINIMUM_TIME_TO_WAIT} />}

            <div className={`reports__pdf ${modifiers.hiddenPdf}`}>
                {/* Cover component here to avoid header on first page*/}
                <ReportCover />

                <table className="reports__container">
                    <thead className="reports__header">
                        <tr>
                            <th>
                                <img src={logoBlue} alt="Logo CoCircular" className="logo" />
                                <div>
                                    <p>{getHeaderDates()}</p>
                                    <p>{props.promocion?.nombre}</p>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <ReportIndex />
                                {egrData?.length > 0 ? (
                                    <>
                                        <article className="reports__page">
                                            <ReportProjectInfo egrData={egrData} />
                                        </article>
                                        <article className="reports__page">
                                            <ReportInitialKpis />
                                        </article>
                                    </>
                                ) : (
                                    <article className="reports__page">
                                        {/* If no EGR data, we combine both in same page */}
                                        <ReportProjectInfo egrData={egrData} />
                                        <ReportInitialKpis />
                                    </article>
                                )}
                                <ReportGraphics />
                                <ReportImpact />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {openLoadingPopover && (
                <ReportGeneratePDFPopover openModal={openLoadingPopover} setOpenModal={setOpenLoadingPopover} />
            )}
        </div>
    );
};

export default withAuth(ReportComponent);
