import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { IconInfoCircle, IconPrinter } from '@tabler/icons';

import { withAuth } from 'context/AuthProvider';
import activitiesServices from 'api/activities/activities.services';
import Layout from 'pages/Layout/Layout';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import { TituloH1 } from 'components/BasicComponents/Titulos/Titulos';
import SecondaryButton from 'components/BasicComponents/Buttons/SecondaryButton';
import AlertCard from 'components/BasicComponents/Cards/AlertCard';
import { NotFound } from 'pages/NotFound/NotFound';

import { Activity, activityAdapter } from '../activityAdapter';
import ActivityBasicData from './activityDetailSections/ActivityBasicData';
import ActivityFiles from './activityDetailSections/ActivityFiles';
import ActivitySiteCheckingSection from './activityDetailSections/ActivitySiteCheckingSection';
import './ActivityDetail.scss';

const ActivityDetail = ({ user }) => {
    const [t] = useTranslation('activities');
    const { activityId, promotionId } = useParams<{ activityId: string; promotionId: string }>();
    const componentRef = useRef<HTMLDivElement>(null);

    const [activity, setActivity] = useState<Activity | null>(null);
    const [activityExists, setActivityExists] = useState(true); // we assume that it exists by default
    const [files, setFiles] = useState<any>([]); // should type files

    const isAdmin = user?.authorities?.includes('ROLE_ADMIN');
    const isSiteChecking = activity?.typeId === 360203;
    const isStatusReport = activity?.typeId === 385751;

    useEffect(() => {
        if (activityId === '') return;
        loadActivity();
        loadFiles();
    }, [activityId]);

    const loadActivity = async () => {
        const activityWithOldData = await activitiesServices.getActivityById(activityId);

        if (!activityWithOldData) {
            setActivityExists(false);
            return;
        }

        const mappedActivity = activityAdapter(activityWithOldData);
        const cleanedObservations = clearObservationsData(mappedActivity.observations);

        setActivity({ ...mappedActivity, observations: cleanedObservations });
    };

    const loadFiles = async () => {
        const activityFiles = await activitiesServices.getActivityFile(`?actividadId.equals=${activityId}`);
        setFiles(activityFiles);
    };

    // TODO: This method should be removed when https://app.clickup.com/t/86bw2qf05 is done
    const clearObservationsData = (observations) => {
        // OUTDATED: at the start of visit records feature, incidents were saved at observations field together with observations
        if (observations?.includes('|')) {
            const index = observations?.indexOf('|');
            const newObservaciones = index !== undefined ? observations?.slice(index + 1) : '';
            return newObservaciones;
        }

        return observations;
    };

    const printActivity = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: () => ` @page { margin: 1.5cm; size: A4; }`,
        documentTitle: `${activity?.subject || 'Actividad'}`
    });

    return (
        <>
            {activityExists ? (
                <Layout>
                    <div className="ActivityDetail" ref={componentRef}>
                        {activity !== null && (
                            <>
                                <header className="ActivityDetail__header">
                                    <div>
                                        <TituloH1 titulo={activity.subject} />
                                    </div>

                                    <div className="ActivityDetail__actions">
                                        <SecondaryButton
                                            action={printActivity}
                                            type="button"
                                            titulo={t('print')}
                                            icon={<IconPrinter size={24} />}
                                            iconPosition="left"
                                            // Just to ignore optional fields
                                            {...({} as any)}
                                        />
                                    </div>
                                </header>
                                <div className="ActivityDetail__contentWrapper">
                                    <ActivityBasicData
                                        activity={activity}
                                        isAdmin={isAdmin}
                                        isSiteChecking={isSiteChecking}
                                    />
                                    {(activity.observations || isStatusReport) && (
                                        <SimpleCard>
                                            {isStatusReport && (
                                                <AlertCard icon={<IconInfoCircle size={24} />}>
                                                    {t('statusReport.pt1')}{' '}
                                                    <Link
                                                        to={`/promotion/${promotionId}/dashboard?dateTo=${activity.date}`}
                                                    >
                                                        {t('statusReport.pt2')}
                                                    </Link>{' '}
                                                    {t('statusReport.pt3')}.
                                                </AlertCard>
                                            )}
                                            <article
                                                className="ActivityDetail__richText"
                                                dangerouslySetInnerHTML={{ __html: activity.observations }}
                                            />
                                        </SimpleCard>
                                    )}
                                    {files.length > 0 && <ActivityFiles files={files} />}
                                    {isSiteChecking && (
                                        <ActivitySiteCheckingSection activityId={parseInt(activityId)} />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </Layout>
            ) : (
                <NotFound />
            )}
        </>
    );
};

export default withAuth(ActivityDetail);
