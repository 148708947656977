import { IconAlertCircle, IconInfoCircle } from '@tabler/icons';
import AlertCard from 'components/BasicComponents/Cards/AlertCard';
import InputText from 'components/BasicComponents/Inputs/InputText';
import { patchTraceabilityE2EDate } from 'features/traceability_e2e/api/patchTraceabilityE2EDate';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { usePromotion } from 'modules/promotion';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import GoBack from '../../../components/BasicComponents/Buttons/GoBack';
import SubmitButton from '../../../components/BasicComponents/Buttons/SubmitButton';
import SimpleCard from '../../../components/BasicComponents/Cards/SimpleCard';
import DragNDrop from '../../../components/BasicComponents/DragAndDrop/DragNDrop';
import InputFecha from '../../../components/BasicComponents/Inputs/InputFecha';
import InputNumber from '../../../components/BasicComponents/Inputs/InputNumber';
import TextArea from '../../../components/BasicComponents/Inputs/TextArea';
import MessageNotComplete from '../../../components/BasicComponents/Messages/MessageNotComplete';
import BasicModal from '../../../components/BasicComponents/Modales/BasicModal';
import CodLerAutocomplete from '../../../components/BasicComponents/Select-Autocomplete/CodLerAutocomplete';
import PromoProGesCodLerAutocomplete from '../../../components/BasicComponents/Select-Autocomplete/PromoProGesCodLerAutocomplete';
import Spinner from '../../../components/BasicComponents/Spinner/Spinner';
import Switch from '../../../components/BasicComponents/Switch/Switch';
import { TituloH1, TituloH2 } from '../../../components/BasicComponents/Titulos/Titulos';
import { withAuth } from '../../../context/AuthProvider';
import Layout from '../../../pages/Layout/Layout';
import { getMultiplesDocuments } from '../../documentation/api/getMultiplesDocuments';
import { traceabilityDocuments } from '../../documentation/api/traceabilityDocuments';
import { DI_TYPE_ID } from '../../documentation/constants/documentation.constants';
import TraceabilityE2EStatusAutocomplete from '../../traceability_e2e/components/TraceabilityE2EStatusAutocomplete';
import { ANALYZED_TEMPLATES_IDS } from '../helpers/automaticReading.constants';
import {
    INITIAL_STATE_NEW_TRACEABILITY,
    fetchCreateOrEditTraceability,
    fetchDeleteDocument,
    getPpgcl,
    getTraceabilityCost,
    newDi,
    searchTraceabilityToEdit,
    setInitial,
    validationTraceabilityForm
} from '../helpers/newEditTracability.helpers';
import './NewTraceability.scss';

const NewEditTraceabilitPage = (props) => {
    const [t] = useTranslation();
    const history = useHistory();
    const [{ promotion }] = usePromotion();
    const [, analytics] = useAnalyticsStore();
    const { isCoCircular } = props;

    const [traceabilityToEdit, setTraceabilityToEdit] = useState();
    const [editingMode, setEditingMode] = useState(false);
    const [values, setValues] = useState(INITIAL_STATE_NEW_TRACEABILITY);
    const [loading, setLoading] = useState(false);
    const [notFormValid, setNotFormValid] = useState(false);
    const [maintainData, setMaintainData] = useState({
        wasteManager: false,
        shipper: false,
        volum: false,
        ler: false
    });

    const [files, setFiles] = useState([]);
    const [messageNotComplete, setMessageNotComplete] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [oldDi, setOldDi] = useState();

    // When 'PENDING', only date should be editable.
    const onlyDateEditable =
        traceabilityToEdit?.traceabilityStatus === 'PENDING' && props.user.authorities.includes('ROLE_WRITER');

    const checkData = () => {
        if (
            traceabilityToEdit?.gestora !== values?.gestora ||
            traceabilityToEdit?.codLer !== values?.codLer ||
            traceabilityToEdit?.transportista !== values?.transportista
        ) {
            setDataPpgcl();
        }
    };

    useEffect(() => {
        const abortController = new AbortController();
        if (props.match.params?.id) {
            setEditingMode(true);
            setStateTraceabilityToEdit();
        } else {
            setValues(INITIAL_STATE_NEW_TRACEABILITY);
        }
        return () => abortController.abort();
    }, [props.promocion?.id]);

    useEffect(() => {
        if (values?.gestora && values?.codLer && values?.transportista) {
            if (props?.match.params?.id) {
                checkData();
            } else {
                setDataPpgcl();
            }
        }
        if (!values?.gestora || !values?.codLer || !values.transportista) {
            setValues({ ...values, tratamiento: '' });
        }
    }, [values.gestora, values.codLer, values.transportista]);

    useEffect(() => {
        if (values?.pesoGestionado !== '' && !editingMode && values?.promoProGesCodeLer?.cost?.ton !== undefined) {
            setValues({
                ...values,
                costeLER: getTraceabilityCost(
                    values?.pesoGestionado,
                    values?.promoProGesCodeLer?.cost?.unit,
                    values?.promoProGesCodeLer?.cost?.ton
                )
            });
        }
    }, [values?.pesoGestionado]);

    const resetData = (newTraceability) => {
        if (newTraceability !== undefined) {
            setValues(setInitial({ data: newTraceability.data, maintainData }));
            return newTraceability;
        }
    };

    const createOrEditTraceability = async () => {
        const newTraceability = await fetchCreateOrEditTraceability(
            { ...values, costeLER: promotion?.permissions?.costs ? values.costeLER : null },
            props.match.params?.id
        );
        if (!files[0]?.id && files?.length > 0) {
            await newDi({
                files,
                values,
                traceability: newTraceability.data,
                promoId: props.promocion?.id
            });
            setFiles([]);
        }
        if (oldDi?.id) {
            fetchDeleteDocument(oldDi, files);
        }
        setSubmitting(false);
        setLoading(false);

        if (editingMode) {
            setTimeout(() => {
                goBack();
            }, 2000);
        } else {
            resetData(newTraceability);
            analytics.event(AnalyticsEvents.TRACEABILITY_CREATED_MANUALLY);
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const validationForm = validateForm();
        if (validationForm) {
            setSubmitting(true);
            setLoading(true);

            if (onlyDateEditable) {
                await patchTraceabilityE2EDate(props.match.params?.id, values?.fechaMovimiento);
                setSubmitting(false);
                setLoading(false);
                setTimeout(goBack, 2000);

                return;
            }

            if (!values?.end2End && !files?.length > 0 && !files?.some((document) => document.type.id === DI_TYPE_ID)) {
                setOpenModal(true);
            } else {
                createOrEditTraceability();
            }
        }
    };
    const handleInputChange = ({ target }) => {
        setValues({
            ...values,
            [target.name]: target.value
        });
    };

    const validateForm = () => {
        const isFormValid = validationTraceabilityForm(values, submitting);
        if (isFormValid) {
            setNotFormValid(false);
            setMessageNotComplete('');
        } else {
            setNotFormValid(true);
            setMessageNotComplete(t('complete', { ns: 'common' }));
        }
        // if we require numDi (if they don't upload a file, we won't require num DI)
        if (checkIfAnalyzedWasteManager() && files?.length > 0 && values?.numDi === '') {
            setNotFormValid(true);
            setMessageNotComplete(t('complete', { ns: 'common' }));
        }

        return isFormValid;
    };

    const setDataPpgcl = async () => {
        const promoPro = await getPpgcl({
            promotionId: props.promocion.id,
            codLer: values?.codLer.codigoLER,
            wasteManagerId: values?.gestora.id,
            carrierId: values.transportista.id
        });
        if (editingMode) {
            setValues({
                ...values,
                tratamiento: promoPro.wasteManagerProGesCodLer.procesoGestora.valorizacion,
                promoProGesCodeLer: promoPro
            });
        } else {
            setValues({
                ...values,
                tratamiento: promoPro.wasteManagerProGesCodLer.procesoGestora.valorizacion,
                porcentajeValorizacion: promoPro.wasteManagerProGesCodLer.actualValorizationPercentage,
                costeLER: getTraceabilityCost(values?.pesoGestionado, promoPro?.cost?.unit, promoPro?.cost?.ton),
                volumenGestionado: getVolume(promoPro),
                promoProGesCodeLer: promoPro
            });
        }
    };

    const getVolume = (promoPro) => {
        if (maintainData.volum && values.volumenGestionado !== null && values.volumenGestionado !== '') {
            return values.volumenGestionado;
        }

        return promoPro?.volumenUnitario || '';
    };

    //edit traceability
    const getDocumentsTraceabilityToEdit = async () => {
        const documents = await traceabilityDocuments(props.match.params.id);
        if (documents?.data?.length > 0) {
            const idsDocuments = documents?.data?.map((documento) => documento.documentId);
            const allDocuments = await getMultiplesDocuments(props.promocion?.id, idsDocuments);

            if (allDocuments?.data?.code == 0) {
                const docsData = allDocuments?.data?.content?.documents;
                const diDoc = docsData.find((doc) => doc.type.id === DI_TYPE_ID);
                const arrayFilesAux = diDoc !== undefined ? [diDoc] : [];

                setOldDi(diDoc);
                setFiles(arrayFilesAux);

                return docsData;
            }
        }
        return [];
    };

    const setStateTraceabilityToEdit = async () => {
        setLoading(true);
        const traceabilityToEdit = await searchTraceabilityToEdit(props.match.params.id);
        const documentsTraceabilityToEdit = await getDocumentsTraceabilityToEdit();
        const createObj = {
            ...traceabilityToEdit,
            gestora: traceabilityToEdit.promoProGesCodeLer.wasteManagerProGesCodLer.procesoGestora.gestora,
            codLer: traceabilityToEdit.promoProGesCodeLer.wasteManagerProGesCodLer.codLER,
            transportista: traceabilityToEdit.promoProGesCodeLer.carrierProGesCodLer?.procesoGestora?.gestora,
            documentoPromos: documentsTraceabilityToEdit
        };
        setValues(createObj);
        setTraceabilityToEdit({ ...createObj });
        setLoading(false);
    };

    const goBack = () => {
        history.goBack();
    };

    const getNonAccreditedPgcls = () => {
        const nonAccreditedPgcls = [];
        const selectedPpgcl = values.promoProGesCodeLer;
        const pgclTypes = ['wasteManagerProGesCodLer', 'carrierProGesCodLer'];

        if (selectedPpgcl !== undefined && selectedPpgcl !== null) {
            pgclTypes.forEach((pgclType) => {
                if (
                    selectedPpgcl[pgclType]?.accreditedFlow !== undefined &&
                    selectedPpgcl[pgclType]?.accreditedFlow !== null &&
                    selectedPpgcl[pgclType]?.accreditedFlow === false
                ) {
                    nonAccreditedPgcls.push(pgclType);
                }
            });
        }
        return nonAccreditedPgcls;
    };

    const handleTraceabilityStatusChange = (e) => {
        const { checked } = e.target;
        setValues({
            ...values,
            traceabilityStatus: checked ? 'WARNING' : 'COMPLETED'
        });
    };

    const handleVolumeCheckboxChange = (e) => {
        const { checked } = e.target;
        setValues({
            ...values,
            volumenGestionado: checked ? null : ''
        });
    };

    const removeDocument = () => {
        setValues({ ...values, documentoPromos: values.documentoPromos.filter((doc) => doc.id !== files[0]?.id) });
        setFiles([]);
    };

    const onCloseModal = () => {
        setSubmitting(false);
        setLoading(false);
    };

    const checkIfAnalyzedWasteManager = () => {
        if (values?.gestora?.id !== undefined && ANALYZED_TEMPLATES_IDS.includes(values?.gestora?.id)) {
            return true;
        }
        return false;
    };

    const title =
        (editingMode &&
            traceabilityToEdit?.traceabilityStatus === 'PENDING' &&
            t('requestDi.title', { ns: 'traceability' })) ||
        (editingMode && t('edit.title', { ns: 'traceability' })) ||
        t('new', { ns: 'traceability' });

    return (
        <Layout>
            <GoBack goBack={goBack} />
            <TituloH1 titulo={title} />
            <SimpleCard className="newTraceability">
                {onlyDateEditable && (
                    <AlertCard icon={<IconAlertCircle />}>
                        <Trans
                            i18nKey="onlyDateEditableMsg"
                            ns="traceability"
                            values={{ wasteManager: values?.gestora?.empresa || '' }}
                            components={{ bold: <strong />, p: <p /> }}
                        />
                    </AlertCard>
                )}
                {!editingMode && (
                    <div className="newTraceability__switchContainer">
                        <Switch
                            label={t('maintain.lerCode', { ns: 'traceability' })}
                            checked={maintainData.ler}
                            onChange={() => setMaintainData({ ...maintainData, ler: !maintainData.ler })}
                        />
                        <Switch
                            label={t('maintain.wasteManager', { ns: 'traceability' })}
                            checked={maintainData.wasteManager}
                            onChange={() =>
                                setMaintainData({ ...maintainData, wasteManager: !maintainData.wasteManager })
                            }
                        />
                        <Switch
                            label={t('maintain.transporter', { ns: 'traceability' })}
                            checked={maintainData.shipper}
                            onChange={() => setMaintainData({ ...maintainData, shipper: !maintainData.shipper })}
                        />
                        <Switch
                            label={t('maintain.volume', { ns: 'traceability' })}
                            checked={maintainData.volum}
                            onChange={() => setMaintainData({ ...maintainData, volum: !maintainData.volum })}
                        />
                    </div>
                )}
                <form onSubmit={handleFormSubmit} className="newTraceability__form">
                    <TituloH2 titulo={t('information.container', { ns: 'traceability' })} />
                    <div className="newTraceability__row">
                        <CodLerAutocomplete
                            className="newTraceability__input newTraceability__input-medium"
                            {...{ values, setValues }}
                            value={values?.codLer}
                            required
                            validacion={notFormValid}
                            disabled={onlyDateEditable}
                        />
                        <PromoProGesCodLerAutocomplete
                            className="newTraceability__input newTraceability__input-large"
                            {...{ values, setValues }}
                            value={values?.gestora}
                            name="gestora"
                            required
                            validacion={notFormValid}
                            preLoadOptions={true}
                            requiredFieldsToPreLoadOptions={['codLer', 'transportista']}
                            disabled={onlyDateEditable}
                        />
                        <PromoProGesCodLerAutocomplete
                            className="newTraceability__input newTraceability__input-large"
                            {...{ values, setValues }}
                            value={values?.transportista}
                            typeOfWasteManager="CARRIER"
                            name="transportista"
                            required
                            validacion={notFormValid}
                            preLoadOptions={true}
                            requiredFieldsToPreLoadOptions={['codLer', 'gestora']}
                            disabled={onlyDateEditable}
                        />
                    </div>

                    {values?.porcentajeValorizacion !== '' && values?.tratamiento && (
                        <div className="newTraceability__ppgclInfo">
                            <p>
                                {/* TODO: Unknown valorization percent should change to "null" */}
                                {values?.porcentajeValorizacion !== 1
                                    ? `${values?.porcentajeValorizacion}% de valorización`
                                    : '% de valorización desconocido'}
                            </p>

                            <p>
                                Tratamiento {values?.tratamiento?.codigoValorizacion}
                                <span
                                    data-for="tooltip"
                                    data-tip={values?.tratamiento?.descripcion}
                                    data-offset="{'top': -10}"
                                >
                                    <IconInfoCircle stroke={2} size={22} />
                                    <ReactTooltip
                                        id="tooltip"
                                        delayHide={100}
                                        effect="solid"
                                        multiline={true}
                                        className="newTraceability__tooltip"
                                        event="click"
                                        globalEventOff={'click'}
                                    />
                                </span>
                            </p>
                        </div>
                    )}

                    {getNonAccreditedPgcls().length > 0 && (
                        <p className="ppgcl__notAccredited">
                            <IconAlertCircle stroke={2} size={16} />
                            <p>
                                {getNonAccreditedPgcls().map((wrongPgcl, i) => (
                                    <span key={i}>
                                        {t(`noAccreditedFlow.newTraceability.${wrongPgcl}`, { ns: 'ppgcl' })}{' '}
                                        {values?.codLer?.codigoLER}
                                    </span>
                                ))}
                            </p>
                        </p>
                    )}

                    <div className="newTraceability__row">
                        <InputFecha
                            inputContainer="newTraceability__input newTraceability__input-medium"
                            label={t('date.date', { ns: 'common' })}
                            id="fecha-nueva-trazabilidad"
                            name="fechaMovimiento"
                            value={values?.fechaMovimiento}
                            onChange={handleInputChange}
                            validacion={notFormValid}
                            notLimit={true}
                            required
                        />
                        <InputNumber
                            className="newTraceability__input newTraceability__input-small"
                            label={t('weight.upperCase', { ns: 'common' }) + ' (T)'}
                            id="tn-gestionadas-nueva-traz"
                            name="pesoGestionado"
                            value={values?.pesoGestionado}
                            onChange={handleInputChange}
                            showThousandSeparator={false}
                            validacion={notFormValid}
                            required
                            maximumFractionDigits={3}
                            disabled={onlyDateEditable}
                        />
                        {promotion?.permissions?.costs && (
                            <div className="newTraceability__field  newTraceability__cost">
                                <InputNumber
                                    className="newTraceability__input newTraceability__input-small"
                                    label={t('cost.upperCase', { ns: 'costs' }) + ' (€)'}
                                    id="coste-nueva-traz"
                                    name="costeLER"
                                    value={values?.costeLER}
                                    onChange={handleInputChange}
                                    min="0"
                                    disabled={onlyDateEditable}
                                />
                                <p>
                                    {values?.promoProGesCodeLer?.cost?.unit !== undefined &&
                                        values?.promoProGesCodeLer?.cost?.unit !== null && (
                                            <p>Coste unitario: {values?.promoProGesCodeLer?.cost?.unit}€</p>
                                        )}
                                    {values?.promoProGesCodeLer?.cost?.ton !== undefined &&
                                        values?.promoProGesCodeLer?.cost?.ton !== null && (
                                            <p>Coste tonelada: {values?.promoProGesCodeLer?.cost?.ton}€</p>
                                        )}
                                </p>
                            </div>
                        )}

                        <div className="newTraceability__field">
                            <InputNumber
                                className={`newTraceability__input newTraceability__input-small ${
                                    values?.volumenGestionado === null && 'newTraceability__hideInput'
                                }`}
                                label={t('volume.upperCase', { ns: 'traceability' }) + ' (m³)'}
                                id="volumen-nueva-traz"
                                name="volumenGestionado"
                                value={values?.volumenGestionado}
                                onChange={handleInputChange}
                                min="0"
                                validacion={notFormValid}
                                required
                                disabled={onlyDateEditable}
                            />
                            <div className="newTraceability__checkboxUnknown">
                                <label>
                                    <input
                                        type="checkbox"
                                        className="checkbox"
                                        checked={values?.volumenGestionado === null}
                                        onChange={(e) => handleVolumeCheckboxChange(e)}
                                        disabled={onlyDateEditable}
                                    />
                                    <span>{t('volume.unknown.short', { ns: 'traceability' })}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <TituloH2 titulo={t('documentation.upperCase', { ns: 'common' })} />

                    {!onlyDateEditable && (
                        <div className="newTraceability__row newTraceability__row-flexStart">
                            <DragNDrop
                                className="newTraceability__dragNDrop"
                                label={t('di.add', { ns: 'traceability' })}
                                maxFiles={1}
                                setFiles={setFiles}
                                files={files}
                                urlProp="url"
                                fileNameProp="name"
                                fileProp="bytes"
                                contentTypeProp="type"
                                removeFunction={removeDocument}
                            />
                            {!values?.end2End && (
                                <div className="newTraceability__statusCheckboxContainer">
                                    <label>
                                        <input
                                            type="checkbox"
                                            className="checkbox"
                                            checked={values?.traceabilityStatus === 'WARNING' ? true : false}
                                            onChange={(e) => handleTraceabilityStatusChange(e)}
                                        />
                                        <span className="newTraceability__statusCheckboxContainer--item">
                                            {t('diNotComplete', { ns: 'traceability' })}
                                        </span>
                                    </label>
                                </div>
                            )}
                        </div>
                    )}

                    <div className="newTraceability__status">
                        {isCoCircular && (
                            <div className="newTraceability__row">
                                <InputText
                                    className="newTraceability__numDi"
                                    label="Num. DI"
                                    id="numDi"
                                    name="numDi"
                                    value={values?.numDi}
                                    onChange={handleInputChange}
                                    validacion={notFormValid}
                                    required={values?.end2End || checkIfAnalyzedWasteManager()}
                                    disabled={onlyDateEditable}
                                />
                                {values?.end2End && (
                                    <TraceabilityE2EStatusAutocomplete
                                        value={values?.traceabilityStatus}
                                        values={values}
                                        setValues={setValues}
                                    />
                                )}
                            </div>
                        )}

                        <TextArea
                            label={t('comments', { ns: 'common' })}
                            id="observaciones-di"
                            name="observation"
                            value={values?.observation}
                            onChange={handleInputChange}
                            inputContainer="input__container"
                            disabled={onlyDateEditable}
                        />
                    </div>

                    <SubmitButton text={t('save', { ns: 'common' })} buttonDisabled={submitting ? true : false} />
                </form>
                {messageNotComplete && <MessageNotComplete message={messageNotComplete} />}
                {loading && <Spinner />}
                {openModal && (
                    <BasicModal
                        {...{ openModal, setOpenModal, onCloseModal }}
                        title="Traslado sin DI"
                        subtitle={t('areYouSure', { ns: 'traceability' })}
                        actionText={t('continue.upperCase', { ns: 'common' })}
                        cancelText={t('cancel', { ns: 'common' })}
                        actionFunction={createOrEditTraceability}
                        refuse={false}
                    />
                )}
            </SimpleCard>
        </Layout>
    );
};
export default withAuth(NewEditTraceabilitPage);
