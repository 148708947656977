import { useState, useEffect } from 'react';

export const useFilter = ({ values, objFilter, page, samePage }) => {
    const [filter, setFilter] = useState('');
    const [filterReady, setFilterReady] = useState(false);

    const createFilterWithObject = () => {
        setFilterReady(false);
        let createFilter = `?page=${page}&sort=num,asc&sort=fechaFin,asc`;
        for (let i in values) {
            if (values[i]) {
                if (objFilter[i] !== undefined) {
                    createFilter += objFilter[i];
                }
            }
        }
        setFilter(createFilter);
        setFilterReady(true);
    };

    useEffect(() => {
        createFilterWithObject();
    }, [page, samePage]);

    return { filter, createFilterWithObject, filterReady };
};
