import { useTranslation } from 'react-i18next';

import { SelectOption } from 'components/BasicComponents/Filter/SelectFilter/SelectFilter';
import { useAsyncOptions } from 'components/BasicComponents/Filter/SelectFilter/hooks/useAsyncOptions';
import SelectAutocomplete from 'components/BasicComponents/Select/SelectAutocomplete';
import { useResetWhenEnterpriseChanges } from './hooks/useResetWhenEnterpriseChanges';
import { WasteManager, findWasteManagers } from './services/findWasteManagers';

export type UserWasteManagerSelectorProps = {
    enterpriseId?: number;
    value?: WasteManager[];
    onChange: (value: UserWasteManagerSelectorProps['value']) => void;
};

const UserWasteManagerSelector = (props: UserWasteManagerSelectorProps) => {
    const { t } = useTranslation();

    const [loadOptions] = useAsyncOptions({
        request: async (params) => {
            if ((params.page || 0) > 0) return [];
            const data = await findWasteManagers({ ...params, enterpriseId: props.enterpriseId || NaN });
            return data;
        },
        adapter: (wm) => optionAdapter(wm)
    });

    const disabled = !props.enterpriseId;

    useResetWhenEnterpriseChanges(props);

    return (
        <div key={props.enterpriseId}>
            <SelectAutocomplete
                label={t('wasteManagerCenters')}
                disabled={!props.enterpriseId}
                style={{ opacity: disabled ? 0.5 : 1 }}
                value={props.value?.map(optionAdapter)}
                loadOptions={loadOptions}
                menuPosition="fixed"
                onChange={(options) => {
                    if (options && Array.isArray(options)) {
                        const wm: WasteManager[] = options.map((o) => {
                            const [name, nima] = (o.label as string).split(' - ');
                            return { id: o.value, name, nima };
                        });
                        props.onChange?.(wm);
                    }
                }}
            />
            {!props.enterpriseId && <small>Debes seleccionar primero una empresa para el usuario</small>}
        </div>
    );
};

const optionAdapter = (wm: WasteManager): SelectOption => {
    const label = wm.nima ? `${wm.name} - ${wm.nima}` : wm.name;
    return { value: wm.id, label };
};

export default UserWasteManagerSelector;
