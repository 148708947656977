import { IconChartBar, IconChevronDown, IconTable } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import Card from 'components/BasicComponents/Cards/SimpleCard';
import DropdownList from 'components/BasicComponents/DropdownList';
import SegmentedControl from 'components/BasicComponents/SegmentedControl/SegmentedControl';
import useStateFromStorage from 'hooks/useStateFromStorage';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { usePromotion } from 'modules/promotion';
import {
    TraceabilitiesSummaryMeasures,
    TraceabilitiesSummaryMode,
    TraceabilitiesSummaryUnits
} from 'modules/traceabilities/domain/TraceabilitiesSummary';
import './TraceabilitiesSummary.scss';
import TraceabilitiesSummaryChart from './components/TraceabilitiesSummaryChart';
import TraceabilitiesSummaryTable from './components/TraceabilitiesSummaryTable';
import { useSummaryFilters } from './hooks/useSummaryFilters';

const TraceabilitiesSummary = () => {
    const [t] = useTranslation('traceabilities');
    const { filters } = useSummaryFilters();
    const [{ promotion }] = usePromotion();
    const [, analytics] = useAnalyticsStore();

    const axisYOptions = promotion?.permissions.costs ? ['movements', 'tons', 'costs'] : ['movements', 'tons'];

    const [unit, setUnit] = useStateFromStorage({
        key: 'TraceabilitiesSummary--unit',
        initialState: TraceabilitiesSummaryUnits.movements
    });

    const [measure, setMeasure] = useStateFromStorage({
        key: 'TraceabilitiesSummary--measure',
        initialState: TraceabilitiesSummaryMeasures.lerType
    });

    const [mode, setMode] = useStateFromStorage({
        key: 'TraceabilitiesSummary--mode',
        initialState: TraceabilitiesSummaryMode.byDate
    });

    const [show, setShow] = useStateFromStorage({
        key: 'TraceabilitiesSummary--show',
        initialState: true
    });

    const modifiers = [[show ? 'TraceabilitiesSummary--show' : 'TraceabilitiesSummary--hide']];

    return (
        <div className={`TraceabilitiesSummary ${modifiers.join(' ')}`}>
            <Card dataTestid={'TraceabilitiesSummary' as any}>
                <header className="TraceabilitiesSummary__header">
                    {show && (
                        <>
                            <SegmentedControl
                                className="TraceabilitiesSummary__switches"
                                onChange={(v) => setMode(v as TraceabilitiesSummaryMode)}
                                value={mode}
                                options={[
                                    {
                                        id: TraceabilitiesSummaryMode.byDate,
                                        label: t('summary.chart'),
                                        content: <IconChartBar size={20} stroke={1.6} />
                                    },
                                    {
                                        id: TraceabilitiesSummaryMode.totals,
                                        label: t('summary.table'),
                                        content: <IconTable size={20} stroke={1.6} />
                                    }
                                ]}
                            />
                            {mode === 'by-date' && (
                                <DropdownList
                                    items={axisYOptions.map((unitOption, i) => (
                                        <li
                                            role="button"
                                            key={i}
                                            className="TraceabilitiesSummary__dropdownItem"
                                            onClick={() => {
                                                setUnit(TraceabilitiesSummaryUnits[unitOption]);
                                            }}
                                        >
                                            {t(`summary.axisY.${unitOption}`)}
                                        </li>
                                    ))}
                                >
                                    <div
                                        onClick={() =>
                                            analytics.event(AnalyticsEvents.DROPDOWN_UNIT_TRACEABILITY_SUMMARY_CLICKED)
                                        }
                                    >
                                        <SegmentedControl
                                            className="TraceabilitiesSummary__unitSelector"
                                            options={[
                                                {
                                                    id: unit,
                                                    content: (
                                                        <>
                                                            <IconChevronDown />
                                                            Ver {t(`summary.axisY.${unit}`, { format: 'lowercase' })}
                                                        </>
                                                    ),
                                                    label: ''
                                                }
                                            ]}
                                        />
                                    </div>
                                </DropdownList>
                            )}
                            <SegmentedControl
                                className="TraceabilitiesSummary__switches TraceabilitiesSummary__switches--measure"
                                onChange={(v) => setMeasure(v as TraceabilitiesSummaryMeasures)}
                                value={measure}
                                options={Object.entries(TraceabilitiesSummaryMeasures).map(([label, value]) => ({
                                    id: value,
                                    label: t(`summary.${label}`, { ns: 'traceabilities' })
                                }))}
                            />
                        </>
                    )}
                    <button className="TraceabilitiesSummary__button" onClick={() => setShow(!show)}>
                        {t(show ? 'hideSummary' : 'showSummary', { ns: 'traceabilities' })}
                        <IconChevronDown size={18} />
                    </button>
                </header>
                <div className="TraceabilitiesSummary__summary">
                    {show && mode === TraceabilitiesSummaryMode.byDate && (
                        <TraceabilitiesSummaryChart filters={filters} measure={measure} unit={unit} />
                    )}
                    {show && mode === TraceabilitiesSummaryMode.totals && (
                        <TraceabilitiesSummaryTable filters={filters} measure={measure} />
                    )}
                </div>
            </Card>
        </div>
    );
};

export default TraceabilitiesSummary;
