import { Thunk } from 'modules/shared/domain/store/createStore';
import { entitiesAdapter } from 'modules/shared/domain/utils/entitiesAdapter';
import { WasteRegisterCriteria } from '../../domain/WasteRegisterCriteria';
import { WasteRegisterStore } from '../../domain/WasteRegisterStore';

type Store = WasteRegisterStore;

export const loadWasteRegisters =
    (criteria?: WasteRegisterCriteria): Thunk<Store> =>
    async (store, { api }) => {
        try {
            const newCriteria: WasteRegisterCriteria = {
                ...store.state.criteria,
                ...(criteria || {})
            };

            store.set((draft) => {
                draft.criteria = newCriteria;

                draft.loading = 'pending';
                draft.error = null;
            });

            const response = await api.findByCriteria(newCriteria);

            const { wasteRegisters, total } = response;

            store.set((draft) => {
                draft.criteria = newCriteria;

                draft.data = entitiesAdapter(wasteRegisters, 'uuid');
                draft.total = total;

                draft.loading = 'succeeded';
                draft.error = null;
            });
        } catch (e) {
            const error = e as Error;
            console.error(error);

            store.set((state) => {
                state.error = error;
                state.loading = 'failed';
            });
        }
    };
