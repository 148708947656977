import { IconFileDownload } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import EmptyState from 'components/BasicComponents/EmptyState/EmptyState';
import fromEntities from 'lib/fromEntities';
import { useTraceabilities } from 'modules/traceabilities';

import TraceabilityRow from './table/TraceabilityRow';
import './TraceabilitiesTable.scss';

const TraceabilitiesTable = () => {
    const [t] = useTranslation('traceabilities');
    const [{ loading, data, total, criteria }, { loadTraceabilities, downloadTraceabilities }] = useTraceabilities();

    const traceabilities = fromEntities(data);

    const generateRowsSkeleton = (numRows = 20) => {
        const skeletons = Array.from({ length: numRows }).map((_, index) => <TraceabilityRow key={index} />);
        return skeletons;
    };

    const loadMore = () => {
        loadTraceabilities({ page: (criteria?.page || 0) + 1 }, { mode: 'merge' });
    };

    const dataLength = data.allIds.length;
    const modifiers = [loading.list === 'pending' ? 'TraceabilitiesTable--is-loading' : ''];

    if (loading.list === 'pending' && dataLength === 0) {
        return (
            <>
                <div className="TraceabilitiesTable__results">&nbsp;</div>
                <table className="TraceabilitiesTable">
                    <tbody>{generateRowsSkeleton(20)}</tbody>
                </table>
            </>
        );
    }

    if (loading.list === 'succeeded' && dataLength === 0) {
        return <EmptyState mode="notFound" />;
    }

    return (
        <>
            <header className="TraceabilitiesTable__header" data-testid="TraceabilitiesTable__header">
                <div className="TraceabilitiesTable__results">
                    {total} {t(total === 1 ? 'foundTraceability' : 'foundTraceabilities')}
                </div>
                <SmallButton
                    testid="TraceabilitiesTable__exportToCsv"
                    className={`button--link TraceabilitiesTable__download ${
                        loading.download === 'pending' ? 'TraceabilitiesTable__download--loading' : ''
                    }`}
                    action={downloadTraceabilities}
                    titulo={t('exportToCsv')}
                    icon={<IconFileDownload />}
                    iconPosition="left"
                    // Just to ignore optional fields
                    {...({} as any)}
                />
            </header>
            <InfiniteScroll
                dataLength={dataLength}
                next={loadMore}
                hasMore={total > dataLength && loading.list !== 'deleteing'}
                loader={
                    <table className="TraceabilitiesTable">
                        <tbody>{generateRowsSkeleton(5)}</tbody>
                    </table>
                }
                scrollableTarget="main-layout"
                style={{ overflow: 'unset' }}
            >
                <table className={`TraceabilitiesTable ${modifiers.join(' ')}`}>
                    <tbody>
                        {traceabilities.map((traceability) => (
                            <TraceabilityRow key={traceability.id} traceability={traceability} />
                        ))}
                    </tbody>
                </table>
            </InfiniteScroll>
        </>
    );
};

export default TraceabilitiesTable;
