import fetcher from 'lib/fetcher';
import { PromotionKPIs } from 'modules/promotion-kpis/domain/PromotionKPIs';
import { MandatoryFlowsDetails } from 'modules/promotion-kpis/domain/mandatory-flows/MandatoryFlowsDetails';
import { MandatoryFlowsSummary } from 'modules/promotion-kpis/domain/mandatory-flows/MandatoryFlowsSummary';
import { PromotionKPIsApi } from '../../domain/PromotionKPIsApi';
import { InvalidContentException, NoContentException } from '../../domain/PromotionKPIsExceptions';
import { PromotionKPIsGoal } from '../../domain/goals/PromotionKPIsGoal';
import { calcMandatoryFlowsSort } from '../../domain/mandatory-flows/calcMandatoryFlowsSort';

export const httpPromotionKPIsApi: PromotionKPIsApi = {
    // Valorization
    getValorizationSummary: async ({ promotionId }) => {
        const { data } = await fetcher(`/api/promotion/${promotionId}/metrics/kpi/valorization/summary`);
        return data.content;
    },
    getValorizationByScope: async ({ promotionId, scope }) => {
        const scopeUrlSegment =
            (typeof scope === 'string' && scope) ||
            (typeof scope === 'object' && scope.hasOwnProperty('lerTypeId') && `lerType/${scope.lerTypeId}`) ||
            '';

        const { data } = await fetcher(`api/promotion/${promotionId}/metrics/kpi/valorization/${scopeUrlSegment}`);
        return data.content;
    },

    // Mix
    getMixSummary: async ({ promotionId }) => {
        const { data } = await fetcher(`/api/promotion/${promotionId}/metrics/kpi/mix/summary`);
        return data.content;
    },
    getMixByScope: async ({ promotionId, scope }) => {
        const scopeUrlSegment =
            (typeof scope === 'string' && scope) ||
            (typeof scope === 'object' && scope.hasOwnProperty('lerTypeId') && `lerTypeId/${scope.lerTypeId}`) ||
            '';

        const { data } = await fetcher(`api/promotion/${promotionId}/metrics/kpi/mix/${scopeUrlSegment}`);
        return data.content;
    },

    // Mandatory flows
    getMandatoryFlowsSummary: async ({ promotionId }) => {
        const { data } = await fetcher(`/api/promotion/${promotionId}/metrics/kpi/mandatory-flows/summary`);
        const summary: MandatoryFlowsSummary = data.content;

        summary.lerTypes.sort(calcMandatoryFlowsSort);

        return data.content;
    },
    getMandatoryFlowsDetails: async ({ promotionId }) => {
        const { data } = await fetcher(`/api/promotion/${promotionId}/metrics/kpi/mandatory-flows/details`);
        const details: MandatoryFlowsDetails = data.content;

        details.lerTypes.sort(calcMandatoryFlowsSort);

        return details;
    },

    // Certified traceabilities
    getCertifiedTraceabilities: async ({ promotionId }) => {
        const { data } = await fetcher(`/api/promotion/${promotionId}/metrics/kpi/certified-traceabilities`);
        const certifiedTraceabilities = data.content;

        if (!certifiedTraceabilities) throw new NoContentException();
        if (certifiedTraceabilities.data > certifiedTraceabilities.goal) throw new InvalidContentException();

        return data.content;
    },

    // Goals
    getGoals: async (criteria) => {
        const { promotionId, kpi } = criteria;
        const params = { page: 0, size: 999 };

        const { data } = await fetcher(`/api/promotion/${promotionId}/goals/${kpi}`, { params });

        const goals: PromotionKPIsGoal[] = data.content;
        const goalsSorted = goals.sort((a, b) => {
            // 1. Sort by scope
            if (a.scope.name !== b.scope.name) return b.scope.name.localeCompare(a.scope.name);
            // 2. If scope is the same, sort by severity
            if (a.severity !== b.severity) return (a.severity || 0) - (b.severity || 0);
            // 3. If scope and severity are the same, sort by progress
            if (a.progress !== b.progress) return a.progress - b.progress;
            // 4. If scope, progress and severity are the same, sort by value
            return a.value.value - b.value.value;
        });

        return goalsSorted;
    },
    getGoalScopes: async (criteria) => {
        const { kpi, ...params } = criteria;
        const { data } = await fetcher(`api/goals/${kpi}/scopes`);

        // Move pagination to back
        if (params.page && params.page > 0) return [];

        // Move search to back
        const scopes = data.content.filter((scope) => scope.name.toLowerCase().includes(params.search?.toLowerCase()));

        return scopes;
    },
    saveGoal: async (params) => {
        const { promotionId, kpi, goal } = params;

        // Just for clean the object of unnecessary fields for the backend
        const body = structuredClone(goal) as any;
        delete body.uuid;
        delete body.scope.value.name;

        await fetcher.put(`/api/promotion/${promotionId}/goals/${kpi}/${goal.uuid}`, body);
    },
    removeGoal: async ({ goalId, promotionId }) => {
        await fetcher.delete(`/api/promotion/${promotionId}/goals/${goalId}`);
    },
    getAllGoals: async ({ promotionId }) => {
        const kpis = Object.values(PromotionKPIs);

        const requests = kpis.map((kpi) =>
            httpPromotionKPIsApi.getGoals({ promotionId, kpi }).then((goals) => goals.map((goal) => ({ ...goal, kpi })))
        );

        type Results = Awaited<typeof requests[0]>;

        const goals = await Promise.allSettled(requests)
            .then((res) => res.filter(({ status }) => status === 'fulfilled'))
            .then((res) => res.map((res) => (res as PromiseFulfilledResult<Results>).value))
            .then((res) => res.flat());

        return goals;
    }
};
