import promotionServices from 'api/promotion/promotion.services';
import { AUTH_INITIAL_STATE } from 'modules/auth/domain/AuthConfig';

export class AuthAdapter {
    public static adaptFromAccount = async (account) => {
        if (account && account.activated === true && !this.notNeedPromotion(account)) {
            return {
                isLoggedin: true,
                isLoading: false,
                user: account,
                authorities: account.authorities,
                isCoCircular: this.esDeCoCircular(account.authorities),
                isWasteStakeholder: this.isCarrierOrDumpmaster(account.authorities),
                pathRedirect: this.getDefaultPathRedirect(account.authorities),
                promocion: await this.cargarPromocionCustomer(account)
            };
        } else if (account.activated === true && account) {
            return {
                isLoggedin: true,
                isLoading: false,
                message: null,
                user: account,
                authorities: account.authorities,
                isCoCircular: this.esDeCoCircular(account.authorities),
                isWasteStakeholder: this.isCarrierOrDumpmaster(account.authorities),
                pathRedirect: this.getDefaultPathRedirect(account.authorities)
            };
        } else {
            return AUTH_INITIAL_STATE;
        }
    };

    private static esDeCoCircular = (authorities) => {
        return authorities.includes('ROLE_ADMIN');
    };

    private static isCarrierOrDumpmaster = (authorities) => {
        return authorities.includes('ROLE_DUMPMASTER');
    };

    private static getDefaultPathRedirect = (authorities) => {
        let defaultRedirect = '/home';

        if (authorities?.includes('ROLE_DUMPMASTER')) {
            defaultRedirect = '/waste-manager-traceability';
        }

        return defaultRedirect;
    };

    private static notNeedPromotion = (account) => {
        const arr = ['ROLE_ADMIN', 'ROLE_DUMPMASTER'];
        const intersections = account.authorities.filter((e) => arr.indexOf(e) !== -1);
        return intersections.length === 0 ? false : true;
    };

    private static cargarPromocionCustomer = async (account) => {
        const getPromocion = await promotionServices.getUserPromotions(account.id);
        if (!getPromocion) return;
        sessionStorage.setItem('promocion', JSON.stringify(getPromocion[0]));
        return getPromocion[0];
    };
}
