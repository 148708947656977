import { IconStar, IconBiohazard, IconChartArea } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

const TypeWaste = ({ type, tooltip = null, showTooltip = false, showIcon = true, showText = true, iconSize = 22 }) => {
    // default = valuable material
    const [t] = useTranslation();
    const [typeWasteData, setTypeWasteData] = useState({
        text: t('waste.nonDangerous', { ns: 'common' }),
        icon: <IconStar size={iconSize} stroke={1.5} />
    });

    useEffect(() => {
        calculateTypeWasteData();
    }, [type]);

    const calculateTypeWasteData = () => {
        if (type === undefined) {
            return null;
        }

        let typeWasteDataAux = {};

        switch (type) {
            case 'valuableMaterial':
            case 'nonDangerous':
                typeWasteDataAux['text'] = t('waste.nonDangerous', { ns: 'common' });
                typeWasteDataAux['icon'] = <IconStar size={iconSize} stroke={1.5} />;
                break;

            case 'dangerous':
                typeWasteDataAux['text'] = t('waste.dangerous', { ns: 'common' });
                typeWasteDataAux['icon'] = <IconBiohazard size={iconSize} stroke={1.5} />;
                break;

            case 'land':
                typeWasteDataAux['text'] = t('waste.land', { ns: 'common' });
                typeWasteDataAux['icon'] = <IconChartArea size={iconSize} stroke={1.5} />;
                break;

            default:
                break;
        }

        setTypeWasteData(typeWasteDataAux);
    };

    const getTooltip = () => {
        if (!showTooltip) {
            return null;
        }

        return tooltip || typeWasteData.text || '';
    };

    return (
        <span className={`typeWaste`} data-for="tooltip" data-tip={getTooltip()} data-offset="{'top': -10}">
            {showIcon && typeWasteData.icon}
            {showTooltip && <ReactTooltip id="tooltip" delayHide={100} effect="solid" multiline={true} />}
            {showText && typeWasteData.text}
        </span>
    );
};

export default TypeWaste;
