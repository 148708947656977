import promoProGesCodLersServices from '../../../api/promoProGesCodLers/promoProGesCodLers.services';
import { allFieldsComplete } from '../../../utils/helpers/general.helpers';
import { associateTraceabilities } from '../../associate_documents_traceabilities/api/associateTraceability';
import { createDocument } from '../../documentation/api/createDocument';
import { deleteDocument } from '../../documentation/api/deleteDocument';
import { getDocumentTypeById } from '../../documentation/api/getDocumentTypeById';
import { DI_TYPE_ID } from '../../documentation/constants/documentation.constants';
import { prepareDocsArray } from '../../documentation/helpers/documentation.helpers';
import { docMapperPOST } from '../../documentation/mappers/docMapperPOST';
import { editTraceability } from '../api/editTraceability';
import { newTraceability } from '../api/newTracability';
import { traceabilityById } from '../api/traceabilityById';

export const INITIAL_STATE_NEW_TRACEABILITY = {
    fechaMovimiento: '',
    codLer: '',
    gestora: '',
    traceabilityStatus: 'COMPLETED',
    transportista: null,
    pesoGestionado: '',
    volumenGestionado: '',
    costeLER: '',
    porcentajeValorizacion: '',
    documentoPromos: [],
    observation: '',
    numDi: '',
    end2End: false,
    hasChanged: false
};

const _requiredTraceabilityFields = (values) => {
    const objRequired = {
        fechaMovimiento: values.fechaMovimiento,
        codLer: values.codLer,
        porcentajeValorizacion: values.porcentajeValorizacion,
        volumenGestionado: values.volumenGestionado,
        pesoGestionado: values.pesoGestionado,
        traceabilityStatus: values.traceabilityStatus,
        promoProGesCodeLer: values.promoProGesCodeLer
    };

    // unknown volume checkbox is checked
    if (values.volumenGestionado === null) {
        delete objRequired.volumenGestionado;
    }

    return objRequired;
};
export const validationTraceabilityForm = (values, submitting) => {
    if (allFieldsComplete(_requiredTraceabilityFields(values)) && !submitting) {
        return true;
    } else {
        return false;
    }
};

export const getPpgcl = async ({ promotionId, codLer, wasteManagerId, carrierId }) => {
    const filter = `?promocionId.equals=${promotionId}&codLer.equals=${codLer}&gestoraId.equals=${wasteManagerId}&carrierId.equals=${carrierId}`;
    const promoProGesCodLers = await promoProGesCodLersServices.filterPromoProGesCodLers(filter);
    return promoProGesCodLers[0];
};

export const fetchCreateOrEditTraceability = async (values, idTraceability) => {
    const fetchTraceability =
        idTraceability === undefined ? await newTraceability(values) : await editTraceability(values);
    return fetchTraceability;
};

export const searchTraceabilityToEdit = async (id) => {
    const searchTraceability = await traceabilityById(id);
    const traceabilityToEdit = searchTraceability.traceabilities[0];
    return traceabilityToEdit;
};

const _createObj = (idTraceability, idDocument) => {
    return {
        documentId: idDocument,
        traceabilityIds: [idTraceability]
    };
};

const _mapperPostDi = async ({ files, values, promoId }) => {
    let diDoc = null;
    const diDocTypeRequest = await getDocumentTypeById(DI_TYPE_ID);
    const diDocType = diDocTypeRequest?.data?.[0];
    if (diDocType !== undefined) {
        const docsArray = prepareDocsArray(files);
        diDoc = docMapperPOST({
            promoId,
            docData: { ...values, date: values?.fechaMovimiento },
            type: { id: diDocType?.id, name: diDocType?.descripcion },
            docsArray: docsArray
        });
    }
    return diDoc;
};

export const newDi = async ({ files, values, traceability, promoId }) => {
    const di = await _mapperPostDi({ files, values, promoId });
    const newDi = await createDocument(di);
    if (newDi?.data?.code == 0 || newDi?.status == 204) {
        const objAssociate = _createObj(traceability.id, newDi?.data?.content?.ids[0]);
        await associateTraceabilities(objAssociate);
    } else {
        let traceabilityToEdit = await traceabilityById(traceability.id);
        traceabilityToEdit = {
            ...traceabilityToEdit.data.content.traceabilities[0],
            traceabilityStatus: 'WARNING'
        };
        await editTraceability(traceabilityToEdit);
    }
    return newDi;
};

export const fetchDeleteDocument = async (oldDi, files) => {
    if (oldDi?.id !== files[0]?.id) {
        await deleteDocument(oldDi.id);
    }
};

export const setInitial = ({ data, maintainData }) => {
    let objToSet = INITIAL_STATE_NEW_TRACEABILITY;
    const { wasteManager, shipper, volum, ler } = maintainData;
    if (shipper) {
        objToSet = {
            ...objToSet,
            transportista: data.promoProGesCodeLer.carrierProGesCodLer.procesoGestora.gestora
        };
    }
    if (wasteManager) {
        objToSet = {
            ...objToSet,
            gestora: data.promoProGesCodeLer.wasteManagerProGesCodLer.procesoGestora.gestora
        };
    }
    if (volum) {
        objToSet = { ...objToSet, volumenGestionado: data.volumenGestionado };
    }
    if (ler) {
        objToSet = {
            ...objToSet,
            codLer: data.promoProGesCodeLer.wasteManagerProGesCodLer.codLER
        };
    }

    return objToSet;
};

// 0 by default to avoid undefined/nulls (unitCost always has value, in theory, tho)
export const getTraceabilityCost = (tons = 0, unitCost = 0, tonCost = 0) => {
    return tons * tonCost + unitCost;
};
