import { IconExternalLink, IconX } from '@tabler/icons';
import IconButton from 'components/BasicComponents/Buttons/Small/IconButton';
import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import SegmentedControl from 'components/BasicComponents/SegmentedControl/SegmentedControl';
import { getDocuments } from 'features/documentation/api/getDocuments';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import './TreatmentContractAssociation.scss';
import { deleteLinkedTreatmentContract } from './api/deleteLinkedTreatmentContract';
import { modifyLinkedTreatmentContract } from './api/modifyLinkedTreatmentContract';
interface TreatmentContractAssociationProps {
    ppgcl: any;
    promotionId: number | undefined;
    promotionName: string | undefined;
    resetSelectedPpgcl: Function;
    changePpgcl: ({ ppgclId, message, removeCT }: { ppgclId: number; message: string; removeCT: boolean }) => void;
    changeDisplayDocument: ({ id, url, fileName }: { id: string; url: string; fileName: string }) => void;
}

const TreatmentContractAssociation = ({
    ppgcl,
    promotionId,
    promotionName,
    resetSelectedPpgcl,
    changeDisplayDocument,
    changePpgcl
}: TreatmentContractAssociationProps) => {
    const [documentTypeId, setDocumentTypeId] = useState<412001 | 675001>(412001);
    const [documents, setDocuments] = useState(undefined as any);
    const [page, setPage] = useState(0);
    const [selectedDocumentId, setSelectedDocumentId] = useState(ppgcl?.contractId);
    const [showDocumentsList, setShowDocumentsList] = useState(false);

    const transferOperatorName = {
        1: promotionName,
        3: ppgcl?.wasteManagerProGesCodLer?.procesoGestora?.gestora?.empresa,
        4: ppgcl?.transferOperatorProGesCodLer?.procesoGestora?.gestora?.empresa
    };

    useEffect(() => {
        if (documentTypeId === undefined || !promotionId) return;
        loadTreatmentContracts(true);
    }, [documentTypeId, promotionId]);

    useEffect(() => {
        setSelectedDocumentId(ppgcl?.contractId);
        setShowDocumentsList(ppgcl?.contractId === null);
    }, [ppgcl?.id]);

    const loadTreatmentContracts = async (reset = false) => {
        const currentPage = reset ? 0 : page;
        const filter = `?page=${currentPage}&tipoId.equals=${documentTypeId}&sort=fechaCreacion,desc&sort=id,desc`;
        const docsRequest = await getDocuments(promotionId, filter);
        const docs = docsRequest?.data?.content?.documents || [];

        setDocuments(reset ? docs : [...documents, ...docs]);
        setPage(currentPage + 1);
    };

    const linkPpgclToTreatmentContract = async (document) => {
        await modifyLinkedTreatmentContract({ projectWasteFlowId: ppgcl?.id, contractId: document?.id });
        changePpgcl({ ppgclId: ppgcl?.id, message: '✓ Asociado a ' + document?.name, removeCT: false });
        setSelectedDocumentId(document?.id);
    };

    const removeTreatmentContractFromPpgcl = async () => {
        await deleteLinkedTreatmentContract({ projectWasteFlowId: ppgcl?.id, contractId: selectedDocumentId });
        changePpgcl({ ppgclId: ppgcl?.id, message: '✓ Contrato de tratamiento desvinculado', removeCT: true });
        setSelectedDocumentId(null);
    };

    return (
        <div className="TreatmentContractAssociation">
            <header>
                <h4>Asociar contrato de tratamiento al flujo de residuos</h4>
                <IconX onClick={() => resetSelectedPpgcl()} />
            </header>
            <div className="TreatmentContractAssociation__ppgclData">
                <div>
                    <p>
                        LER y tratamiento: {ppgcl?.codLer} -{' '}
                        {ppgcl?.wasteManagerProGesCodLer?.procesoGestora?.valorizacion?.codigoValorizacion}
                    </p>
                    <p>Gestora: {ppgcl?.wasteManagerProGesCodLer?.procesoGestora?.gestora?.empresa || '-'}</p>
                </div>
                <div>
                    <p>Transportista: {ppgcl.carrierProGesCodLer?.procesoGestora?.gestora?.empresa || '-'}</p>
                    <p>Operador del traslado: {transferOperatorName[ppgcl?.transferOperatorType?.id] || '-'}</p>
                </div>
            </div>
            {!showDocumentsList && (
                <>
                    <p className="TreatmentContractAssociation__contractAssociated">
                        Asociado al contrato de tratamiento: {ppgcl?.contractFileName}
                    </p>
                    <SmallButton
                        titulo="Cambiar CT asociado"
                        action={() => setShowDocumentsList(true)}
                        {...({} as any)}
                    />
                </>
            )}

            {showDocumentsList && (
                <>
                    <SegmentedControl
                        onChange={(id) => setDocumentTypeId(id)}
                        value={documentTypeId}
                        options={[
                            { id: 412001, label: 'CTs no peligrosos y tierras' },
                            { id: 675001, label: 'CTs peligrosos' }
                        ]}
                    />
                    <div className="TreatmentContractAssociation__results" id="TreatmentContractAssociation__results">
                        {documents === undefined ? (
                            <p>
                                <i>Cargando...</i>
                            </p>
                        ) : documents?.length > 0 ? (
                            <InfiniteScroll
                                dataLength={documents.length}
                                next={loadTreatmentContracts}
                                hasMore={true}
                                loader={null}
                                height={400}
                                scrollableTarget="TreatmentContractAssociation__results"
                            >
                                <div>
                                    <label
                                        key="noDocument"
                                        className="TreatmentContractAssociation__document TreatmentContractAssociation__document--noDocumentOption"
                                    >
                                        <input
                                            type="radio"
                                            name="selectedTreatmentContract"
                                            onChange={() => removeTreatmentContractFromPpgcl()}
                                            checked={selectedDocumentId === null}
                                        />
                                        <span>No vincular a ningún CT</span>
                                    </label>
                                </div>
                                {documents.map((document) => (
                                    <div>
                                        <label key={document?.id} className="TreatmentContractAssociation__document">
                                            <input
                                                type="radio"
                                                name="selectedTreatmentContract"
                                                onChange={() => linkPpgclToTreatmentContract(document)}
                                                checked={selectedDocumentId === document?.id}
                                                aria-label={document?.name}
                                            />
                                            <span>{document?.name}</span>
                                            <div className="TreatmentContractAssociation__open-external">
                                                <IconButton
                                                    titulo="Ver doc."
                                                    icon={<IconExternalLink />}
                                                    action={() =>
                                                        changeDisplayDocument({
                                                            id: document?.id,
                                                            url: document?.url,
                                                            fileName: document?.name
                                                        })
                                                    }
                                                    {...({} as any)}
                                                />
                                            </div>
                                        </label>
                                    </div>
                                ))}
                            </InfiniteScroll>
                        ) : (
                            <>
                                <p>No se han encontrado contratos de tratamiento de este tipo</p>
                                <SmallButton
                                    titulo="Subir documento"
                                    color="blue"
                                    type="link"
                                    to="/documents/create"
                                    {...({} as any)}
                                />
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default TreatmentContractAssociation;
