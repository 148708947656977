// import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { TituloH2, TituloH4 } from '../../../components/BasicComponents/Titulos/Titulos';
import { withAuth } from '../../../context/AuthProvider';
import { getGraphicLink } from '../../dashboard/helpers/biuwer.helpers';
import promotionServices from '../../../api/promotion/promotion.services';
import './ReportPage.scss';

const ReportGraphics = (props) => {
    // const [t] = useTranslation();
    const [hasLands, setHasLands] = useState(true);

    useEffect(() => {
        if (props?.promocion?.id !== undefined) {
            checkIfThereAreLands();
        }
    }, [props?.promocion?.id]);

    const checkIfThereAreLands = async () => {
        const totalTonsRequest = await promotionServices.getPromoTotalTons(props?.promocion?.id);
        const tonsLand = totalTonsRequest?.data?.content?.totalLand;

        if (tonsLand !== undefined && tonsLand === 0) {
            setHasLands(false);
        }
    };

    const getLink = (resourceId, includeLands = null, includeDangerous = null) => {
        let extraFilters = '';
        extraFilters += includeLands !== null ? `&land=${includeLands}` : '';
        extraFilters += includeDangerous !== null ? `&dangerous=${includeDangerous}` : '';

        return getGraphicLink(resourceId, [props?.promocion?.id], extraFilters) || '';
    };

    return (
        <>
            <article className="reports__page">
                <TituloH2 titulo={'3. MÉTRICAS DE GESTIÓN DE RESIDUOS'} />

                <TituloH4 titulo={'Distribución de residuos por tipo de LER (valorizables)'} />
                <div className="reports__iframeWrapper">
                    <iframe
                        height="600"
                        className="reports__normalSizeIframe"
                        src={getLink('8ec6ac90-c284-11ed-ae09-17a1a794b024')}
                    />
                </div>
                <TituloH4 titulo={'Detalle toneladas generadas (por LER)'} />
                <div className="reports__iframeWrapper">
                    <iframe
                        height="700"
                        className="reports__normalSizeIframe"
                        src={getLink('2fbc2030-c186-11ed-ae09-17a1a794b024')}
                    />
                </div>
            </article>
            <article className="reports__page">
                <TituloH4 titulo={'Peso generado por residuo'} />
                <div className="reports__iframeWrapper">
                    <iframe
                        height="600"
                        className="reports__normalSizeIframe"
                        src={getLink('1cd96eb0-c338-11ed-ae09-17a1a794b024', false, false)}
                    />
                </div>
                <TituloH2 titulo={'4. TRATAMIENTO Y VALORIZACIÓN'} />

                <TituloH4 titulo={'Porcentaje de valorización y de mezcla'} />
                <div className="reports__iframeWrapper">
                    <iframe
                        height="600"
                        className="reports__normalSizeIframe"
                        src={getLink('5d690d60-c288-11ed-ae09-17a1a794b024')}
                    />
                </div>
            </article>

            <article className="reports__page">
                <TituloH4 titulo={'Cantidad de residuos según su tratamiento (residuos)'} />
                <div className="reports__iframeWrapper">
                    <iframe
                        height="600"
                        className="reports__normalSizeIframe"
                        src={getLink('7a58bc90-c288-11ed-ae09-17a1a794b024', false)}
                    />
                </div>
                <TituloH4 titulo={'Cantidad de residuos según su tratamiento (tierras)'} />
                {hasLands ? (
                    <div className="reports__iframeWrapper">
                        <iframe
                            height="600"
                            className="reports__normalSizeIframe"
                            src={getLink('7a58bc90-c288-11ed-ae09-17a1a794b024', true)}
                        />
                    </div>
                ) : (
                    <p>
                        <i>No existen tierras en el proyecto</i>
                    </p>
                )}
            </article>
            <article className="reports__page">
                <TituloH4 titulo={'Cantidad de residuos por planta gestora (residuos)'} />
                <div className="reports__iframeWrapper">
                    <iframe
                        height="600"
                        className="reports__normalSizeIframe"
                        src={getLink('94b3b720-c288-11ed-ae09-17a1a794b024', false)}
                    />
                </div>
                <TituloH4 titulo={'Cantidad de residuos por planta gestora (tierras)'} />
                {hasLands ? (
                    <div className="reports__iframeWrapper">
                        <iframe
                            height="600"
                            className="reports__normalSizeIframe"
                            src={getLink('94b3b720-c288-11ed-ae09-17a1a794b024', true)}
                        />
                    </div>
                ) : (
                    <p>
                        <i>No existen tierras en el proyecto</i>
                    </p>
                )}
            </article>
        </>
    );
};

export default withAuth(ReportGraphics);
