import { useEffect, useState } from 'react';
import './SegmentedControl.scss';

export interface SegmentedControlProps {
    value?: string | number | null;
    options: Array<{ id: string | number; label: string; content?: React.ReactElement }>;
    onChange?: (value: any) => any;
    className?: string;
}

const SegmentedControl = (props: SegmentedControlProps) => {
    const [value, setValue] = useState<typeof props.value>(props.options.find((o) => o.id === props.value)?.id || null);

    useEffect(() => {
        if (props.onChange) props.onChange(value);
    }, [value]);

    useEffect(() => {
        setValue(props.value || null);
    }, [props.value]);

    return (
        <div className={`SegmentedControl ${props.className || ''}`}>
            {props.options.map((option) => (
                <label
                    title={option.label}
                    className={`SegmentedControl__item ${value === option.id ? 'SegmentedControl__item--active' : ''}`}
                >
                    <span>{option.content || option.label}</span>
                    <input
                        type="radio"
                        name="direction"
                        onClick={(e) => setValue(option.id)}
                        aria-label={option.label}
                    />
                </label>
            ))}
        </div>
    );
};

export default SegmentedControl;
