import { IconX } from '@tabler/icons';
import { createRef, useEffect, useState } from 'react';
import { crearMaxDate } from '../../../utils/helpers/general.helpers.js';
import './Input.scss';

const InputFecha = ({
    label = '',
    id = '',
    name,
    value,
    onChange,
    className = '',
    inputContainer,
    required = false,
    aspaLimpiar = false,
    setValues,
    values,
    validacion = false,
    notLimit = false,
    minDate = null,
    maxDate = null,
    maxDays = 15,
    hasError = false,
    placeholder = null,
    extraElement,
    errorMsg = '',
    onBlur = undefined,
    ...inputAttributes
}) => {
    const [notComplete, setNotComplete] = useState(false);
    const ref = createRef();

    const limpiarFecha = () => {
        setValues({
            ...values,
            [name]: ''
        });
    };

    const inputVacio = () => {
        if (validacion === true && required && (value === undefined || value === null || value === '')) {
            setNotComplete(true);
        } else {
            setNotComplete(false);
        }
    };

    const checkIfValid = () => {
        let classInput = '';

        if (notComplete || hasError) {
            classInput += `input__input--notComplete`;
        }

        return classInput;
    };

    const getMaxDate = () => {
        if (maxDate !== undefined && maxDate !== null) {
            return maxDate;
        } else {
            return crearMaxDate(maxDays);
        }
    };

    useEffect(() => {
        inputVacio();
    }, [notComplete, validacion, value]);

    useEffect(() => {
        if (errorMsg) {
            ref.current.setCustomValidity(errorMsg);
            ref.current.reportValidity();
        } else ref.current.setCustomValidity('');
    }, [errorMsg]);

    return (
        <div className={inputContainer ? inputContainer : 'input__autocomplete'}>
            <label className="input__label" htmlFor={id}>
                {label} {required ? '*' : ''}
            </label>
            <div className={`input__wrapper ${checkIfValid()}`}>
                <input
                    {...inputAttributes}
                    ref={ref}
                    id={id}
                    type="date"
                    name={name}
                    value={value || ''}
                    onChange={onChange}
                    className={`input__input datefield input__fecha ${className || ''}`}
                    aria-label={label}
                    min={minDate && minDate !== '' ? minDate : '2019-09-01'}
                    max={notLimit ? '' : getMaxDate()}
                    placeholder={placeholder}
                />
                {aspaLimpiar && (
                    <button type="button" className="input__clear" onClick={() => limpiarFecha()}>
                        <IconX color="#cccccc" size={24} />
                    </button>
                )}
                {extraElement && extraElement}
            </div>
        </div>
    );
};
export default InputFecha;
