import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Stepper from '../../components/BasicComponents/Steppers/Stepper';
import { TituloH1 } from '../../components/BasicComponents/Titulos/Titulos';
import { withAuth } from '../../context/AuthProvider';
import Layout from '../Layout/Layout';
import DatosIniciales from '../../components/ComplexComponents/SiteChecking/DatosIniciales';
import Residuos from '../../components/ComplexComponents/SiteChecking/Residuos';
import Resumen from '../../components/ComplexComponents/SiteChecking/Resumen';
import Spinner from '../../components/BasicComponents/Spinner/Spinner';
import { getYearMonthDay, cambiarOrdenFecha } from '../../utils/helpers/general.helpers.js';
import ResultadoCarga from '../../components/BasicComponents/Messages/ResultadoCarga';
import activitiesServices from '../../api/activities/activities.services';
import lerServices from '../../api/ler/ler.services';
import visitRecordServices from '../../api/siteChecking/visitRecord.services';

const NuevaVisita = (props) => {
    const [t] = useTranslation();

    const { user, promocion } = props;
    const [currentStep, setCurrentStep] = useState(0);
    const [message, setMessage] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sectionTypes, setSectionTypes] = useState([]);
    const [lerTypes, setLerTypes] = useState([]);
    const [incidencias, setIncidencias] = useState([]);
    const [attachedImages, setAttachedImages] = useState([]);
    const [newAttachedImages, setNewAttachedImages] = useState([]);

    const steps = [
        { id: 0, title: t('initialData', { ns: 'visit' }) },
        { id: 1, title: t('waste.nonDangerous', { ns: 'common' }) },
        { id: 2, title: t('waste.dangerous', { ns: 'common' }) },
        { id: 3, title: t('rsu', { ns: 'common' }) },
        { id: 4, title: t('summary', { ns: 'visit' }) }
    ];

    const INITIAL_STATE = {
        id: null,
        clienteUser: user,
        promocion: undefined,
        estadoActividad: 'BORRADOR',
        tipoActividad: {},
        fechaActividad: getYearMonthDay(),
        observaciones: '',
        asunto: '',
        promotionState: '',
        visitRecords: [],
        onSite: true
    };

    const [actividad, setActividad] = useState(INITIAL_STATE);
    const [promoState, setPromoState] = useState({ promotionState: '' });

    useEffect(() => {
        if (promocion !== undefined && promocion !== null) {
            // Redirect if someone changes the selected promotion
            if (actividad?.promocion?.id !== undefined && promocion?.id !== actividad?.promocion?.id) {
                props.history.push('/');
            }

            // We are editing the existing activity
            if (props.match.params.activityId !== undefined && !isLoading) {
                setDataActividad(props.match.params.activityId);

                // Initiating the activity
            } else if (actividad?.promocion?.id === undefined) {
                setInitialValues();
            }

            setNewLerTypes();
        }
    }, [promocion]);

    useEffect(() => {
        if (actividad?.asunto !== '') {
            // To avoid triggering 'setActividad' twice from 2 different useEffects
            setActividad((val) => ({
                ...val,
                asunto: `${t('visit.sg', { ns: 'visit' })} (${cambiarOrdenFecha(val.fechaActividad)})`
            }));
        }
    }, [actividad.fechaActividad]);

    const setInitialValues = async () => {
        const activitySubject = `${t('visit.sg', { ns: 'visit' })} (${cambiarOrdenFecha(getYearMonthDay())})`;

        const activityType = await getActivityTypeSiteVisit();
        const activityStatus = 'BORRADOR';

        const activityAux = {
            ...actividad,
            asunto: activitySubject,
            tipoActividad: activityType,
            estadoActividad: activityStatus,
            onSite: true,
            promocion
        };

        setActividad(activityAux);

        return activityAux;
    };

    const setNewLerTypes = async () => {
        let lerTypesAux = await lerServices.getLerTypes('?sort=lerKind,asc');
        let lerTypesObject = [];
        // Doing all this innecesary wrapping because originally the object had this structure...
        // and I'm scared of changing a LOT of code due to this change
        lerTypesAux?.forEach((lerType) => {
            lerTypesObject.push({ lerType: lerType });
        });
        setLerTypes(lerTypesObject);
    };

    const getActivityTypeSiteVisit = async () => {
        let result = '';
        const types = await activitiesServices.getTypesActivities('?descripcionCorta.contains=Visita');
        if (types?.length > 0) {
            result = types[0];
        }
        return result;
    };

    useEffect(() => {
        if (isSaving) {
            updateActivity();
        }
    }, [isSaving]);

    const setDataActividad = async (id) => {
        setIsLoading(true);
        const activityAux = await activitiesServices.getActivityById(id);
        if (activityAux !== undefined) {
            let visitRecords = await visitRecordServices.getVisitRecordsByActivityId(id);
            visitRecords = visitRecords?.content?.response;

            // Doing this bc sometimes we post/put the activity without passing the promotion
            // I couldn't reproduce the error by myself, but I guess the "setInitialValues" method is never called
            let promoAux = activityAux?.promocion ? activityAux?.promocion : promocion;

            setActividad({
                ...activityAux,
                promocion: promoAux,
                visitRecords: visitRecords,
                onSite: activityAux?.onSite === null ? true : activityAux?.onSite
            });

            setPromoState({ promotionState: activityAux?.promotionState });
            setDataSectionTypes(visitRecords);
            getAttachedImages(activityAux?.id);
        }
        setIsLoading(false);
    };

    const setDataSectionTypes = (containers) => {
        let sectionTypesAux = [];

        if (containers?.length > 0) {
            // Primero ordenamos según su ler type, super importante!!
            containers.sort((a, b) => a?.lerType?.lerKind.localeCompare(b?.lerType?.lerKind));

            let lastLerType = containers[0];
            let visitRecordsArray = [];

            for (let i = 0; i < containers.length; i++) {
                if (containers[i]?.lerType?.id === lastLerType?.lerType?.id) {
                    visitRecordsArray.push(containers[i]);
                } else {
                    sectionTypesAux.push({
                        sectionName: lastLerType?.lerType?.lerKind,
                        lerType: lastLerType?.lerType,
                        existLerType: lastLerType?.existContainer,
                        dangerous: lastLerType?.lerType?.dangerous,
                        rsu: lastLerType?.lerType?.rsu,
                        containers: visitRecordsArray
                    });
                    visitRecordsArray = [];
                    lastLerType = containers[i];
                    i--;
                }
            }
            // Pusheando el último visitRecord
            sectionTypesAux.push({
                sectionName: lastLerType?.lerType?.lerKind,
                lerType: lastLerType?.lerType,
                existLerType: lastLerType?.existContainer,
                dangerous: lastLerType?.lerType?.dangerous,
                rsu: lastLerType?.lerType?.rsu,
                containers: visitRecordsArray
            });
        }
        setSectionTypes(sectionTypesAux);
    };

    const updateActivity = async () => {
        let updatedActivity = {};
        let activityWithPhotos = {};

        // Doing this bc sometimes this kind of initial info is empty. I'm going crazy
        if (actividad?.tipoActividad?.id === undefined || actividad?.asunto === '') {
            activityWithPhotos = setInitialValues();
        } else {
            activityWithPhotos = actividad;
        }

        activityWithPhotos = {
            ...activityWithPhotos,
            promotionState: promoState?.promotionState
        };

        activityWithPhotos = await removeDeletedPhotos(activityWithPhotos);
        const activityWithoutPhotos = removeDocumentsFromActivity(activityWithPhotos);

        if (actividad?.id === null) {
            updatedActivity = await visitRecordServices.createConstructionSiteActivity(activityWithoutPhotos);
        } else {
            updatedActivity = await visitRecordServices.updateConstructionSiteActivity(activityWithoutPhotos);
        }

        if (updatedActivity !== undefined) {
            await addContainerImages(updatedActivity, activityWithPhotos);
            await persistAttachedImages(updatedActivity);
        } else {
            setMessage({
                text: t('visit.updating', { ns: 'errors' }),
                success: false
            });
        }

        setIsSaving(false);
    };

    const removeDeletedPhotos = async (activity) => {
        for (let i = 0; i < activity?.visitRecords?.length; i++) {
            let visitRecord = activity?.visitRecords?.[i];

            for (let j = 0; j < visitRecord?.documents?.length; j++) {
                let image = visitRecord?.documents?.[j];

                if (image?.file === 'deleted image' && image?.id !== undefined && image?.id !== 'already deleted') {
                    await visitRecordServices.deleteImageVisitRecord(visitRecord?.id, image?.id);
                    image = { ...image, id: 'already deleted' };
                    visitRecord.documents[j] = image;
                    activity.visitRecords[i] = visitRecord;
                }
            }
        }
        return activity;
    };

    const removeDocumentsFromActivity = (activity) => {
        const activityAux = JSON.parse(JSON.stringify(activity));
        for (let i = 0; i < activityAux?.visitRecords?.length; i++) {
            let visitRecord = activityAux?.visitRecords[i];
            delete visitRecord.documents;
            activityAux.visitRecords[i] = { ...visitRecord };
        }
        return activityAux;
    };

    const sortArrayById = (array) => {
        return array?.sort((a, b) => (a.id != null ? a.id : Infinity) - (b.id != null ? b.id : Infinity));
    };

    const addContainerImages = async (updatedActivity, activityWithPhotos) => {
        let uploadsOk = true;
        let newVisitRecords = [];

        const containersWithIds = sortArrayById(updatedActivity?.visitRecords);
        const containersWithPhotos = sortArrayById(activityWithPhotos?.visitRecords);

        for (let i = 0; i < containersWithPhotos?.length; i++) {
            let currentContainer = { ...containersWithPhotos[i], id: containersWithIds[i]?.id };

            for (let j = 0; j < currentContainer?.documents?.length && uploadsOk; j++) {
                const currentImage = currentContainer?.documents[j];

                if (currentImage?.id === undefined && currentImage?.file !== 'deleted image') {
                    const imageRequest = await visitRecordServices.addImageVisitRecord(
                        currentContainer?.id,
                        currentImage
                    );

                    if (imageRequest?.content === undefined) {
                        uploadsOk = false;
                    } else {
                        const imageId = imageRequest?.content?.id;
                        currentContainer.documents[j] = { ...currentImage, id: imageId };
                    }
                }
            }
            newVisitRecords.push(currentContainer);
        }

        setDataSectionTypes(newVisitRecords);
        setActividad({ ...updatedActivity, visitRecords: newVisitRecords });

        if (uploadsOk) {
            setMessage({
                text: t('success', { ns: 'visit' }),
                success: true
            });
        } else {
            setMessage({
                text: t('visit.images.saving', { ns: 'errors' }),
                success: false
            });
        }
    };

    const getAttachedImages = async (activityId) => {
        const allAttachedImages = (await activitiesServices.getActivityFile(`?actividadId.equals=${activityId}`)) || [];

        setAttachedImages(allAttachedImages);
        setNewAttachedImages(allAttachedImages);
    };

    const persistAttachedImages = async (updatedActivity) => {
        let updatedAttachedImages = [];

        // Check if the user removed previously saved images and deleting them from BBDD
        for (let i = 0; i < attachedImages?.length; i++) {
            const attachedImage = attachedImages[i];

            const imgFound = newAttachedImages?.some((img) => img?.id === attachedImage?.id);

            if (!imgFound) {
                // Couldn't find the image in the new array (bc it was deleted by the user)
                const deletedImg = await activitiesServices.deleteActivityFile(attachedImage?.id);

                if (deletedImg === undefined) {
                    setMessage({
                        text: t('visit.images.deleting', { ns: 'errors' }),
                        success: false
                    });
                }
            }
        }

        // Posting all new images
        for (let i = 0; i < newAttachedImages?.length; i++) {
            let newAttachedImage = newAttachedImages[i];

            if (!newAttachedImage?.id) {
                // Its a new image so it doesn't have ID yet
                newAttachedImage = await activitiesServices.createActivityFile({
                    ...newAttachedImage,
                    actividad: updatedActivity
                });

                if (newAttachedImage === undefined) {
                    setMessage({
                        text: t('visit.images.uploading', { ns: 'errors' }),
                        success: false
                    });
                }
            }

            updatedAttachedImages.push(newAttachedImage);
        }
        setAttachedImages(updatedAttachedImages);
        setNewAttachedImages(updatedAttachedImages);
    };

    return (
        <Layout>
            {(isSaving || isLoading) && <Spinner />}
            <TituloH1 titulo={t('newVisit', { ns: 'visit' })} />
            <Stepper
                steps={steps}
                setCurrentStep={setCurrentStep}
                currentStep={currentStep}
                setIsSaving={setIsSaving}
                finalButton={false}
            >
                {(() => {
                    switch (currentStep) {
                        case 0:
                            return (
                                <DatosIniciales
                                    title={steps[0]?.title}
                                    actividad={actividad}
                                    setActividad={setActividad}
                                    user={user}
                                    promocion={promocion}
                                    promoState={promoState}
                                    setPromoState={setPromoState}
                                />
                            );
                        case 1:
                            return (
                                <Residuos
                                    title={steps[1]?.title}
                                    setIncidencias={setIncidencias}
                                    lerTypes={lerTypes}
                                    isRSU={false}
                                    setActividad={setActividad}
                                    sectionTypes={sectionTypes}
                                    setSectionTypes={setSectionTypes}
                                    isSaving={isSaving}
                                    promocion={promocion}
                                />
                            );
                        case 2:
                            return (
                                <Residuos
                                    title={steps[2]?.title}
                                    setIncidencias={setIncidencias}
                                    lerTypes={lerTypes}
                                    dangerously={true}
                                    isRSU={false}
                                    setActividad={setActividad}
                                    sectionTypes={sectionTypes}
                                    setSectionTypes={setSectionTypes}
                                    isSaving={isSaving}
                                    promocion={promocion}
                                />
                            );
                        case 3:
                            return (
                                <Residuos
                                    title={steps[3]?.title}
                                    setIncidencias={setIncidencias}
                                    lerTypes={lerTypes}
                                    dangerously={false}
                                    isRSU={true}
                                    actividad={actividad}
                                    setActividad={setActividad}
                                    sectionTypes={sectionTypes}
                                    setSectionTypes={setSectionTypes}
                                    isSaving={isSaving}
                                    promocion={promocion}
                                />
                            );
                        case 4:
                            return (
                                <Resumen
                                    title={steps[4]?.title}
                                    incidencias={incidencias}
                                    actividad={actividad}
                                    setActividad={setActividad}
                                    isSaving={isSaving}
                                    promocion={promocion}
                                    sectionTypes={sectionTypes}
                                    newAttachedImages={newAttachedImages}
                                    setNewAttachedImages={setNewAttachedImages}
                                />
                            );
                        default:
                            return <div>{t('visit.step', { ns: 'errors' })}</div>;
                    }
                })()}
            </Stepper>
            {message?.text && <ResultadoCarga success={message.success} text={message.text} setMessage={setMessage} />}
        </Layout>
    );
};

export default withAuth(NuevaVisita);
