import { useTranslation } from 'react-i18next';
import { DEFAULT_ICON, ICONS_BY_ACTIVITY_TYPE } from 'features/activities/constants/ActivityTypesIcons';
import { ACTIVITY_STATUS } from 'features/activities/constants/AcitivityStatus';
import { Activity } from 'features/activities/activityAdapter';
import { useDateFormatter } from 'hooks/useDateFormatter';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import Status from 'components/BasicComponents/Status/Status';
import SiteCheckingModeChip from '../smallComponents/SiteCheckingModeChip';

interface ActivityBasicDataProps {
    activity: Activity;
    isAdmin: boolean;
    isSiteChecking: boolean;
}

const ActivityBasicData = ({ activity, isAdmin, isSiteChecking }: ActivityBasicDataProps) => {
    const [t] = useTranslation('activities');
    const { format } = useDateFormatter();

    const getActivityIcon = () => {
        if (activity === null) return <></>;

        const iconData = ICONS_BY_ACTIVITY_TYPE.find((icon) => icon.typeId === activity.typeId);
        return iconData?.icon || DEFAULT_ICON;
    };

    const getActivityStatus = (): { description: string; color: string } => {
        const defaultValues = { description: 'Sin estado', color: 'blue' };

        const activityStatusData = ACTIVITY_STATUS.find((status) => status.value === activity?.status);
        const { description, color } = activityStatusData ? activityStatusData : defaultValues;

        return { description, color };
    };

    return (
        <SimpleCard>
            <section className="ActivityDetail__simpleData">
                <div className="ActivityDetail__type">
                    <div className="ActivityDetail__icon">{getActivityIcon()}</div>
                    <dl>
                        <dt>{t('type')}</dt>
                        <dd>{activity.type}</dd>
                    </dl>
                </div>
                {isSiteChecking && activity.onSite !== null && (
                    <dl>
                        <dt>Modo</dt>
                        <dd>
                            <SiteCheckingModeChip onSite={activity.onSite} />
                        </dd>
                    </dl>
                )}
                <dl>
                    <dt>{t('author')}</dt>
                    <dd>{activity.author || t('cocircularTech', { ns: 'common' })}</dd>
                </dl>
                <dl>
                    <dt>{t('date')}</dt>
                    <dd>{format(activity.date, 'DD MMM YYYY')}</dd>
                </dl>
                <dl>
                    <dt>{t('activityStatus')}</dt>
                    <dd>
                        <Status text={getActivityStatus().description} status={getActivityStatus().color} />
                    </dd>
                </dl>
                {activity.wasteManagerName && (
                    <dl>
                        <dt>{t('wasteManager')}</dt>
                        <dd title={activity.wasteManagerName}>{activity.wasteManagerName}</dd>
                    </dl>
                )}
                {isAdmin && (
                    <dl className="ActivityDetail__visibleToClient">
                        <dt>{t('visibleByClients')}</dt>
                        <dd>{activity.visibleByClients ? t('yes', { ns: 'common' }) : t('no', { ns: 'common' })}</dd>
                    </dl>
                )}
            </section>
        </SimpleCard>
    );
};

export default ActivityBasicData;
