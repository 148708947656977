import { contextStoreAdapter } from 'modules/shared/infraestructure/adapters/react/contextStoreAdapter';
import { e2ePermissionsStore } from '../../application/e2ePermissionsStore';

// Contexts creator
const createE2EPermissionsContext = contextStoreAdapter(e2ePermissionsStore);

// Main context
const e2ePermissionsContext = createE2EPermissionsContext({ name: 'E2EPermissionsStore' });
export const E2EPermissionsProvider = e2ePermissionsContext.Provider;
export const useE2EPermissions = e2ePermissionsContext.useConsumer;
