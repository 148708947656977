import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';
import { withAuth } from '../../../context/AuthProvider';
import { getTodayDate } from '../../../utils/helpers/dates.helpers';
import { customStyles, hasMore } from './helpers';

const PromotionStatus = ({
    propName = 'promotionStatus',
    required,
    className,
    values,
    setValues,
    isClearable = false,
    INITIAL_STATE = ''
}) => {
    const [t] = useTranslation();

    const TODAY_DATE = getTodayDate();
    const OPTIONS = [
        { value: `&fechaFin.greaterThan=${TODAY_DATE}`, description: 'En curso' },
        { value: `&fechaFin.lessThan=${TODAY_DATE}`, description: 'Finalizada' }
    ];

    const getLoadOptions = async (search, loadedOptions, { page }) => {
        let options = search
            ? OPTIONS.filter((option) => option.description.toLowerCase().includes(search.toLowerCase()))
            : OPTIONS;
        return {
            options,
            hasMore: hasMore(options),
            additional: {
                page: page + 1
            }
        };
    };

    const selectOption = (item) => {
        if (item) {
            setValues({ ...values, [propName]: item });
        } else {
            setValues({ ...values, [propName]: INITIAL_STATE });
        }
    };

    return (
        <div className={className ? className : 'input__autocomplete'}>
            <label className="input__label">
                {t('promotionStatus', { ns: 'common' })} {required ? '*' : ''}
            </label>
            <AsyncPaginate
                loadOptions={getLoadOptions}
                onChange={selectOption}
                getOptionLabel={(option) => option.description}
                placeholder=""
                value={values[propName]}
                additional={{
                    page: 0
                }}
                filterOption={false}
                styles={customStyles()}
                isClearable={isClearable}
            />
        </div>
    );
};
export default withAuth(PromotionStatus);
