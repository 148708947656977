import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import { AnalyticsEvents, useAnalyticsEvent } from 'modules/analytics';
import { usePromotion } from 'modules/promotion';
import { PromotionKPIs } from 'modules/promotion-kpis/domain/PromotionKPIs';
import './KPIs.scss';
import { Widget } from './types/Widget';
import useCertifiedTraceabilitiesWidget from './widgets/useCertifiedTraceabilitiesWidget';
import useMandatoryFlowsWidget from './widgets/useMandatoryFlowsWidget';
import useMixWidget from './widgets/useMixWidget';
import useValorizationWidget from './widgets/useValorizationWidget';

const KPIs = () => {
    const [t] = useTranslation('kpis');
    const analytics = useAnalyticsEvent(AnalyticsEvents.KPIS_REACHED);
    const [{ promotion }] = usePromotion();
    const promotionId = promotion?.id.toString() || '';
    const promotionProgress = promotion?.progress || 0;

    const [currentWidget, setCurrentWidget] = useState<string | null>(null);

    const kpis: { [KPI in PromotionKPIs]: ReturnType<Widget> } = {
        valorization: useValorizationWidget({ promotionId, promotionProgress }),
        mix: useMixWidget({ promotionId, promotionProgress }),
        'mandatory-flows': useMandatoryFlowsWidget({ promotionId, promotionProgress }),
        certifiedTraceabilities: useCertifiedTraceabilitiesWidget({ promotionId, promotionProgress })
    };

    return (
        <>
            <SimpleCard className="KPIs" subtitle={t('subtitle')}>
                <div className="KPIs__tabs">
                    {Object.entries(kpis).map(([widget, Widget], index) => (
                        <button
                            data-testid={`KPIs__tab-${widget}`}
                            className={`KPIs__tab ${
                                currentWidget === widget || currentWidget === null
                                    ? 'KPIs__tab--white'
                                    : 'KPIs__tab--grey'
                            } ${currentWidget === widget ? 'KPIs__tab--active' : ''}`}
                            key={index}
                            onClick={() => {
                                // Prevents the user from clicking the widget when has not details
                                if (Widget.Detail() === null) return;

                                setCurrentWidget(widget);
                                const event = AnalyticsEvents[`KPIS_${widget.toUpperCase().replace('-', '')}_CLICKED`];
                                analytics.event(event);
                            }}
                        >
                            <Widget.Tile />
                        </button>
                    ))}
                </div>

                {currentWidget !== null && (
                    <div className="KPIs__widget-container" data-testid={`KPIs__detail-${currentWidget}`}>
                        {currentWidget && kpis[currentWidget as keyof typeof kpis].Detail()}
                    </div>
                )}
                <SmallButton
                    className="KPIs__showMoreButton"
                    titulo={currentWidget === null ? 'Ver detalle' : 'Ocultar detalle'}
                    icon={currentWidget === null ? <IconChevronDown /> : <IconChevronUp />}
                    action={() => {
                        setCurrentWidget(currentWidget === null ? 'valorization' : null);
                    }}
                />
            </SimpleCard>
        </>
    );
};

export default KPIs;
