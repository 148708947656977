import { getCo2ByLer } from '../api/getCo2ByLer';

// Summatory of the KPI of each point date, to get the total KPI.
const _getKPIData = (graphData = []) => {
    return graphData?.reduce(
        (acc, curr) => {
            return {
                real: (+acc.real + +curr.real).toFixed(2),
                ideal: (+acc.ideal + +curr.ideal).toFixed(2),
                standard: (+acc.standard + +curr.standard).toFixed(2)
            };
        },
        { real: 0, ideal: 0, standard: 0 }
    );
};

// Transform the Graph legend text for KPIs
const _transformKeys = (graphData = []) => {
    return graphData?.map((item) => {
        return {
            Real: item['real'],
            Ideal: item['potential'],
            Estándar: item['standard'],
            typeLer: item['typeLer']
        };
    });
};

/** Exported methods ---------------------------------------------------------------*/

const getCo2ByLerGraphData = async (promotionId = null, filter = '') => {
    const data = await getCo2ByLer(promotionId, filter);
    let co2ByLerGraphData = data;
    const co2KPI = _getKPIData(co2ByLerGraphData);

    co2ByLerGraphData = _transformKeys(co2ByLerGraphData);

    return { co2ByLerGraphData, co2KPI };
};

export { getCo2ByLerGraphData };
