export interface WasteRegister {
    uuid: string;
    movementDate: string;
    flow: {
        id: number;
        ler: {
            code: string;
            type: string;
        };
        manager: {
            name: string;
        };
        carrier: {
            name: string;
        } | null;
        treatment: {
            code: string;
            description: string;
        };
        cost: {
            unit: number | null;
            ton: number | null;
        };
        volume: number | null;
        valuationPercentage: number | null;
    };
    promotionId: number;
    promotionName: string;
    volume: number | null;
    cost: number | null;
    observations: string | null;
    updatedAt: string;
    updatedBy: string;
    createdAt: string;
    createdBy: string;
}

export type EmptyWasteRegister = Partial<WasteRegister> & Pick<WasteRegister, 'uuid'>;

export const createEmptyWasteRegister = (
    data: EmptyWasteRegister & Pick<WasteRegister, 'promotionId'>
): EmptyWasteRegister => ({
    ...data
});
