import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import PrivateRoute from 'components/routes/PrivateRoute';
import WasteRegisterCreate from 'features/waste-register/components/WasteRegisterCreate';
import WasteRegisterList from 'features/waste-register/components/WasteRegisterList/WasteRegisterList';
import './PromotionWasteRegisterPage.scss';

const PromotionWasteRegisterPage = () => {
    const { path } = useRouteMatch();

    return (
        <div className="PromotionWasteRegisterPage">
            <Switch>
                <PrivateRoute component={List} path={path} />
                <Redirect to={path} />
            </Switch>
        </div>
    );
};

export default PromotionWasteRegisterPage;

// react router dom <Route> requieres to pass a named components (not as anonymous function) to the component prop
// because it uses the component name to determine if the component should be re - rendered or not.

const List = (route) => {
    const promotionId = route.match.params.promotionId;
    if (!promotionId) return null;

    return (
        <>
            <WasteRegisterCreate promotionId={promotionId} />
            <WasteRegisterList promotionIds={[promotionId]} />
        </>
    );
};
