import { TraceabilityStat } from 'modules/traceabilities';
import {
    MAX_MIXTURES_PERCENTAGE,
    MIN_VALORIZATION_PERCENTAGE
} from 'modules/traceabilities/domain/utils/traceabilities.constants';

export enum KpiColors {
    good = '#3da447',
    bad = '#ff7070',
    neutral = '#af87ff',
    empty = '#f2f4f7'
}

export const KPI_CONFIG: Array<{
    name: keyof Partial<TraceabilityStat>;
    calculateColor: (param) => string;
    unit?: string;
    testId: string;
}> = [
    {
        name: 'averageValorizationPercent',
        calculateColor: (valorizationPercentage) =>
            valorizationPercentage >= MIN_VALORIZATION_PERCENTAGE ? KpiColors.good : KpiColors.bad,
        unit: '%',
        testId: 'averageValorizationPercent'
    },
    {
        name: 'averageMixPercent',
        calculateColor: (mixturesPercentage) =>
            mixturesPercentage >= MAX_MIXTURES_PERCENTAGE ? KpiColors.bad : KpiColors.good,
        unit: '%',
        testId: 'averageMixPercent'
    },
    {
        name: 'accumulatedManagedTons',
        calculateColor: () => KpiColors.neutral,
        unit: 't',
        testId: 'accumulatedManagedTons'
    },
    {
        name: 'accumulatedCostPerTraceability',
        calculateColor: () => KpiColors.neutral,
        unit: '€',
        testId: 'accumulatedCostPerTraceability'
    }
];

export const DATA_EMPTY = [
    {
        id: 'empty',
        data: [
            { x: 1, y: 0 },
            { x: 2, y: 10 },
            { x: 3, y: 50 },
            { x: 4, y: 90 },
            { x: 6, y: 100 }
        ]
    }
];
