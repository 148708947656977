import { contextStoreAdapter } from 'modules/shared';
import { wasteRegisterStore } from 'modules/waste-register/application/wasteRegisterStore';

const WasteRegisterContext = contextStoreAdapter(wasteRegisterStore)({ name: 'WasteRegisterStore' });

export const WasteRegisterProvider = WasteRegisterContext.Provider;
export const useWasteRegisterStore = WasteRegisterContext.useConsumer;

export const useWasteRegisterApi = {
    findById: WasteRegisterContext.createQueryHook(({ api }) => api.findById)
};
