import Switch from 'react-switch';
import './Switch.scss';
const SwitchComponent = ({ label, checked, onChange }) => {
    return (
        <div className="switch">
            <Switch
                checked={checked}
                onChange={onChange}
                offColor="#E8E8FF"
                onColor="#7F42F5"
                uncheckedIcon={true}
                checkedIcon={true}
                id={label}
            />
            <label htmlFor={label}>{label}</label>
        </div>
    );
};
export default SwitchComponent;
