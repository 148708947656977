import { useTranslation } from 'react-i18next';
import { TituloH1 } from '../../components/BasicComponents/Titulos/Titulos';
import CardPpgclSettings from '../../components/ComplexComponents/WasteManagerDashboard/CardPpgclSettings';
import { withAuth } from '../../context/AuthProvider';
import Layout from '../Layout/Layout';
import '../../components/ComplexComponents/WasteManagerDashboard/SettingsPpgcl.scss';
import CardNewUserOdd from '../../components/ComplexComponents/WasteManagerDashboard/CardNewUserOdd';
import PromotionOddUsers from '../../components/ComplexComponents/WasteManagerDashboard/PromotionOddUsers/PromotionOddUsers';
// import WasteManagerUsers from '../../components/ComplexComponents/WasteManagerDashboard/PromotionOddUsers/WasteManagerUsers';

const WasteManagerDashboard = (props) => {
    const [t] = useTranslation();
    return (
        <Layout>
            <TituloH1 titulo={t('dashboardTitle', { ns: 'newWasteManager' })} />
            <CardPpgclSettings />

            {/* {props.promocion?.oddEnabled && (
                <div className="usersOdd">
                    <TituloH1 titulo={t('titleUsersOdd', { ns: 'traceabilityE2E' })} />
                    <CardNewUserOdd>
                        <div className="ppgcl__card ppgcl__noWrap">
                            <WasteManagerUsers />
                            <PromotionOddUsers />
                        </div>
                    </CardNewUserOdd>
                </div>
            )} */}
        </Layout>
    );
};

export default withAuth(WasteManagerDashboard);
