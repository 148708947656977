import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { KPIStatus } from 'modules/promotion-kpis';
import { currencyFormatDE } from 'utils/helpers/general.helpers';
import KPIStatusIcon from '../KPIStatusIcon';
import './KPITile.scss';

interface KPITileProps {
    label: string;
    value?: number | null;
    unit?: string;
    total?: number | null;
    hasError?: boolean;
    status?: KPIStatus | null;
}

const KPITile = (props: PropsWithChildren<KPITileProps>) => {
    const [t] = useTranslation('kpis');

    const modifiers = [
        props.value === undefined ? 'KPITile--loading' : '',
        props.value === null ? 'KPITile--not-available' : '',
        props.hasError ? 'KPITile--has-error' : '',
        props.status ? `KPITile--${props.status}` : ''
    ];

    return (
        <div className={`KPITile ${modifiers.join(' ')}`} data-testid="KPITile">
            <div className="KPITile__label">{props.value !== undefined && props.label}</div>
            <div className="KPITile__content">
                <div className="KPITile__value" data-testid="KPITile__value">
                    {props.value !== null && props.value !== undefined && (
                        <>
                            {currencyFormatDE(props.value)}
                            {props.unit}
                        </>
                    )}
                    {props.value === null && t('not-available')}
                    {props.total !== undefined && props.total !== null && (
                        <span className="KPITile__total"> / {currencyFormatDE(props.total)}</span>
                    )}
                </div>

                <KPIStatusIcon status={props.status} />
            </div>
            {props.children && <div className="KPITile__slot">{props.children}</div>}
        </div>
    );
};

export default KPITile;
