import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import BasicButton from 'components/BasicComponents/Buttons/Base/BasicButton';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import Spinner from 'components/BasicComponents/Spinner/Spinner';
import Di from 'components/ComplexComponents/Di';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { convertToYYYYMMDDFormat } from 'utils/helpers/general.helpers';
import './WasteRegisterDI.scss';
import WasteRegisterDIFallback from './components/WasteRegisterDIFallback';
import { useWasteRegisterDIData } from './hooks/useWasteRegisterDIData';

export type WasteRegisterDIProps = {
    uuid: string;
};

const WasteRegisterDI = (props: WasteRegisterDIProps) => {
    const [t] = useTranslation('wasteRegister');
    const printRef = useRef();
    const { data, error, loading } = useWasteRegisterDIData(props);
    const [, analytics] = useAnalyticsStore();

    const print = useReactToPrint({
        content: () => printRef.current as any,
        documentTitle: `${convertToYYYYMMDDFormat(data?.date)}_DI_${data?.ler.code}_${data?.di.num}`
    });

    useEffect(() => {
        if (!error) return;
        analytics.event(AnalyticsEvents.WASTE_REGISTER_DI_LINK_ERROR);
    }, [error]);

    if (loading === 'pending')
        return (
            <div className="WasteRegisterDI">
                <Spinner />
            </div>
        );

    if (error) return <WasteRegisterDIFallback />;

    if (!data) return null;

    return (
        <>
            <div className="WasteRegisterDI">
                <BasicButton
                    type="button"
                    color="purple"
                    text={t('di.print')}
                    action={() => {
                        print();
                    }}
                    {...({} as any)}
                />
                <SimpleCard dataTestid="WasteRegisterDI__di" className="WasteRegisterDI__diWrapper">
                    <Di referent={printRef} diData={data} />
                </SimpleCard>
            </div>
        </>
    );
};

export default WasteRegisterDI;
