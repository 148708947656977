import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';
import promoProGesCodLersServices from '../../../api/promoProGesCodLers/promoProGesCodLers.services';
import lerServices from '../../../api/ler/ler.services';
import { withAuth } from '../../../context/AuthProvider';
import { createFilterWithObject, differenceArrays, removeDuplicates } from '../../../utils/helpers/general.helpers';
import { getPpgclWithLinkedWasteManager } from '../../../features/traceability_e2e/api/getPpgclWithLinkedWasteManager';
import { getLersPgcl } from '../../../features/new_ppgcl/api/getLersPgcl';
import { customStyles, getCarrierIds, hasMore } from './helpers';
import { DEFAULT_OPTIONS_AUTOCOMPLETE } from './autocomplete.helpers';

const CodLerAutocomplete = ({
    className,
    required,
    value,
    setValues,
    values,
    name = 'codLer',
    searchFromPPGCL = true,
    searchFromPgcl = false,
    pgclValorizationFilter = '',
    validacion,
    INITIAL_STATE = '',
    promocion,
    carrier = 'transportista',
    wasteManager = 'gestora',
    resetWasteManager = false,
    resetCarrier = false,
    wasteManagerId = null,
    avoidDuplicateLers = false,
    requestContainerODD = false,
    disabled = false
}) => {
    const [notComplete, setNotComplete] = useState();
    const [t] = useTranslation();

    useEffect(() => {
        emptyInput();
    }, [promocion?.id, validacion, value]);

    const carrierIds = getCarrierIds(values[carrier]);

    const getCarrierFilter = () => {
        if (Array.isArray(values[carrier])) {
            return `&carrierId.contains=${carrierIds}`;
        }
        return `&carrierId.equals=${carrierIds}`;
    };

    const filterValues = {
        [wasteManager]: `&gestoraId.equals=${values?.[wasteManager]?.id}`,
        [carrier]: carrierIds ? getCarrierFilter() : ''
    };

    const getFilter = (page, search) => {
        let filter = `?page=${page}`;

        if (promocion) {
            filter += `&promocionId.equals=${promocion?.id}`;
        }
        if (search && !searchFromPgcl) {
            filter += `&codLer.contains=${search}&typeLer.contains=${search}`;
        }
        if (search && searchFromPgcl) {
            filter += `&codLER.contains=${search}&lerType.contains=${search}`;
        }
        const addFilter = createFilterWithObject(values, filterValues);
        return (filter += addFilter);
    };

    const getFilterPgcl = (page, search) => {
        let filter = `?page=${page}&sort=codigoLER,asc&hasPgcl.equals=true${pgclValorizationFilter}`;
        if (values?.[wasteManager]) {
            filter += `&gestoraId.equals=${values?.[wasteManager]?.id}`;
        }
        if (search) {
            filter += `&lerType.contains=${search}&codigoLER.contains=${search}`;
        }
        return filter;
    };
    const loadOptionsProGesCodLer = async (search, loadedOptions, { page }) => {
        let options = [];
        let filter = getFilterPgcl(page, search);
        const lers = await getLersPgcl(filter);
        options = lers.data;

        return {
            options,
            hasMore: hasMore(lers),
            additional: {
                page: page + 1
            }
        };
    };

    const loadOptionsPPGCL = async (search, loadedOptions, { page }) => {
        let filter = getFilter(page, search);
        if (wasteManagerId !== null) {
            filter += `&gestoraId.equals=${wasteManagerId}`;
        }
        const promoProGesCodLers = await promoProGesCodLersServices.filterPromoProGesCodLers(filter);
        const optionsWithDuplicates = promoProGesCodLers.map((option) => option.wasteManagerProGesCodLer.codLER);

        let options = removeDuplicates(optionsWithDuplicates, 'id');

        // add only NEW cod lers
        if (avoidDuplicateLers) {
            options = differenceArrays(options, loadedOptions, 'codigoLER');
        }

        return {
            options,
            hasMore: hasMore(promoProGesCodLers),
            additional: {
                page: page + 1
            }
        };
    };

    const loadOptionsOnlyCodLer = async (search, loadedOptions, { page }) => {
        let filter = `?page=${page}`;
        filter += search ? `&codigoLER.contains=${search}&lerType.contains=${search}` : '';
        const codLers = await lerServices.getCodLers(filter);
        const options = codLers;

        return {
            options,
            hasMore: hasMore(codLers),
            additional: {
                page: page + 1
            }
        };
    };

    const loadOptionsPPGCLWithWasteManager = async (search, loadedOptions, { page }) => {
        const filter = `?page=${page}&promotionId=${promocion?.id}`;
        const promoProGesCodLersRequest = await getPpgclWithLinkedWasteManager(filter);
        const ppgcls = promoProGesCodLersRequest?.data || [];

        const optionsWithDuplicates = ppgcls?.map((option) => option.wasteManagerProGesCodLer.codLER);
        let options = removeDuplicates(optionsWithDuplicates, 'id');

        // add only NEW cod lers
        if (avoidDuplicateLers) {
            options = differenceArrays(options, loadedOptions, 'codigoLER');
        }

        return {
            options,
            hasMore: hasMore(options),
            additional: {
                page: page + 1
            }
        };
    };

    const selectOption = (item) => {
        if (item === null) {
            setValues({
                ...values,
                [name]: INITIAL_STATE,
                [carrier]: resetCarrier ? '' : values[carrier],
                [wasteManager]: resetWasteManager ? '' : values[wasteManager]
            });
        } else {
            setValues({
                ...values,
                [name]: item,
                [carrier]: resetCarrier ? '' : values[carrier],
                [wasteManager]: resetWasteManager ? '' : values[wasteManager]
            });
        }
    };

    const emptyInput = () => {
        if (validacion === true && required && (value === '' || value === undefined || value === null)) {
            setNotComplete(true);
        } else {
            setNotComplete(false);
        }
    };

    const getLoadOptions = () => {
        if (searchFromPgcl) {
            return loadOptionsProGesCodLer;
        } else if (searchFromPPGCL) {
            if (requestContainerODD) {
                return loadOptionsPPGCLWithWasteManager;
            } else {
                return loadOptionsPPGCL;
            }
        } else {
            return loadOptionsOnlyCodLer;
        }
    };

    const key = () => {
        return JSON.stringify(values);
    };

    const modifiers = [disabled ? 'input__autocomplete--disabled' : ''];

    return (
        <div className={`${className ? className : 'input__autocomplete'} ${modifiers.join(' ')}`}>
            <label className="input__label">
                {t('ler.code', { ns: 'common' })} {required ? '*' : ''}
            </label>

            <AsyncPaginate
                {...DEFAULT_OPTIONS_AUTOCOMPLETE}
                loadOptions={getLoadOptions()}
                onChange={selectOption}
                getOptionLabel={(option) => `${option.codigoLER} - ${option.lerType.lerKind}`}
                getOptionValue={(option) => option}
                placeholder=""
                value={values?.[name]}
                additional={{
                    page: 0
                }}
                styles={customStyles(notComplete)}
                defaultInputValue={null}
                isClearable={true}
                key={key()}
                isDisabled={disabled}
            />
        </div>
    );
};

export default withAuth(CodLerAutocomplete);
