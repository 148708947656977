import { IconHome } from '@tabler/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import { withAuth } from 'context/AuthProvider';
import Layout from 'pages/Layout/Layout';

import ReportComponent from './ReportComponent';

export interface ReportPageProps {
    promocion?: {
        id: string;
    };
}

const ReportPage = ({ promocion }: ReportPageProps) => {
    const history = useHistory();
    const [t] = useTranslation();

    const hasPromotion = !!promocion?.id;
    return (
        <>
            {hasPromotion ? (
                <ReportComponent />
            ) : (
                <div>
                    <p>{t('toDisplayReportGoHome', { ns: 'reports' })}</p>
                    <br />
                    <SmallButton
                        titulo={t('goHome', { ns: 'reports' })}
                        icon={<IconHome />}
                        action={() => history.push(`/home`)}
                        type="button"
                        color="blue"
                    />
                </div>
            )}
        </>
    );
};

export default withAuth(ReportPage);
