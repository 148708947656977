import { IconChartBar, IconTable } from '@tabler/icons';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import SegmentedControl from 'components/BasicComponents/SegmentedControl/SegmentedControl';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { usePromotion } from 'modules/promotion';
import { useWasteByValorization } from 'modules/waste-by-valorization';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './WasteByValorization.scss';
import WasteByValorizationChart from './WasteByValorizationChart';
import WasteByValorizationTable from './WasteByValorizationTable';

const WasteByValorization = () => {
    const [t] = useTranslation();
    const [mode, setMode] = useState<'chart' | 'table'>('chart');
    const [, analytics] = useAnalyticsStore();

    const [{ promotion }] = usePromotion();
    if (!promotion) return <></>;

    // eslint-disable-next-line
    const { data } = useWasteByValorization({ promotionId: promotion?.id });

    return (
        <SimpleCard>
            <header className="WasteByValorization__header">
                <h3>Toneladas de residuos por tipo de valorización</h3>

                <SegmentedControl
                    onChange={(newMode) => {
                        setMode(newMode as typeof mode);
                        if (newMode === 'table') {
                            analytics.event(AnalyticsEvents.PROMO_VALORIZATION_TABLE_SWITCH_CLICKED);
                        }
                    }}
                    value={mode}
                    options={[
                        {
                            id: 'chart',
                            label: t('summary.chart', { ns: 'traceabilities' }),
                            content: <IconChartBar size={20} stroke={1.6} />
                        },
                        {
                            id: 'table',
                            label: t('summary.table', { ns: 'traceabilities' }),
                            content: <IconTable size={20} stroke={1.6} />
                        }
                    ]}
                />
            </header>
            {data !== null && data?.length > 0 && (
                <>
                    {mode === 'chart' ? (
                        <WasteByValorizationChart data={data} />
                    ) : (
                        <WasteByValorizationTable data={data} />
                    )}
                </>
            )}
            {data?.length === 0 && (
                <p className="WasteByValorization__noResultsMessage">{t('noData', { ns: 'common' })}</p>
            )}
        </SimpleCard>
    );
};

export default WasteByValorization;
