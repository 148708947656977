import { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconChevronDown, IconInfoCircle } from '@tabler/icons';
import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import { usePromotion } from 'modules/promotion';
import { useTraceabilities } from 'modules/traceabilities';
import {
    TraceabilitiesSummaryCriteria,
    TraceabilitiesSummaryTotalMeasures
} from 'modules/traceabilities/domain/TraceabilitiesSummary';
import { currencyFormatDE } from 'utils/helpers/general.helpers';
import './TraceabilitiesSummaryTable.scss';
import TraceabilitiesSummaryTableSkeleton from './TraceabilitiesSummaryTableSkeleton';

type TraceabilitiesSummaryTableProps = Omit<TraceabilitiesSummaryCriteria, 'unit'>;

const TraceabilitiesSummaryTable = ({ filters, measure }: TraceabilitiesSummaryTableProps) => {
    const [t] = useTranslation('traceabilities');
    const [{ promotion }] = usePromotion();
    const [{ removed, summaryTotals, loading }, { loadTraceabilitiesSummaryTotals }] = useTraceabilities();
    const [limited, setLimited] = useState(true);

    useEffect(() => {
        if (!filters.promotionId) return;
        loadTraceabilitiesSummaryTotals({ measure });
        setLimited(true);
    }, [measure, JSON.stringify(filters), JSON.stringify(removed)]);

    if (summaryTotals.length === 0) return <TraceabilitiesSummaryTableSkeleton />;

    const list = summaryTotals.slice(0, limited ? 10 : undefined);
    const hasMore = summaryTotals.length > list.length;

    const modifiers = [loading.summary === 'pending' ? 'TraceabilitiesSummaryTable--loading' : ''];

    return (
        <div className={`TraceabilitiesSummaryTable ${modifiers.join(' ')}`} data-testid="TraceabilitiesSummaryTable">
            <div className="TraceabilitiesSummaryTable__scroll-container">
                <table>
                    <thead>
                        <tr>
                            <td></td>
                            <th>{t(`summary.units.traceabilities`)}</th>
                            <th>{t(`summary.units.valorizationPercent`)}</th>
                            <th colSpan={2}>{t(`summary.units.managedTons`)}</th>
                            <th>{t(`summary.units.volume`)}</th>
                            {promotion?.permissions.costs && <th>{t(`summary.units.cost`)}</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((row) => (
                            <tr key={row.id}>
                                <th role="rowheader">{row.label}</th>

                                <Cell col="traceabilities">{row.content.traceabilities}</Cell>
                                <Cell col="valorizationPercent" unit="%">
                                    {row.content.valorizationPercent}
                                </Cell>
                                <Cell col="managedTons" unit="t">
                                    {row.content.managedTons}
                                </Cell>
                                <Cell
                                    col="managedTonsPercent"
                                    unit="%"
                                    className="TraceabilitiesSummaryTable__tonsPercent"
                                >
                                    {row.content.managedTonsPercent}
                                </Cell>
                                <Cell col="volume" unit="m³">
                                    {row.content.volume}
                                </Cell>
                                {promotion?.permissions.costs && (
                                    <Cell col="cost" unit="€">
                                        {row.content.cost}
                                    </Cell>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {limited && hasMore && (
                <div className="TraceabilitiesSummaryTable__show-more">
                    <SmallButton
                        action={() => setLimited(false)}
                        titulo={t(`showAll`)}
                        icon={<IconChevronDown />}
                        iconPosition="left"
                        // Just to ignore optional fields
                        {...({} as any)}
                    />
                </div>
            )}
        </div>
    );
};

export default TraceabilitiesSummaryTable;

const Cell = ({
    children,
    col,
    className,
    unit
}: PropsWithChildren<{ unit?: string; col: TraceabilitiesSummaryTotalMeasures; className?: string }>) => {
    const [t] = useTranslation('traceabilities');

    /* TODO: Unknown valorization percent should change to "null" */
    const isNotAvailable = children === 1 && col === 'valorizationPercent';
    const isNotApplicable = children === null;

    const value =
        (isNotAvailable && t('notAvailableShort', { ns: 'common' })) ||
        (isNotApplicable && t('notApplicableShort', { ns: 'common' })) ||
        `${currencyFormatDE(children)} ${unit || ''}`;

    const msg = (isNotApplicable && 'notApplicableAlert') || (isNotAvailable && 'notAvailableAlert') || '';

    return (
        <td className={className}>
            <div>
                <span>{value}</span>
                {(isNotAvailable || isNotApplicable) && (
                    <Tooltip
                        className="TraceabilitiesSummaryTable__tooltip"
                        msg={t(msg, { col: t(`summary.units.${col}`) })}
                    >
                        <IconInfoCircle size={14} />
                    </Tooltip>
                )}
            </div>
        </td>
    );
};
