import { useEffect, useState } from 'react';

import { WasteRegister, createEmptyWasteRegister } from 'modules/waste-register/domain/WasteRegister';
import { useWasteRegisterStore } from 'modules/waste-register/infrastructure/react/wasteRegisterContext';
import { WasteRegisterFormProps } from '../WasteRegisterForm';

type EmptyWasteRegister = Partial<WasteRegister> & Pick<WasteRegister, 'uuid'>;

export function useWasteRegisterForm({ promotionId, uuid, onSave }: WasteRegisterFormProps) {
    const mode = !!uuid ? 'edit' : 'create';

    const [{ data: wasteRegisters, loading, invalid }, { saveWasteRegister }] = useWasteRegisterStore();
    const [wasteRegister, setWasteRegister] = useState<WasteRegister | EmptyWasteRegister>();

    const foundWr = uuid ? wasteRegisters.byId[uuid] : null;

    const update = (wr: Partial<typeof wasteRegister>) => {
        if (!wasteRegister) return;
        setWasteRegister({ ...wasteRegister, ...wr });
    };

    const submit = async () => {
        if (!wasteRegister) return;
        try {
            await saveWasteRegister(wasteRegister as WasteRegister);
            onSave?.();
        } catch {}
    };

    // Loads waste register data or creates a new one
    useEffect(() => {
        if (wasteRegister) return;

        if (mode === 'edit' && foundWr) setWasteRegister(foundWr);
        if (mode === 'create')
            setWasteRegister(
                createEmptyWasteRegister({
                    uuid: crypto.randomUUID(),
                    promotionId,
                    movementDate: new Date().toISOString().split('T')[0]
                })
            );
    }, [foundWr, loading, wasteRegister, promotionId, mode]);

    // Updates volume with flow volume when flow changes
    useEffect(() => {
        if (wasteRegister?.flow?.id === undefined) return;
        if (wasteRegister.volume) return;

        update({ volume: wasteRegister.flow.volume });
    }, [wasteRegister?.flow?.id]);

    return {
        wasteRegister,
        mode,
        loading,
        error: !!invalid[wasteRegister?.uuid || ''],

        // actions
        update,
        submit
    };
}
