import { Redirect } from 'react-router-dom';

const TraceabilityRedirect = () => {
    const getPromotionFromSessionStorage = () => {
        try {
            return JSON.parse(sessionStorage.getItem('promocion') || '');
        } catch {
            return null;
        }
    };

    const promotion = getPromotionFromSessionStorage();

    if (!promotion) return <Redirect to="/" />;

    return <Redirect to={`promotion/${promotion?.id}/traceabilities`} />;
};

export const TRACEABILITIES_ROUTES = [
    {
        component: TraceabilityRedirect,
        path: '/traceabilities',
        requiredPermission: 'canView',
        section: 'traceability'
    }
];
