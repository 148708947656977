import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconCheck } from '@tabler/icons';
import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import { OnboardingResource, OnboardingResourceState } from 'modules/onboarding';
import { OnboardingResourceStates } from 'modules/onboarding/domain/paths/resources/OnboardingResourceStates';
import './OnboardingResourceItem.scss';

interface OnboardingResourceItemProps extends React.HTMLAttributes<HTMLAnchorElement> {
    data: OnboardingResource;
    state?: OnboardingResourceState;
    variant?: 'tiny';
}

const OnboardingResourceItem = forwardRef<HTMLAnchorElement, OnboardingResourceItemProps>(
    ({ data: resource, className, variant, state, ...rest }, ref) => {
        const [t] = useTranslation('onboarding');
        const [mimeType, setMimeType] = useState<'jpg' | 'gif'>('jpg');

        const modifiers = [variant === 'tiny' ? '--tiny' : '', className || ''];

        return (
            <a
                ref={ref}
                {...rest}
                className={`OnboardingResourceItem ${modifiers.join(' ')}`}
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.loom.com/share/${resource.video?.id}`}
                onMouseEnter={() => setMimeType('gif')}
                onMouseLeave={() => setMimeType('jpg')}
            >
                <Tooltip
                    msg={state?.state === OnboardingResourceStates.SEEN ? t('viewed') : t('pendingView')}
                    className={`OnboardingResourceItem__state --${state?.state?.toLowerCase() || ''}`}
                >
                    <div>{state?.state === OnboardingResourceStates.SEEN && <IconCheck size={14} />}</div>
                </Tooltip>
                <div className="OnboardingResourceItem__thumbnail">
                    <img
                        alt={t(`${resource.id}.title`)}
                        src={`https://cdn.loom.com/sessions/thumbnails/${resource.video?.thumbnailId}.${mimeType}`}
                        onError={(e) => (e.currentTarget.style.display = 'none')}
                    />
                </div>
                <div className="OnboardingResourceItem__info">
                    <p className="OnboardingResourceItem__title">
                        {t(`resource.${resource.id}.title`)} — {resource.video?.minutes} min.
                    </p>
                    <p className="OnboardingResourceItem__description">
                        {t(`resource.${resource.id}.description`, { defaultValue: null })}
                    </p>
                </div>
            </a>
        );
    }
);

export default OnboardingResourceItem;
