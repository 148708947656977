import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SidePanel from 'components/BasicComponents/SidePanel';
import { useLocation } from 'react-router-dom';
import WasteRegisterForm from '../WasteRegisterForm';
import './WasteRegisterCreate.scss';

type WasteRegisterCreateProps = {
    promotionId: number;
};

const WasteRegisterCreate = (props: WasteRegisterCreateProps) => {
    const [t] = useTranslation('wasteRegister');
    const [open, setOpen] = useState(false);
    const { state } = useLocation();

    useEffect(() => {
        if (!state?.openCreatePanel) return;

        setOpen(state?.openCreatePanel);
        // Reset state so we can open sidepanel by clicking on the button again.
        // Doing it this way to avoid rerender component
        window.history.replaceState({}, '');
    }, [state]);

    return (
        <>
            {/* <div className="WasteRegisterCreate">
                <CreateButton
                    type="button"
                    text={t('registerDI.button')}
                    icon={null}
                    color={'white'}
                    action={() => {
                        setOpen(true);
                    }}
                />
            </div> */}
            <SidePanel title={t('registerDI.panelTitle')} openModal={open} setOpenModal={setOpen}>
                <WasteRegisterForm promotionId={props.promotionId} onSave={() => setOpen(false)} />
            </SidePanel>
        </>
    );
};

export default WasteRegisterCreate;
