import { PromotionKPIsGoalMet } from './goals/PromotionKPIsGoal';

/**
 * Obtiene por cada nivel de severidad el goal aplicable (met !== null) con mayor progreso
 * Luego, de todos estos, orderarlos primero los que no están cumplidos. Si ninguno cumple, ordenar por severidad
 */
export function getCurrentApplicableGoals(goals?: PromotionKPIsGoalMet[]) {
    if (!goals) return [];

    // Agrupa los goals por severidad
    let goalsBySeverity: Record<string, PromotionKPIsGoalMet[]> = {};
    for (const goal of goals) {
        const serverityGoals = goalsBySeverity[goal.severity as number];
        goalsBySeverity[goal.severity as number] = serverityGoals ? [...serverityGoals, goal] : [goal];
    }

    // Obtiene el goal aplicable con mayor progreso por cada nivel de severidad
    let currentApplicableGoals: PromotionKPIsGoalMet[] = [];
    for (const severityGoals of Object.values(goalsBySeverity)) {
        const sortedGoals = sortGoalsByInmediate(severityGoals) || [];
        const currentGoal = sortedGoals[0];
        if (currentGoal) currentApplicableGoals.push(currentGoal);
    }

    // Ordena los goals aplicables:
    currentApplicableGoals.sort((a, b) => {
        // 1. Primero los que no están cumplidos
        if (a.met !== b.met) return a.met ? 1 : -1;
        // 2. A igual cumplimiento, ordena por severidad de más crítica a menos crítica
        return (a.severity as number) - (b.severity as number);
    });

    return currentApplicableGoals;
}

/** Reports the most inmediate goal to evaluate */
function sortGoalsByInmediate(goals: PromotionKPIsGoalMet[]) {
    const sortedGoals = goals.sort((a, b) => {
        // Si no está cumplido, va al final
        if (a.met === null) return 1;
        if (b.met === null) return -1;

        // 2. Ordena por progreso, de más alto a más bajo
        if (a.progress !== b.progress) return b.progress - a.progress;

        // 3. A igual progreso, ordena por valor, de más alto a más bajo
        return b.value.value - a.value.value;
    });

    return sortedGoals;
}
