import BasicButton from 'components/BasicComponents/Buttons/Base/BasicButton';
import './MessageWasteManagerPage.scss';
import { useAuth } from 'modules/auth';

// ELIMINAR COMPONENTE (Y SU CSS) CUANDO NOS CARGUEMOS DEL TODO EL PORTAL DE GESTORAS (E2E)
const MessageWasteManagerPage = () => {
    const [, { logout }] = useAuth();

    return (
        <div className="MessageWasteManagerPage">
            <h1>El portal CoCircular vinculado al DI ya no está disponible</h1>
            <p>
                Comparte los DIs de tus clientes a través de su buzón WallE (cada proyecto tiene un correo que os debe
                hacer llegar).
            </p>
            <p>Si tienes dudas, contacta con CoCircular.</p>
            <BasicButton
                type="button"
                action={() => logout()}
                color="blue"
                text="Cerrar sesión" // Just to ignore optional fields
                {...({} as any)}
            />
        </div>
    );
};

export default MessageWasteManagerPage;
