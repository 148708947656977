import { Thunk } from 'modules/shared/domain/store/createStore';

import { E2EPermissionsStore } from '../../domain/E2EPermissionsStore';
import { createE2EPermissions } from '../../domain/createE2EPermissions';

export const loadE2EPermissions = (): Thunk<E2EPermissionsStore> => async (store, repositories) => {
    store.dispatch('loadE2EPermissionsPending', null);
    try {
        const promotion = await repositories.promotion.getCurrent();
        const user = await repositories.user.getCurrent();

        const permissions = createE2EPermissions({ promotion, user });

        store.dispatch('loadE2EPermissionsFulfilled', { permissions });
    } catch (error) {
        store.dispatch('loadE2EPermissionsRejected', { error: error as Error });
    }
};
