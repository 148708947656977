import { logger } from 'logger';
import fetcher from '../../../lib/fetcher';

const _userMessage = {
    success: {
        text: 'request.success',
        ns: 'traceabilityE2E'
    },
    error: {
        text: 'request.error',
        ns: 'traceabilityE2E'
    }
};

export const createTraceabilityE2E = async (traceability) => {
    const URL = '/api/tracing/end2end-traceability';
    try {
        const response = await fetcher.post(URL, traceability, { message: _userMessage });
        return response;
    } catch (error) {
        logger.send(error);
    }
};
