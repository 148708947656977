import { TraceabilitiesCriteria } from './TraceabilitiesCriteria';

/**
 *
 * COMMON
 */
export interface TraceabilitiesSummaryCriteria {
    filters: TraceabilitiesCriteria;
    measure: TraceabilitiesSummaryMeasures;
    unit: TraceabilitiesSummaryUnits;
}

export enum TraceabilitiesSummaryUnits {
    movements = 'movements',
    tons = 'tons',
    costs = 'costs'
}

export enum TraceabilitiesSummaryMeasures {
    lerType = 'ler-type',
    codLer = 'cod-ler',
    // ler = 'ler', temporary disable until back implementes it
    wasteManager = 'waste-manager',
    // carrier = 'carrier', temporary disable until back implementes it
    treatment = 'treatment'
}

export enum TraceabilitiesSummaryMode {
    byDate = 'by-date',
    totals = 'summary'
}

/**
 *
 * BY DATE
 */

export type TraceabilitiesSummaryByDate = TraceabilitiesSummaryDateEntry[];

interface TraceabilitiesSummaryDateEntry {
    date: string;
    data: TraceabilitiesSummaryData;
}

type TraceabilitiesSummaryData = Array<TraceabilitiesSummaryValue>;

interface TraceabilitiesSummaryValue {
    id: number;
    label: string;
    value: number;
}

/**
 *
 * TOTALS
 */

export type TraceabilitiesSummaryTotals = TraceabilitiesSummaryTotal[];

export type TraceabilitiesSummaryTotalMeasures = keyof TraceabilitiesSummaryTotal['content'];

interface TraceabilitiesSummaryTotal {
    id: number;
    label: string;
    content: {
        traceabilities: null | number;
        managedTons: null | number;
        managedTonsPercent: null | number;
        valorizationPercent: null | number;
        volume: null | number;
        cost: null | number;
    };
}
