import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Traceability } from 'modules/traceabilities';
import { useWasteRegisterApi } from 'modules/waste-register/infrastructure/react/wasteRegisterContext';
import { wasteRegisterToTraceabilityAdapter } from '../adapter/wasteRegisterToTraceabilityAdapter';

export function useWasteRegisterDIData({ uuid }: { uuid: string }) {
    const [t] = useTranslation();
    const wasteRegister = useWasteRegisterApi.findById({ uuid: uuid });
    const [data, setData] = useState<Traceability>();
    const [error, setError] = useState<Error>();

    useEffect(() => {
        if (!wasteRegister.data) return;

        // In order to prevent critical error page, we need to wrap the code in a try-catch block
        try {
            setData(wasteRegisterToTraceabilityAdapter(wasteRegister.data));
            setError(undefined);
        } catch (e) {
            const error = e as Error;
            error.name = t('error');
            setError(error);
        }
    }, [wasteRegister.data]);

    return { data, error: error || wasteRegister.error, loading: wasteRegister.loading };
}
