import { useTranslation } from 'react-i18next';

import KPITile from 'features/KPIs/components/KPITile';
import { Widget } from 'features/KPIs/types/Widget';
import { createQueryString } from 'hooks/useQuery';
import { usePromotion } from 'modules/promotion';
import { KPIStatus, calcCertifiedTraceabilitiesStatus, useCertifiedTraceabilities } from 'modules/promotion-kpis';
import KPIAlarm from '../components/KPIAlarm';
import KPIThermometer from '../components/KPIThermometer';

const useCertifiedTraceabilitiesWidget: Widget = ({ promotionId }) => {
    const [t] = useTranslation('kpis');
    const { data, error, loading } = useCertifiedTraceabilities({ promotionId });
    const [{ promotion }] = usePromotion();

    const current = calcCertifiedTraceabilitiesStatus({
        certifiedTraceabilities: data,
        promotionProgress: promotion?.progress
    });

    const isErrorStatus = current.status && [KPIStatus.DANGER, KPIStatus.WARNING].includes(current.status);
    const emptyTraceabilities = current.certifiedTraceabilities && current.certifiedTraceabilities.goal === 0;

    return {
        status: current.status || null,
        Tile: () => (
            <KPITile
                label={t('certifiedTraceabilities.label')}
                value={!emptyTraceabilities ? current.certifiedTraceabilities?.data : null}
                total={!emptyTraceabilities ? current.certifiedTraceabilities?.goal : null}
                hasError={!!error}
                status={current.status}
            >
                <KPIThermometer
                    value={current.certifiedTraceabilities?.data}
                    maxValue={current.certifiedTraceabilities?.goal}
                    thresholds={[{ type: 'MIN', value: current.certifiedTraceabilities?.goal || 0 }]}
                    status={current.status}
                />
            </KPITile>
        ),
        Detail: () => {
            if (loading !== 'succeeded' || current.status === undefined) return null;

            return (
                <div>
                    {current.status !== null && current.status !== undefined && (
                        <KPIAlarm
                            status={current.status}
                            title={t(`certifiedTraceabilities.alarm.${current.status}.title`)}
                            text={t(`certifiedTraceabilities.alarm.${current.status}.subtitle`, {
                                notCertified: current.notCertified
                            })}
                            link={{
                                to: `/promotion/${promotion?.id}/traceabilities${
                                    isErrorStatus
                                        ? createQueryString({
                                              di: { label: 'No acreditado', value: 'not-certified' }
                                          })
                                        : ''
                                }`,
                                text: t(`certifiedTraceabilities.alarm.${current.status}.link`)
                            }}
                        />
                    )}
                </div>
            );
        }
    };
};

export default useCertifiedTraceabilitiesWidget;
