import { IconAlertTriangle, IconExternalLink, IconRadioactive } from '@tabler/icons';
import IconButton from 'components/BasicComponents/Buttons/Small/IconButton';
import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import promoProGesCodLersServices from '../../../../api/promoProGesCodLers/promoProGesCodLers.services';
import { withAuth } from '../../../../context/AuthProvider';
import { getTraceabilities } from '../../../../features/traceability/api/getTraceabilities';
import { INITIAL_STATE_SEEKER } from '../../../../pages/NewPromotion/newPromotion.utils';
import { createFilterWithObject } from '../../../../utils/helpers/general.helpers';
import SubmitButton from '../../../BasicComponents/Buttons/SubmitButton';
import ResultadoCarga from '../../../BasicComponents/Messages/ResultadoCarga';
import BasicModal from '../../../BasicComponents/Modales/BasicModal';
import SimplePopover from '../../../BasicComponents/Popovers/SimplePopover';
import CodLerAutocomplete from '../../../BasicComponents/Select-Autocomplete/CodLerAutocomplete';
import PromoProGesCodLerAutocomplete from '../../../BasicComponents/Select-Autocomplete/PromoProGesCodLerAutocomplete';
import Spinner from '../../../BasicComponents/Spinner/Spinner';
import { PpgclContext } from '../CardPpgclSettings';
import FormEditPpgcl from '../SeekerPpgcl/FormEditPpgcl';
import ResultPpgcl from '../SeekerPpgcl/ResultPpgcl';
import './SeekerPpgcl.scss';
import TreatmentContractAssociation from './TreatmentContractAssociation';

const SeekerPpgcl = ({ promocion }) => {
    const [t] = useTranslation();

    const { setAllPpgcl, allPpgcl } = useContext(PpgclContext);

    const [checkTraceabilities, setCheckTraceabilities] = useState(false);
    const [hasTraceabilities, setHasTraceabilities] = useState(false);
    const [message, setMessage] = useState();
    const [ppgclToDelete, setPpgclToDelete] = useState();
    const [loading, setLoading] = useState(false);
    const [openEditPopover, setOpenEditPopover] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [seeker, setSeeker] = useState(INITIAL_STATE_SEEKER);
    const [ppgclToEdit, setPpgclToEdit] = useState();
    const [selectedPpgcl, setSelectedPpgcl] = useState(undefined);
    // this is not boolean tho. its value can be false or { id: string; url: string; fileName: string }
    const [displayDocument, setDisplayDocument] = useState(false);

    const checkIfPpgclHasTraceabilities = async () => {
        setCheckTraceabilities(false);
        const filter = `/count?promoProGesCodeLerId.equals=${ppgclToDelete.id}`;
        const numberOfTraceabilties = await getTraceabilities(filter);
        numberOfTraceabilties.data === 0 ? setHasTraceabilities(false) : setHasTraceabilities(true);
        setCheckTraceabilities(true);
    };

    const textModal = () => {
        return hasTraceabilities
            ? t('notDeleteBecause', { ns: 'newPpgcl' })
            : `${t('sureDelete', { ns: 'newPpgcl' })} ${ppgclToDelete.id}?`;
    };
    const removePpgclList = (ppgclToDelete) => {
        setAllPpgcl(allPpgcl.filter((ppgcl) => ppgcl.id !== ppgclToDelete.id));
    };

    const createMessage = (deleteTraceability) => {
        if (deleteTraceability !== undefined && deleteTraceability.status === 204) {
            setMessage({ text: t('messageDelete.success', { ns: 'newPpgcl' }), success: true });
            removePpgclList(ppgclToDelete);
        } else {
            setMessage({ text: t('messageDelete.error', { ns: 'newPpgcl' }), success: false });
        }
    };

    const deletePpgcl = async () => {
        if (!hasTraceabilities) {
            const deleteTraceability = await promoProGesCodLersServices.deletePromoProGesCodLer(ppgclToDelete);
            createMessage(deleteTraceability);
        }
    };

    const handleFormSubmitPromotionInfo = (e) => {
        e.preventDefault();
        getPpgcl();
    };

    const { gestora, codLer } = seeker;

    const searchFilter = {
        gestora: `&gestoraId.equals=${gestora?.id}`,
        codLer: `&codLer.equals=${codLer?.codigoLER}`
    };

    const createFilter = () => {
        let filter = `?promocionId.equals=${promocion?.id}&sort=procesoGestoraCodLer.codLER.codigoLER,asc&sort=id,desc`;
        const addFilter = createFilterWithObject(seeker, searchFilter);
        return (filter += addFilter);
    };

    const getPpgcl = async () => {
        setLoading(true);
        const filter = createFilter();
        const totalPpgcls = await promoProGesCodLersServices.countPromoProGesCodLers(filter);
        let ppgcls = await promoProGesCodLersServices.filterPromoProGesCodLers(`${filter}&size=${totalPpgcls}`);
        ppgcls = ppgcls?.map((ppgcl) => {
            // Adapt back data to manipulate data at front more easily
            return {
                ...ppgcl,
                contractId: ppgcl?.contract?.id || null,
                contractFileName: ppgcl?.contract?.fileName || null,
                contractFileUrl: ppgcl?.contract?.fileUrl || null,
                contractIsDangerous: ppgcl?.contract?.isDangerous || null
            };
        });

        ppgcls = ppgcls.sort((a, b) => (a.contractFileName || '').localeCompare(b.contractFileName || ''));

        setAllPpgcl(ppgcls);
        setLoading(false);
    };

    useEffect(() => {
        if (ppgclToDelete !== undefined) {
            checkIfPpgclHasTraceabilities();
        }
    }, [ppgclToDelete]);

    const toggleEdit = (ppgcl) => {
        setOpenEditPopover(!openEditPopover);
        setPpgclToEdit(ppgcl);
    };

    const toggleDelete = (ppgcl) => {
        setOpenDeleteModal(!openDeleteModal);
        setPpgclToDelete(ppgcl);
    };

    const changeDisplayDocument = ({ id, url, fileName }) => {
        setDisplayDocument({ id, url, fileName });
    };

    const displayTreamentContractHeader = (index) => {
        const previousPpgcl = allPpgcl[index - 1];
        const currentPpgcl = allPpgcl[index];

        if (previousPpgcl?.contractFileName === currentPpgcl?.contractFileName) return null;

        if (!currentPpgcl.contractFileName) {
            return (
                <h4 className="seekerPpgcl__contractName seekerPpgcl__contractName-unlinked">
                    <IconAlertTriangle />
                    <span>Flujos de residuos sin contrato de tratamiento</span>
                </h4>
            );
        }

        return (
            <h4 className="seekerPpgcl__contractName">
                {currentPpgcl.contractIsDangerous && (
                    <Tooltip msg={'Este contrato de tratamiento es de LERs peligrosos'}>
                        <IconRadioactive />
                    </Tooltip>
                )}
                <span>{currentPpgcl.contractFileName}</span>
                <IconButton
                    titulo="Ver doc."
                    icon={<IconExternalLink />}
                    action={() =>
                        changeDisplayDocument({
                            id: currentPpgcl?.contractId,
                            url: currentPpgcl?.contractFileUrl,
                            fileName: currentPpgcl?.contractFileName
                        })
                    }
                />
            </h4>
        );
    };

    const changePpgcl = ({ ppgclId, message, removeCT }) => {
        if (ppgclId === undefined) return;

        let newPpgcls = allPpgcl?.map((ppgcl) => {
            if (ppgcl?.id !== ppgclId) return ppgcl;

            return {
                ...ppgcl,
                message, // "message" is a variable neccessary to front to write a purple message "PPGCL associated with X" / "Removed CT"
                ...(removeCT && { contractId: null }) // only set to null the Id property. if we set to null contractName, ppgcls list order changes
            };
        });

        setAllPpgcl(newPpgcls);
    };

    useEffect(() => {
        let mounted = true;
        if (mounted && promocion?.id) {
            getPpgcl();
        }
        return () => {
            mounted = false;
        };
    }, [promocion?.id]);

    return (
        <div className="ppgcl__form--seeker">
            <section className="seekerPpgcl__container">
                <div className={`seekerPpgcl__ppgcls ${selectedPpgcl ? 'seekerPpglc__ppgcls-openedDoc' : ''}`}>
                    <form onSubmit={handleFormSubmitPromotionInfo} className="seekerPpgcl__form">
                        <PromoProGesCodLerAutocomplete values={seeker} setValues={setSeeker} value={seeker.gestora} />
                        <CodLerAutocomplete
                            values={seeker}
                            setValues={setSeeker}
                            value={seeker.codLer}
                            name="codLer"
                            avoidDuplicateLers={true}
                        />
                        <SubmitButton text={t('search.search', { ns: 'common' })} />
                    </form>
                    {promocion?.id && allPpgcl.length === 0 && (
                        <p className="seekerPpgcl__message">{t('noResults', { ns: 'newPpgcl' })}</p>
                    )}

                    {allPpgcl.length > 0 && (
                        <div className="seekerPpgcl__scrollResults" id="seekerPpgcl__scrollResults">
                            {allPpgcl.map((ppgcl, index) => {
                                return (
                                    <>
                                        {displayTreamentContractHeader(index)}
                                        <ResultPpgcl
                                            key={ppgcl.id}
                                            hasTreatmentContract={ppgcl.contractId !== null}
                                            {...{
                                                ppgcl,
                                                setOpenEditPopover,
                                                toggleEdit,
                                                toggleDelete,
                                                selectedPpgcl,
                                                setSelectedPpgcl
                                            }}
                                        />
                                    </>
                                );
                            })}
                        </div>
                    )}
                </div>
                {selectedPpgcl && (
                    <div className="seekerPpgcl__documentsContainer">
                        <TreatmentContractAssociation
                            ppgcl={selectedPpgcl}
                            changePpgcl={changePpgcl}
                            promotionId={promocion?.id}
                            promotionName={promocion?.nombre}
                            resetSelectedPpgcl={() => setSelectedPpgcl(undefined)}
                            changeDisplayDocument={changeDisplayDocument}
                        />
                    </div>
                )}
            </section>

            {loading && <Spinner />}

            {openEditPopover && (
                <SimplePopover
                    openModal={openEditPopover}
                    setOpenModal={setOpenEditPopover}
                    title={t('edit', { ns: 'newPpgcl' })}
                    className="seekerPpgcl__editPpgcl"
                >
                    <FormEditPpgcl
                        ppgcl={ppgclToEdit}
                        {...{ allPpgcl, setAllPpgcl, openEditPopover, setOpenEditPopover }}
                    />
                </SimplePopover>
            )}

            {displayDocument && (
                <SimplePopover
                    openModal={displayDocument}
                    setOpenModal={setDisplayDocument}
                    title={`Visualizando ${displayDocument?.fileName}`}
                    allowScroll={false}
                    className="seekerPpgcl__displayedDocument"
                >
                    <>
                        <SmallButton
                            type="link"
                            titulo="Editar documento"
                            to={`/documents/${displayDocument?.id}/edit`}
                        />
                        <iframe src={displayDocument?.url} />
                    </>
                </SimplePopover>
            )}

            {openDeleteModal && checkTraceabilities && (
                <BasicModal
                    title={t('deleteWasteStream', { ns: 'newPpgcl' })}
                    subtitle={textModal()}
                    actionFunction={() => deletePpgcl()}
                    actionText={t('delete', { ns: 'newPpgcl' })}
                    openModal={openDeleteModal}
                    setOpenModal={setOpenDeleteModal}
                    disabledButton={hasTraceabilities}
                    noDeleteButton={true}
                />
            )}

            {message?.text && <ResultadoCarga text={message.text} setMessage={setMessage} success={message.success} />}
        </div>
    );
};

export default withAuth(SeekerPpgcl);
