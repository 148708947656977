import { useEffect } from 'react';

import { WASTE_REGISTER_INITIAL_CRITERIA } from 'modules/waste-register/domain/WasteRegisterCriteria';
import { useWasteRegisterStore } from 'modules/waste-register/infrastructure/react/wasteRegisterContext';

export function useLoadWasteRegisters(props: { promotionIds: number[] }) {
    const { promotionIds } = props;
    const [{ updated, total }, { loadWasteRegisters }] = useWasteRegisterStore();

    // Load waste registers when promotionIds change
    useEffect(() => {
        loadWasteRegisters({ promotionIds });
    }, [JSON.stringify(promotionIds)]);

    // Load waster register when updating changes
    useEffect(() => {
        if (Object.keys(updated).length === 0) return;

        const size = total || WASTE_REGISTER_INITIAL_CRITERIA.size;

        loadWasteRegisters({ promotionIds, page: 0, size });
    }, [JSON.stringify(Object.keys(updated))]);
}
