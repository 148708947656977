import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import EmptyState from 'components/BasicComponents/EmptyState';
import SidePanel from 'components/BasicComponents/SidePanel';
import { useLoadWasteRegisters } from 'features/waste-register/hooks/useLoadWasteRegisters';
import { useDateFormatter } from 'hooks/useDateFormatter';
import fromEntities from 'lib/fromEntities';
import { WasteRegister } from 'modules/waste-register/domain/WasteRegister';
import { useWasteRegisterStore } from 'modules/waste-register/infrastructure/react/wasteRegisterContext';
import WasteRegisterActions from '../WasteRegisterActions';
import WasteRegisterForm from '../WasteRegisterForm';
import './WasteRegisterList.scss';
import WasteRegisterListItem from './components/WasteRegisterListItem';

export type WasteRegisterListProps = {
    promotionIds: number[];
};

const WasteRegisterList = (props: WasteRegisterListProps) => {
    const [{ total, criteria, data, loading, error }, { loadMoreWasteRegisters }] = useWasteRegisterStore();
    const wasteRegisters = fromEntities(data);
    const { format } = useDateFormatter();
    const [editWasteRegister, setEditWasteRegister] = useState<WasteRegister>();

    const skeletons = new Array(criteria.size).fill(null).map((_, i) => <WasteRegisterListItem key={i} />);
    const list = groupHeadersAdapter(wasteRegisters, 'movementDate');

    useLoadWasteRegisters({ promotionIds: props.promotionIds });

    const modifiers = [
        loading === 'pending' ? 'WasteRegisterList--pending' : '',
        loading === 'failed' && list.length === 0 ? 'WasteRegisterList--failed' : ''
    ].join(' ');

    return (
        <>
            <div className={`WasteRegisterList ${modifiers}`}>
                <ErrorCard className="WasteRegisterList__error" error={error as Error} />

                {list.length === 0 && loading === 'succeeded' && (
                    <EmptyState testid="WasteRegisterList__empty" mode="emptyResults" subject="registros de residuos" />
                )}

                {list.length > 0 && <WasteRegisterActions />}

                <div data-testid="WasteRegisterList__list">
                    {list.length === 0 && loading !== 'succeeded' ? (
                        skeletons
                    ) : (
                        <InfiniteScroll
                            className="WasteRegisterList__list"
                            scrollableTarget="main-layout"
                            dataLength={total}
                            // Only load more when loading has succeeded. This way we wait until the wasteRegisters.length
                            // its updated, so we avoid the weird situation of skeletons appearing (and not dissapearing)
                            // when a user creates a new waste register and scrolls fast to the bottom
                            hasMore={loading === 'succeeded' && total > wasteRegisters.length}
                            loader={skeletons}
                            next={() => {
                                loadMoreWasteRegisters({ page: (criteria.page || 0) + 1 });
                            }}
                        >
                            {list.map((item, index) => {
                                if ('type' in item && item.type === 'header')
                                    return (
                                        <header key={index} className="WasteRegisterList__group-header">
                                            {format(item.group, 'DD MMM YYYY')}
                                        </header>
                                    );

                                const wr = item as WasteRegister;
                                return (
                                    <WasteRegisterListItem
                                        key={wr.uuid}
                                        wasteRegister={wr}
                                        onClickEdit={setEditWasteRegister}
                                    />
                                );
                            })}
                        </InfiniteScroll>
                    )}
                </div>
            </div>

            <SidePanel
                testid="WasteRegisterList__edit"
                title={
                    editWasteRegister
                        ? `${format(editWasteRegister?.movementDate, 'DD/MM/YY')} - ${editWasteRegister?.flow.ler.type}`
                        : ''
                }
                openModal={!!editWasteRegister}
                setOpenModal={(isOpen) => {
                    if (!isOpen) setEditWasteRegister(undefined);
                }}
            >
                {editWasteRegister && (
                    <WasteRegisterForm
                        uuid={editWasteRegister.uuid}
                        promotionId={editWasteRegister.promotionId}
                        onSave={() => setEditWasteRegister(undefined)}
                        isEditMode={!!editWasteRegister}
                    />
                )}
            </SidePanel>
        </>
    );
};

export default WasteRegisterList;

function groupHeadersAdapter<E extends { [key: string]: any }>(data: E[], sortKey: keyof E) {
    return data.reduce((acc, item, index) => {
        const prevItem = data[index - 1];

        const prevSortKey = prevItem?.[sortKey];
        const currentSortKey = item[sortKey];

        if (!prevSortKey || prevSortKey !== currentSortKey) {
            return [...acc, { type: 'header' as const, group: currentSortKey, sortKey }, item];
        }

        return [...acc, item];
    }, [] as (E | { type: 'header'; group: any })[]);
}
