export const SortTraceabilityList = [
    {
        label: 'ler.code',
        name: 'traceabilityList',
        sortBy: 'promoProGesCodeLer.wasteManagerProGesCodLer.codLER.codigoLER',
        section: 'common'
    },
    {
        label: 'DESC_DATE',
        name: 'traceabilityList',
        sortBy: 'fechaMovimiento',
        section: 'sort'
    },
    {
        label: 'ASC_DATE',
        name: 'traceabilityList',
        sortBy: 'fechaMovimiento',
        section: 'sort'
    },
    {
        label: 'manager.sg',
        name: 'traceabilityList',
        sortBy: 'promoProGesCodeLer.wasteManagerProGesCodLer.procesoGestora.gestora.empresa',
        section: 'common'
    },
    {
        label: 'transporter',
        name: 'traceabilityList',
        sortBy: 'transportista.empresa',
        section: 'common'
    }
];

export const SORT = [
    {
        label: 'ASC_DATE',
        key: 'fechaMovimiento',
        sortby: 'asc'
    },
    {
        label: 'DESC_DATE',
        key: 'fechaMovimiento',
        sortby: 'desc'
    },
    {
        label: 'ASC_MANAGED_TONS',
        key: 'pesoGestionado',
        sortby: 'asc'
    },

    {
        label: 'DESC_MANAGED_TONS',
        key: 'pesoGestionado',
        sortby: 'desc'
    },
    {
        label: 'ASC_COST',
        key: 'costeLER',
        sortby: 'asc'
    },

    {
        label: 'DESC_COST',
        key: 'costeLER',
        sortby: 'desc'
    },
    {
        label: 'ASC_PERCENTAGE_VALORIZATION',
        key: 'porcentajeValorizacion',
        sortby: 'asc'
    },
    {
        label: 'DESC_PERCENTAGE_VALORIZATION',
        key: 'porcentajeValorizacion',
        sortby: 'desc'
    }
];

export const wasteManagerTraceabilityOptions = [
    {
        value: '&end2End.specified=true&traceabilityStatus.in=PENDING',
        description: 'Solicitud DI: pendiente aceptación'
    },
    {
        value: '&end2End.specified=true&traceabilityStatus.in=WARNING',
        description: 'Solicitud DI: pre-DI'
    },
    {
        value: '&end2End.specified=true&traceabilityStatus.in=COMPLETED',
        description: 'Solicitud DI: completo'
    },
    {
        value: '&end2End.specified=true&traceabilityStatus.in=DENIED',
        description: 'Solicitud DI: rechazada'
    }
];

export const traceabilityE2EStatus = [
    {
        value: 'PENDING',
        description: 'Solicitud'
    },
    {
        value: 'WARNING',
        description: 'Pendiente acreditar (falta generar o adjuntar DI)'
    },
    {
        value: 'COMPLETED',
        description: 'Completo (con DI)'
    }
];

export const certficateStatus = [
    {
        value: '&hasCertificate.specified=true',
        description: 'Con certificado'
    },

    {
        value: '&hasCertificate.specified=false',
        description: 'Sin certificado'
    }
];

export const diStatusOptions = [
    { value: true, description: 'Contiene DI' },
    { value: false, description: 'No contiene DI' }
];
