import { useDateFormatter } from 'hooks/useDateFormatter';
import { useTranslation } from 'react-i18next';

import { legacyEnterpriseAdapter } from 'modules/enterprise/related-enterprise/domain/legacyEnterpriseAdapter';
import logoBlue from '../../../assets/logo-blue.png';
import logo360 from '../../../assets/logo/logo360.png';
import { withAuth } from '../../../context/AuthProvider';
import './ReportCover.scss';

const Cover = (props) => {
    const [t] = useTranslation();
    const { format } = useDateFormatter();

    const mainEnterprise = legacyEnterpriseAdapter(props?.promocion?.relatedEnterprises);

    return (
        <div className="cover">
            <div className="cover__container">
                <div className="cover__logos">
                    <img src={logoBlue} alt="Logo CoCircular" />
                    <img src={logo360} alt="Logo 360 Advisor" />
                </div>
                <h2>{t('reportTitle', { ns: 'reports' })}</h2>

                <p>
                    {t('promotion.upperCase', { ns: 'common' })}: <span>{props.promocion?.nombre}</span>
                </p>
                <p className="cover__generatedTo">
                    <i>
                        Este informe se ha elaborado para <b>{mainEnterprise?.nombreComercial}</b> con los datos
                        obtenidos hasta el {format(new Date(), 'DD MMMM YYYY, HH:mm')}h
                    </i>
                </p>
            </div>
        </div>
    );
};

export default withAuth(Cover);
