import { Trans, useTranslation } from 'react-i18next';

import KPIAlarm from 'features/KPIs/components/KPIAlarm';
import { KPIStatus } from 'modules/promotion-kpis';
import { currencyFormatDE } from 'utils/helpers/general.helpers';
import { MandatoryFlowAlarms } from '../hooks/useMandatoryFlowsAlarms';

const WasteManagementAlarm = (props: { alarms: MandatoryFlowAlarms; link: string; status: KPIStatus }) => {
    const [t] = useTranslation('kpis');

    return (
        <>
            {props.alarms.map(({ goal, ...flow }) => {
                const goalValueTons =
                    (goal?.value.type === 'EGR' && flow.egrTons) || // Egr
                    (goal?.value.type === 'PGR' && flow.pgrTons) || // Pgr
                    0; // or 0

                const accumulatedRealPercent = currencyFormatDE(
                    ((flow.accumulatedRealTons || 0) / goalValueTons) * 100
                );

                return (
                    <KPIAlarm
                        status={KPIStatus.DANGER}
                        link={{
                            text: t(`kpis:mandatory-flows.alarm.danger.egrAndPgr.link`),
                            to: props.link
                        }}
                        text={
                            <Trans
                                i18nKey={`kpis:mandatory-flows.alarm.danger.egrAndPgr.text`}
                                values={{
                                    goalValueTons,
                                    flowName: flow.name,
                                    goalValuePercent: currencyFormatDE(goal?.value.value),
                                    wasteManagementType: goal?.value.type.toUpperCase(),
                                    accumulatedRealTons: flow.accumulatedRealTons,
                                    accumulatedRealPercent
                                }}
                                components={{ b: <strong /> }}
                            />
                        }
                    />
                );
            })}
        </>
    );
};

export default WasteManagementAlarm;
