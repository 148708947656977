import fetcher from 'lib/fetcher';
import { csvFieldsAdapter } from 'modules/waste-register/domain/export/csvFieldsAdapter';
import { WasteRegisterApi, WasteRegisterCreator } from 'modules/waste-register/domain/WasteRegisterApi';

export const httpWasteRegisterApi: WasteRegisterApi = {
    async findByCriteria(criteria) {
        const params = {
            'promotionId.in': criteria.promotionIds?.join(','),
            page: criteria.page,
            size: criteria.size,
            sort: 'movementDate,desc'
        };

        const { data, headers } = await fetcher.get('/api/waste-registers', { params });
        const count = parseInt(headers['x-total-count']);

        if (count === undefined || count === null) throw new Error('Invalid count from "x-total-count" header');

        const mappedData = data.content?.wasteRegisters?.map((wasteRegister) => ({
            ...wasteRegister,
            uuid: wasteRegister.id,
            observations: wasteRegister.observation
        }));

        return { total: count, wasteRegisters: mappedData };
    },

    async findById(payload) {
        const response = await fetcher.get(`/api/waste-registers/${payload.uuid}/public`);
        return { ...response.data.content, observations: response.data.content.observation };
    },

    async save(wasteRegister) {
        const body: WasteRegisterCreator = {
            flowId: wasteRegister?.flow?.id,
            movementDate: wasteRegister?.movementDate,
            observations: wasteRegister?.observations,
            volume: wasteRegister?.volume || null
        };

        const response = await fetcher.put(`/api/waste-registers/${wasteRegister.uuid}`, body);
        return response.data;
    },

    async downloadCsv(criteria) {
        /**
         * Move this to backend as a new endpoint
         * /api/waste-registers/export
         */

        const size = 999;
        const { total, wasteRegisters: firstPageResults } = await this.findByCriteria({ ...criteria, page: 0, size });

        // Retrives the rest of the pages
        const restPagesCount = total > size ? Math.ceil((total - size) / size) : 0;
        const restPages = Array.from({ length: restPagesCount }, (_, index) => index + 1);

        const restPagesResults = await Promise.all(
            restPages.map((page) => this.findByCriteria({ ...criteria, page, size }).then((r) => r.wasteRegisters))
        );

        const wasteRegisters = [firstPageResults, ...restPagesResults].flat();

        const header = csvFieldsAdapter(wasteRegisters[0])
            .map(([header]) => header)
            .join(',');

        const data = wasteRegisters.map((wr) =>
            csvFieldsAdapter(wr)
                .map(([, value]) => value?.toString().replace(',', ' '))
                .join(',')
        );

        const csv = [header, ...data].join('\n');

        // Conforms Blob and download
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Registros de residuo - ${new Date().toLocaleString()}.csv`;
        a.click();
    }
};
