import fetcher from '../../../lib/fetcher';

const _userMessage = {
    success: {
        text: 'requestUpdateData.success',
        ns: 'traceabilityE2E'
    },
    error: {
        text: 'requestUpdateData.error',
        ns: 'traceabilityE2E'
    }
};

export const patchTraceabilityE2EDate = async (traceabilityId, date) => {
    const URL = `/api/tracing/end2end-traceability/${traceabilityId}/date`;
    try {
        const response = await fetcher.patch(URL, date, { message: _userMessage });
        return response;
    } catch (error) {
        console.log(error);
    }
};
