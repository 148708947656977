import { AxiosError } from 'axios';
import fetcher from 'lib/fetcher';
import {
    WasteManagement,
    WasteManagementLer,
    wasteManagementEntryCreatorAdapter
} from 'modules/waste-management/domain/WasteManagement';
import { WasteManagementApi } from 'modules/waste-management/domain/WasteManagementApi';
import { WasteManagementMetrics } from 'modules/waste-management';

export const httpWasteManagementApi: WasteManagementApi = {
    async getWasteManagement(criteria) {
        const params = {
            promotionId: criteria.promotionId,
            type: criteria.type
        };

        const { data } = await fetcher.get('/api/tracing/egr', { params });

        const wasteManagement: WasteManagement = data.content.egrResponse;

        return wasteManagement;
    },
    async deleteWasteManagementEntry(criteria) {
        const { data } = await fetcher.delete(`/api/tracing/egr/${criteria.id}`);
        return data;
    },
    async createWasteManagementEntry(source) {
        const entry = wasteManagementEntryCreatorAdapter(source);
        const { data } = await fetcher.post('/api/tracing/egr', [entry]);
        return data;
    },
    async saveWasteManagement(source) {
        const entries = source.map(wasteManagementEntryCreatorAdapter);
        const { data } = await fetcher.put('/api/tracing/egr', entries);
        return data;
    },

    async getMetrics({ promotionId, lands }) {
        try {
            const params = { lands };
            // TODO: Renombrar`/api/promotion/${promotionId}/metrics/waste-management`
            const { data } = await fetcher(`/api/promotion/${promotionId}/metrics/kpi/mandatory-flows`, { params });
            const mandatoryFlows: WasteManagementMetrics = data.content;

            // sort mandatory flows by realTons
            const sortedMandatoryFlows = mandatoryFlows.sort((a, b) => b.realTons - a.realTons);

            return { data: sortedMandatoryFlows };
        } catch (error) {
            // if no EGR/PGR, data is not allowed
            if ((error as AxiosError).response?.data?.code === '1802') return { data: [] };

            throw error;
        }
    },

    async getFlowHistory({ promotionId, lerTypeId }) {
        const { data } = await fetcher(
            // TODO: Renombrar`/api/promotion/${promotionId}/metrics/waste-management/ler-type/${lerTypeId}`
            `/api/promotion/${promotionId}/metrics/kpi/mandatory-flows/ler-type/${lerTypeId}`
        );

        const history = {
            data: data.content.data,
            goals: []
        };

        return history;
    },

    async getLers(criteria) {
        const isSearchingNumber = !isNaN(parseInt(criteria.search || ''));

        const params = {
            sort: `codigoLER,desc`,

            ...(isSearchingNumber
                ? { 'codigoLER.contains': criteria.search }
                : { 'descripcion.contains': criteria.search }),

            /**
             * Because the backend searches for the description AND lertype, there are codes that are not found when the lertype is "other".
             * We have decided to search only by the description because in 99% of the cases the description already includes the lerType
             */
            // 'lerType.contains': criteria.search,

            'hasPgcl.equals': true,
            page: criteria.page || 0
        };

        const { data } = await fetcher.get('api/lers', { params });

        const lers: WasteManagementLer[] = data.map((data) => {
            const ler: WasteManagementLer = {
                id: data.id,
                code: data.codigoLER,
                descripcion: data.descripcion,
                type: {
                    id: data.lerType.id,
                    name: data.lerType.lerKind
                }
            };
            return ler;
        });

        return lers;
    }
};
