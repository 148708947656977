import { contextStoreAdapter } from 'modules/shared/infraestructure/adapters/react/contextStoreAdapter';
import { wasteFlowStore } from 'modules/waste-flow/application/wasteFlowStore';

const wasteFlowContext = contextStoreAdapter(wasteFlowStore)({ name: 'WasteFlowStore' });

export const WasteFlowProvider = wasteFlowContext.Provider;
export const useWasteFlow = wasteFlowContext.useConsumer;

export const wasteFlowHooks = {
    useSaveWasteHandling: wasteFlowContext.createMutationHook(({ api }) => api.saveWasteHandling)
};
