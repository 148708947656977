import TooltipContent, {
    TooltipContentDate,
    TooltipContentLabel,
    TooltipContentValue
} from 'components/BasicComponents/Tooltips/TooltipContent';
import KPIStatusIcon from 'features/KPIs/components/KPIStatusIcon';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { FlowLerTypeDetail, FlowLerTypeHistoryEntry, calcKPIStatusFromGoal } from 'modules/promotion-kpis';
import { Trans, useTranslation } from 'react-i18next';
import './FlowTooltip.scss';
import { calcGrValue } from '../utils/calcGrValue';

type FlowTooltipProps = {
    flow: FlowLerTypeDetail;
    entry: FlowLerTypeHistoryEntry;
};

const FlowTooltip = ({ flow, entry }: FlowTooltipProps) => {
    const date = useDateFormatter();
    const [t] = useTranslation('kpis');

    return (
        <div className="FlowTooltip">
            <TooltipContent>
                <TooltipContentDate className="FlowTooltip__date">
                    {date.format(entry.month, 'MMMM YYYY')}
                </TooltipContentDate>
                <TooltipContentValue>
                    <strong>{entry.accumulatedRealTons || 0}t</strong>
                    <span className="FlowTooltip__flow">{t(`mandatory-flows.tooltip.flow`, { flow: flow.name })}</span>
                </TooltipContentValue>

                {!!entry.goals?.length && (
                    <TooltipContentDate className="FlowTooltip__goal-title">
                        {t(`mandatory-flows.tooltip.title`)}
                    </TooltipContentDate>
                )}

                <TooltipContentLabel>
                    {entry.goals?.map((goal) => {
                        const { value, type } = goal.value;
                        const { accumulatedRealTons, accumulatedTraceabilities } = entry;
                        const { pgrTons, egrTons } = flow;

                        const status = calcKPIStatusFromGoal(goal);

                        let count = 0;
                        if (type === 'EGR') count = calcGrValue(accumulatedRealTons, egrTons);
                        if (type === 'PGR') count = calcGrValue(accumulatedRealTons, pgrTons);
                        if (type === 'COUNT') count = accumulatedTraceabilities;

                        return (
                            <div className={`FlowTooltip__goal FlowTooltip__goal--${status}`}>
                                <KPIStatusIcon className="FlowTooltip__goal-status" status={status} />
                                <Trans
                                    i18nKey={`kpis:mandatory-flows.tooltip.${type?.toLocaleLowerCase()}`}
                                    values={{ value, count }}
                                    components={{ em: <em /> }}
                                />
                            </div>
                        );
                    })}
                </TooltipContentLabel>
            </TooltipContent>
        </div>
    );
};

export default FlowTooltip;
