import { GoalSeverityLevels, PromotionKPIsGoalMet } from './goals/PromotionKPIsGoal';

export enum KPIStatus {
    SUCCESS = 'success',
    WARNING = 'warning',
    DANGER = 'danger',
    INDETERMINATE = 'indeterminate',
    NOT_APPLICABLE = 'not_applicable'
}

/** Determines the status of the KPI based on whether it meets the goal and severity level. */
export function calcKPIStatusFromGoal(goal?: Pick<PromotionKPIsGoalMet, 'met' | 'severity'> | null): KPIStatus | null {
    const { met, severity } = goal || {};

    if (!goal) return KPIStatus.INDETERMINATE;

    const status =
        ((met === null || met === undefined) && KPIStatus.NOT_APPLICABLE) ||
        (met === true && KPIStatus.SUCCESS) ||
        (met === false && severity === GoalSeverityLevels.Critical && KPIStatus.DANGER) ||
        (met === false && severity === GoalSeverityLevels.Warning && KPIStatus.WARNING) ||
        null;

    return status;
}
