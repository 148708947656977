import { Controller, FieldValues } from 'react-hook-form';

import InputDate from 'components/BasicComponents/Inputs/InputFecha';
import { FormControlProps } from './shared/FormControlProps';
import { useErrorMessage } from './shared/useErrorMessage';

type DateProps = {
    label?: string;
    append?: React.ReactNode;
};

type DateFormControlProps<Values extends FieldValues> = React.InputHTMLAttributes<HTMLInputElement> &
    FormControlProps<Values> &
    DateProps;

const DateFormControl = <Values extends FieldValues>(props: DateFormControlProps<Values>) => {
    const { control, name, label, append, ...inputAttributes } = props;

    const { errorMsg } = useErrorMessage(props);

    return (
        <Controller
            control={props.control}
            name={props.name}
            render={({ field }) => (
                <InputDate
                    {...inputAttributes}
                    name={field.name}
                    label={props.label}
                    // from Date to YYYY-MM-DD
                    value={field.value ? new Date(field.value).toISOString().split('T')[0] : undefined}
                    // from YYYY-MM-DD to Date
                    onChange={({ target }) => {
                        const date = target.value ? new Date(target.value) : undefined;
                        field.onChange(date);
                    }}
                    validacion={!!errorMsg}
                    required={!!errorMsg}
                    errorMsg={errorMsg}
                    notLimit={true}
                    onBlur={field.onBlur as any}
                    inputContainer={props.className}
                    extraElement={append}
                    // Just to ignore optional fields
                    {...({} as any)}
                />
            )}
        />
    );
};

export default DateFormControl;
