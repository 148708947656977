import axios from 'axios';
import { getToken } from '../../utils/helpers/api.helpers';
import { dispatchLogout } from '../../lib/LogoutHandler';

class TraceabilitiesServices {
    constructor() {
        this.services = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            withCredentials: true
        });
    }

    traceabilityById = async (id) => {
        try {
            const result = await this.services.get(`/api/tracing/container-traceability?id.equals=${id}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    deleteTraceability = async (id) => {
        try {
            const result = await this.services.delete(`/api/tracing/container-traceability/${id}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    searchTraceabilityGroupByLer = async (url) => {
        try {
            const result = await this.services.get(`/api/tracing/traceability-group-by-ler${url}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    counterTraceabilities = async (filter) => {
        try {
            const result = await this.services.get(`/api/tracing/container-traceability/count${filter}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    counterTraceabilitiesPromotion = async (id, filter = '') => {
        try {
            const result = await this.services.get(
                `/api/tracing/container-traceability/count?promocionId.equals=${id}${filter}`,
                {
                    headers: {
                        Authorization: getToken()
                    }
                }
            );
            return result.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    counterTraceabilitiesEnterprise = async (id) => {
        try {
            const result = await this.services.get(`/api/tracing/container-traceability/count?empresaId.equals=${id}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };
}

const traceabilitiesServices = new TraceabilitiesServices();
export default traceabilitiesServices;
