import { ROLES } from 'utils/constants/roles.constants';
import { E2EPermissions } from './E2EPermissions';
import { E2EPermissionsPromotion } from './E2EPermissionsPromotionRepository';
import { E2EPermissionsUser } from './E2EPermissionsUserRepository';

export const createE2EPermissions = (source: {
    promotion: E2EPermissionsPromotion;
    user: E2EPermissionsUser;
}): E2EPermissions => {
    const noNima = !source.promotion.nima;
    const hasPermissions =
        source.user.authorities.includes(ROLES.writer) || source.user.authorities.includes(ROLES.admin);

    const disableRules =
        (noNima && { value: true, reason: 'promotionWithoutNima' }) ||
        (!hasPermissions && { value: true, reason: 'requestWriterPermissions' });

    return {
        canAccess: { value: true },
        isDisabled: disableRules || { value: false, reason: null }
    };
};
