import { ResponsiveBar } from '@nivo/bar';
import { animated } from '@react-spring/web';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons';
import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import TooltipContent, {
    TooltipContentDate,
    TooltipContentLabel,
    TooltipContentValue
} from 'components/BasicComponents/Tooltips/TooltipContent';
import { useBarChart } from 'hooks/charts/useBarChart';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { WasteByValorizationEntry } from 'modules/waste-by-valorization';
import { VALORIZATION_TYPES } from 'modules/waste-by-valorization/domain/ValorizationTypes';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { currencyFormatDE, roundNumber } from 'utils/helpers/general.helpers';
import './WasteByValorizationChart.scss';
import { getBarsPerWidthWasteByValorization } from './helpers/wasteByValorization.helpers';

const WasteByValorizationChart = ({ data }: { data: WasteByValorizationEntry[] }) => {
    const [t] = useTranslation('promotion');
    const [, analytics] = useAnalyticsStore();

    const [graphData, setGraphData] = useState([] as any);

    const [selectedLerType, setSelectedLerType] = useState<undefined | string>(undefined);
    const [enableAnimation, setEnableAnimation] = useState(true);

    const getLerTypeBar = () => {
        const graphDataFiltered = graphData.filter((bar) => bar.lerType.name === selectedLerType);
        if (!graphDataFiltered || graphDataFiltered.length === 0) return [];

        const lerTypeBar = { ...graphDataFiltered[0] };
        delete lerTypeBar.greyRectangle;

        return [lerTypeBar];
    };

    const { slicedData, ref, showLeftButton, showRightButton, scrollLeft, scrollRight } = useBarChart({
        allData: graphData,
        idProp: 'id',
        resetObjects: [],
        barsPerWidth: getBarsPerWidthWasteByValorization(),
        mode: 'sliceFromLeftToRight'
    });

    const addGreyRectangle = () => {
        if (!data || data.length === 0) return [];

        const highestTons = data[0].totalTons;
        return data.map((lerTypeInfo) => ({ ...lerTypeInfo, greyRectangle: highestTons - lerTypeInfo.totalTons }));
    };

    const updateBarsStyle = (props: { lerType; normalBarBrightness; greyRectangleOpacity }) => {
        const allLerTypeBars = document.querySelectorAll(`[stroke*="${props.lerType}"]`);
        allLerTypeBars.forEach((b) => ((b as any).style.filter = `brightness(${props.normalBarBrightness}%)`));

        if (!selectedLerType) {
            const greyRectangle = document.querySelector(`[stroke="greyRectangle.${props.lerType}"]`);
            if (greyRectangle) (greyRectangle as any).style.opacity = props.greyRectangleOpacity;
        }
    };

    // eslint-disable-next-line
    useEffect(() => {
        if (!data) return;

        const dataAux = addGreyRectangle();
        setGraphData(dataAux);
    }, [data]);

    useEffect(() => {
        // Animation hack. Avoid weird lagg/small lines appearing after zooming into one bar while keeping the animations
        setTimeout(() => {
            setEnableAnimation(false);
            setEnableAnimation(true);
        }, 1000);
    }, [selectedLerType, slicedData]);

    return (
        <div ref={ref} className="WasteByValorizationChart">
            <div style={{ height: 400, width: '100%', position: 'relative' }}>
                {selectedLerType && (
                    <SmallButton
                        titulo="Mostrar todos los residuos"
                        className="WasteByValorizationChart__showAllBars"
                        action={() => setSelectedLerType(undefined)}
                        icon={<IconChevronLeft />}
                    />
                )}
                {showLeftButton && !selectedLerType && (
                    <button
                        onClick={scrollLeft}
                        className="WasteByValorizationChart__scrollButton WasteByValorizationChart__scrollButton-left"
                    >
                        <IconChevronLeft />
                    </button>
                )}
                {showRightButton && !selectedLerType && (
                    <button
                        onClick={scrollRight}
                        className="WasteByValorizationChart__scrollButton WasteByValorizationChart__scrollButton-right"
                    >
                        <IconChevronRight />
                    </button>
                )}
                <ResponsiveBar
                    data={selectedLerType ? getLerTypeBar() : slicedData}
                    indexBy={(entry) => entry.lerType?.['name']}
                    // HACK USING BORDER COLOR AS A VARIABLE TO STORE BAR ID -> {valorizationType}.{lerType}
                    borderColor={(params) => params.key as any}
                    borderWidth={1}
                    onMouseEnter={(datum) => {
                        updateBarsStyle({
                            lerType: datum.indexValue,
                            normalBarBrightness: 97.5,
                            greyRectangleOpacity: 0.2
                        });
                    }}
                    onMouseLeave={(datum) => {
                        updateBarsStyle({
                            lerType: datum.indexValue,
                            normalBarBrightness: 100,
                            greyRectangleOpacity: 0
                        });
                    }}
                    onClick={(datum) => {
                        if (!selectedLerType) {
                            setSelectedLerType(datum.indexValue.toString());
                            analytics.event(AnalyticsEvents.PROMO_VALORIZATION_INDIVIDUAL_BAR_CLICKED);
                        }
                    }}
                    groupMode="stacked"
                    keys={[...VALORIZATION_TYPES, 'greyRectangle']}
                    margin={{ top: 20, right: 0, bottom: 60, left: 60 }}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    enableLabel={false}
                    axisTop={null}
                    axisRight={null}
                    animate={enableAnimation}
                    axisLeft={{
                        tickValues: 5,
                        tickSize: 0,
                        tickPadding: 12,
                        format: (value) => `${value} t`
                    }}
                    axisBottom={{
                        renderTick: ({ value, textAnchor, animatedProps }) => {
                            const mixed = value === 'Mezclas';
                            const name = value.length <= 12 ? value : value?.slice(0, 12) + '...';
                            const entry = slicedData?.find((entry) => entry.lerType.name === value);
                            const valorization = currencyFormatDE(roundNumber(entry?.valorizationPercentage || 0));

                            return (
                                // Avoid lag at axis bottom when dataset changes with <animated.g>
                                // https://github.com/plouc/nivo/issues/2198
                                <animated.g
                                    transform={animatedProps.transform as any}
                                    style={{ opacity: animatedProps.opacity as any, width: '20px' }}
                                >
                                    <title>
                                        {value}: {valorization}% de valorización
                                    </title>
                                    <text
                                        transform="translate(0,22)"
                                        fontFamily="Be Vietnam Pro"
                                        fill={mixed ? '#e38420' : '#273749'}
                                        fontWeight={mixed ? 'bold' : '450'}
                                        textAnchor={textAnchor}
                                        fontSize="13px"
                                    >
                                        {mixed ? '⚠' : ''} {name}
                                    </text>
                                    <text
                                        transform="translate(0,42)"
                                        fontFamily="Be Vietnam Pro"
                                        fill="#4E6178"
                                        fontStyle="italic"
                                        textAnchor={textAnchor}
                                        fontSize="12px"
                                    >
                                        {valorization}%
                                    </text>
                                </animated.g>
                            );
                        }
                    }}
                    // Change padding when there is only one bar, as @nivo doesn't have another way to set bar's max-width
                    padding={selectedLerType ? 0.75 : 0.35}
                    defs={[
                        {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'white',
                            color: '#EFB3B3',
                            size: 4,
                            padding: 6,
                            stagger: false
                        },
                        {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'white',
                            spacing: 10,
                            rotation: -45,
                            lineWidth: 6,
                            color: '#46b958'
                        }
                    ]}
                    // We don't need to do this match as we do fill:url(#bla bla) in the CSS file
                    // fill={[
                    //     { match: { id: 'dumpTons' }, id: 'dots' },
                    //     { match: { id: 'reuseTons' }, id: 'lines' }
                    // ]}
                    theme={{
                        grid: {
                            line: {
                                stroke: 'rgba(209, 216, 225, 0.25)',
                                strokeWidth: 2,
                                strokeDasharray: '6 6'
                            }
                        },
                        axis: {
                            ticks: {
                                line: {
                                    stroke: 'rgba(209, 216, 225, 0.50)',
                                    strokeWidth: 2
                                },
                                text: {
                                    fill: '#4E6178',
                                    fontSize: 12,
                                    fontFamily: 'Be Vietnam Pro'
                                }
                            }
                        },
                        tooltip: {
                            container: {
                                backgroundColor: 'transparent',
                                boxShadow: 'none'
                            }
                        }
                    }}
                    tooltip={(params) => {
                        if (params.id === 'greyRectangle') return null;

                        return (
                            <TooltipContent>
                                <TooltipContentLabel>{t(`valorizationTypes.${params.id}`)}</TooltipContentLabel>
                                <TooltipContentValue>
                                    {currencyFormatDE(roundNumber(params.value))}t
                                </TooltipContentValue>
                                <TooltipContentDate>{params.indexValue}</TooltipContentDate>
                            </TooltipContent>
                        );
                    }}
                />
            </div>
            <div className="WasteByValorizationChart__legend">
                {VALORIZATION_TYPES.map((valorizationType, index) => (
                    <div key={index} className="WasteByValorizationChart__legend-item">
                        <svg>
                            <rect stroke={valorizationType} strokeWidth={2} />
                        </svg>
                        <span className="WasteByValorizationChart__legend-text">
                            {t(`valorizationTypes.${valorizationType}`)}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WasteByValorizationChart;
