import { useState, useEffect } from 'react';
import promotionServices from '../api/promotion/promotion.services';

export const useUserPromotions = (userId, isCoCircular = false) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        if (userId && !isCoCircular) {
            promotionServices
                .getUserPromotions(userId)
                .then((result) => {
                    setData(result.data);
                    setLoading(false);
                })
                .catch(console.log);
        }
    }, [userId]);

    return [loading, data];
};
