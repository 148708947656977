import { Thunk } from 'modules/shared/domain/store/createStore';
import { WasteRegister } from 'modules/waste-register/domain/WasteRegister';
import { WasteRegisterStore } from 'modules/waste-register/domain/WasteRegisterStore';

type Store = WasteRegisterStore; // Define Store here

export const saveWasteRegister =
    (wasteRegister: WasteRegister): Thunk<Store> =>
    async (store, { api }) => {
        try {
            store.set((draft) => {
                draft.updating[wasteRegister.uuid] = wasteRegister;
                draft.loading = 'updating';

                draft.updated = {};
                draft.invalid = {};
                draft.error = null;
            });

            await api.save(wasteRegister);

            store.set((draft) => {
                delete draft.updating[wasteRegister.uuid];
                draft.loading = 'succeeded';

                draft.updated[wasteRegister.uuid] = wasteRegister;
                draft.total += 1;
            });
        } catch (e) {
            const error = e as Error;

            store.set((draft) => {
                delete draft.updating[wasteRegister.uuid];
                draft.loading = 'failed';

                draft.invalid[wasteRegister.uuid] = error;
            });

            throw error;
        }
    };
