// eslint-disable-next-line import/named
import { Serie } from '@nivo/line';

import { Promotion } from 'modules/promotion';
import { PromotionKPIsGoalMet } from 'modules/promotion-kpis';
import { getMonthsBetween } from 'utils/helpers/dates.helpers';
import { KPIHistoricalChartProps } from './KPIHistoricalChart';
import { KPIHistoricalChartStyles } from './KPIHistoricalChart.styles';

export const historicalKPItoSerieAdapter = (params: {
    data: KPIHistoricalChartProps['data'];
    goals: KPIHistoricalChartProps['goals'];
    color: string;
    promotion: Promotion;
}): Serie[] => {
    const { data = [], goals = [], color, promotion } = params;

    const dataSerie: Serie = {
        id: 'data',
        color,
        data: data.map(({ month, value }) => ({ x: month, y: value }))
    };

    const timelineSerie = getTimelineSerie({ promotion });
    const goalsSeries = getGoalsSerie({ goals, promotion, data });

    return [timelineSerie, dataSerie, ...goalsSeries];
};

/** PRIVATE */

/** Obtiene una serie a partir de los objetivos */
const getGoalsSerie = (params: {
    data: KPIHistoricalChartProps['data'];
    goals: KPIHistoricalChartProps['goals'];
    promotion: Promotion;
}): Serie[] => {
    const { goals = [], promotion } = params;

    // Combina los goals que tengan el mismo nivel de severity
    const goalsBySeverity = goals.reduce((groups, goal) => {
        const severity = goal.severity as number;
        const isBestGoal =
            (goal.type === 'MIN' && goals?.every((g) => goal.value >= g.value)) ||
            (goal.type === 'MAX' && goals?.every((g) => goal.value <= g.value));

        const data = {
            ...goal,
            isBestGoal
        };

        return {
            ...groups,
            [severity]: [...(groups[severity] || []), data]
        };
    }, [] as { [severity: number]: Array<PromotionKPIsGoalMet & { type: 'MIN' | 'MAX'; isBestGoal: boolean }> });

    const goalsSeries = Object.entries(goalsBySeverity).map(([severity, goalsGroup]) => {
        const closestUnmetGoal = getClosestUnmentGoal(params);
        const goal = goalsGroup.find((g) => g.met !== null) || goalsGroup[0];

        const points = goalsGroup
            // sort by progress
            .sort((a, b) => a.progress - b.progress)
            .map((goal) => ({ x: getProgressMonth(promotion, goal), y: goal.value.value }));

        const highlighed = goalsGroup.some((g) => goal.uuid === closestUnmetGoal?.uuid);

        return {
            id: `${goal.type}-${severity}`,
            type: goal.type,
            color: highlighed ? '#4E6178' : '#DDD',
            pointColor: goalsGroup.some((g) => g.isBestGoal)
                ? KPIHistoricalChartStyles.colors.success
                : KPIHistoricalChartStyles.colors.warning,
            goal: goal,
            data: [...points, { x: `${promotion?.fechaFin.slice(0, 7)}-01`, y: points[points.length - 1].y }]
        };
    });

    return goalsSeries;
};

/** Obtiene todos los meses comprendidos entre inicio y fin para crear una serie virtual que abarque todo el tiempo de duración del proyecto */
const getTimelineSerie = (params: { promotion: Promotion }): Serie => {
    const { promotion } = params;

    const months = getMonthsBetween({
        firstDate: promotion.fechaInicio,
        lastDate: promotion.fechaFin
    });

    return {
        id: 'timeline',
        color: KPIHistoricalChartStyles.colors.timeline,
        data: months.map((month) => ({ x: month, y: 0 }))
    };
};

/** Obtiene el mes en formato YYYY-MM-01 dado un porcentaje de avance del proyecto comprendido entre la fecha de inicio y fin */
function getProgressMonth(promotion: Promotion, goal: PromotionKPIsGoalMet) {
    const startDateTimestamp = new Date(promotion.fechaInicio).getTime();
    const endDateTimestamp = new Date(promotion.fechaFin).getTime();
    const diff = Math.abs(endDateTimestamp - startDateTimestamp);
    const progressTimestamp = startDateTimestamp + (diff * goal.progress) / 100;

    return new Date(progressTimestamp).toISOString().slice(0, 7) + '-01';
}

// El goal aplicable y no alcanzado más próximo
function getClosestUnmentGoal(params: {
    data: KPIHistoricalChartProps['data'];
    goals: KPIHistoricalChartProps['goals'];
    promotion: Promotion;
}) {
    const { data = [], goals = [], promotion } = params;

    const lastDataEntry = data[data.length - 1];

    // El goal aplicable y no alcanzado más próximo
    const closestUnmetGoal = goals.reduce((prevGoal, goal, index) => {
        // Si ya está cumplido, no lo consideramos
        if (goal.met) return prevGoal;

        // Si lastDataEntry.month no ha llegado aun al mes del progress
        if (!lastDataEntry) return prevGoal;
        const lastDataEntryTime = new Date(lastDataEntry.month).getTime();

        const goalProgressTime = new Date(getProgressMonth(promotion, goal)).getTime();
        const goalReached = lastDataEntryTime >= goalProgressTime;
        if (!goalReached) return prevGoal;

        if (!prevGoal) return goal;

        // Se queda con el más próximo
        const prevDiff = Math.abs(lastDataEntry.value - prevGoal.value.value);
        const currentDiff = Math.abs(lastDataEntry.value - goal.value.value);
        return currentDiff > prevDiff ? prevGoal : goal;
    }, null as PromotionKPIsGoalMet | null);

    return closestUnmetGoal;
}
