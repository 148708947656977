import Tabs from 'components/BasicComponents/Tabs';
import KPIAlarm from 'features/KPIs/components/KPIAlarm';
import KPIStatusIcon from 'features/KPIs/components/KPIStatusIcon';
import { GoalScopeValue, KPIStatus, calcKPIStatusFromGoal, getCurrentApplicableGoals } from 'modules/promotion-kpis';
import { ValorizationSummary } from 'modules/promotion-kpis/domain/valorization/ValorizationSummary';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { currencyFormatDE } from 'utils/helpers/general.helpers';
import ValorizationHistory from '../ValorizationHistory';
import './ValorizationDetail.scss';

interface ValorizationDetailProps {
    summary: ValorizationSummary | null;
    promotionId: string;
    promotionProgress: number;
}

const ValorizationDetail = (props: ValorizationDetailProps) => {
    const [t] = useTranslation('kpis');
    const [activeScopeId, setActiveScopeId] = useState<string | number | null>(null);

    const { lerTypes, ...restScopes } = props.summary || {};

    const restScopesWithId = Object.entries(restScopes).map(([id, scope]) => ({ id, ...scope }));
    const lerTypesWithId = lerTypes?.map(({ lerTypeId, ...scope }) => ({ id: lerTypeId, ...scope })) || [];

    const scopes = [...restScopesWithId, ...lerTypesWithId];

    useEffect(() => {
        setActiveScopeId(scopes[0]?.id || null);
    }, [JSON.stringify(scopes)]);

    const traceabilitiesLink = `/promotion/${props.promotionId}/traceabilities`;

    const scope = scopes.find((scope) => scope.id === activeScopeId);

    if (!scope) return null;

    const nodata = scope.value === null || scope.value === undefined;
    const goal = getCurrentApplicableGoals(scope.goals)[0];
    const status = calcKPIStatusFromGoal(goal);
    const goalsLink =
        status === KPIStatus.INDETERMINATE || status === KPIStatus.NOT_APPLICABLE
            ? `/promotion/${props.promotionId}/settings/kpi-goals/valorization`
            : null;

    return (
        <>
            <div className="ValorizationDetail">
                {/* Multi scope selector */}
                {scopes.length > 1 && (
                    <header>
                        <Tabs
                            className="ValorizationDetail__tabs"
                            activeTab={scopes.findIndex((scope) => scope.id === activeScopeId)}
                            tabs={scopes.map((scope) => {
                                const goal = getCurrentApplicableGoals(scope.goals)[0] || scope.goals[0];
                                const status = calcKPIStatusFromGoal(goal);

                                return (
                                    <div className="ValorizationDetail__tab" onClick={() => setActiveScopeId(scope.id)}>
                                        <span>{scope.name}</span>
                                        <KPIStatusIcon status={status} />
                                    </div>
                                );
                            })}
                        />
                    </header>
                )}

                {/* Alarms */}
                <section>
                    {nodata ? (
                        <KPIAlarm
                            status={KPIStatus.INDETERMINATE}
                            title={t(`valorization.alarm.nodata.title`, { scope: scope.name })}
                            text={t(`valorization.alarm.nodata.subtitle`, { scope: scope.name })}
                            link={{ text: t(`valorization.alarm.nodata.link`), to: traceabilitiesLink }}
                        />
                    ) : (
                        <KPIAlarm
                            status={status}
                            title={t(`valorization.alarm.${status}.title`, { scope: scope.name })}
                            text={
                                <Trans
                                    ns="kpis"
                                    i18nKey={`valorization.alarm.${status}.subtitle`}
                                    values={{
                                        value: currencyFormatDE(scope.value),
                                        goal: goal?.value.value,
                                        promotionProgress: props.promotionProgress,
                                        goalProgress: goal?.progress
                                    }}
                                    components={{ b: <strong /> }}
                                />
                            }
                            link={{
                                text: t(`valorization.alarm.${status}.link`),
                                to: goalsLink || traceabilitiesLink
                            }}
                        />
                    )}
                </section>

                {/* Chart */}
                <section className="KPIs__chart">
                    {activeScopeId && (
                        <ValorizationHistory
                            promotionId={props.promotionId}
                            scope={
                                // In case of a number, it's a lerTypeId
                                (typeof activeScopeId === 'number' && { lerTypeId: activeScopeId }) ||
                                // Default as GoalScopeValue
                                (activeScopeId as GoalScopeValue)
                            }
                        />
                    )}
                </section>
            </div>
        </>
    );
};

export default ValorizationDetail;
